// 商城首页装修
import {
  WgtPage,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtAbtest,
  WgtProductShelves,
  WgtContentPart,
  WgtLift,
  WgtCustomWgt,
  WgtImageText, 
  WgtClassify,
  WgtMemberInfo,
  WgtSuspension,
  WgtOfficialAccount,
  WgtUserContainer,
} from '../wgts'

export const initiWidgets = []

export const widgets = {
  WgtPage,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtAbtest,
  WgtCustomWgt,
  WgtProductShelves,
  WgtContentPart,
  WgtLift,
  WgtImageText, 
  WgtMemberInfo,
  WgtClassify,
  WgtSuspension,
  WgtOfficialAccount,
  WgtUserContainer,
}



export default {
  widgets,
  initiWidgets
}
