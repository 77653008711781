<template>
  <div class="attr-channel">
    <el-select v-model="localValue"  placeholder="请选择" @change="changeChannel">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>

  </div>
</template>

<script>
import api from '@/api'
import { cloneDeep } from 'lodash'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      options: [{
        label:'优惠券',
        value:'coupon'
      },{
        label:'额外保修',
        value:'repair'
      }],
      localValue: 'coupon'
    }
  },
  methods: {
    async changeChannel() {
      this.$emit("input", this.localValue)
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true
    }
  },

}
</script>

<style lang="scss" scoped>
.el-tag+.el-tag {
  margin-left: 6px;
}
</style>