<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="280" :row-actions-align="'left'"
      :fixed-row-action="true" url="/pagestemplate/lists" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }" :split-count="4">
      <template slot="tableTop">
        <el-tabs type="card" v-model="platform" @tab-click="handleTabClick">
          <el-tab-pane label="微信订单小程序" name="wxapp">
          </el-tab-pane>
          <el-tab-pane label="抖音小程序" name="byte_mini">
          </el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabActiveClick">
          <el-tab-pane :label="`全部(${examine_status_total_count})`" name="all"></el-tab-pane>
          <el-tab-pane :label="`待提交(${examine_status_before_count})`" name="waiting"></el-tab-pane>
          <el-tab-pane :label="`待审核(${examine_status_waiting_count})`" name="examine"></el-tab-pane>
          <el-tab-pane :label="`审核未通过(${examine_status_refuse_count})`" name="refuse"></el-tab-pane>
          <el-tab-pane :label="`审核已通过(${examine_status_pass_count})`" name="pass"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <SpDialog class="custom-tem" width="600px" labelWidth="60px" ref="examineTemRef" v-model="examineTem" title="提示"
      :form="examineForm" :form-list="examineFormList" @onSubmit="onExamineFormSubmit" />
  </div>
</template>

<script>
import Vue from 'vue'
import { gWgts } from '@/view/decorate/scene'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { createSetting, useDialog } from '@shopex/finder'
import { EXAMINE_STATUS, EXAMINE_STATUS_TYPE, DOUYIN_PAGE_TYPE, PAGE_TYPE } from '@/consts'

export default {
  data() {
    return {
      examineTem: false,
      examineForm: {
        text: '',
        remark: ''
      },
      examineFormList: [
        {
          label: '',
          key: 'text',
          type: 'text',
          maxlength: 200
        },

        {
          label: '备注',
          key: 'remark',
          type: 'textarea',
          maxlength: 200
        }
      ],
      setting: createSetting({
        search: [{ name: '页面ID', key: 'pages_template_id', placeholder: "ID" },
        { name: '页面类型', placeholder: "请选择页面类型", key: 'weapp_pages', type: 'select', options: PAGE_TYPE, multiple: true },{ name: '页面名称', key: 'template_title', placeholder: "页面名称" }],
        columns: [
          { name: '页面ID', key: 'pages_template_id', minWidth: 80 },
          { name: '页面类型', key: 'weapp_pages', minWidth: 120, render: (h, { row }) => h('span', PAGE_TYPE.find(item => item.value == row.weapp_pages)?.label) },
          { name: '页面名称', key: 'template_title', minWidth: 220 },
          {
            name: '审核状态',
            key: 'examine_status', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          { name: '操作账户', key: 'login_name', minWidth: 160 },
          { name: '更新时间', key: 'updated_at', minWidth: 160 }
        ],
        actions: [
          {
            name: '详情',
            key: 'detail',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDetails(row)
              }
            }
          },
          {
            name: '审核',
            key: 'examine',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == '2'
            },
            action: {
              handler: (row) => {
                this.handleExamineDialog(row, 'approve')
              }
            }
          },
          {
            name: '审核记录',
            key: 'examineList',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleExamineDialog(row, 'look')
              }
            }
          }
        ]
      }),
      activeName: 'all',
      platform:'wxapp',
      examine_status_total_count: 0,
      examine_status_waiting_count: 0,
      examine_status_refuse_count: 0,
      examine_status_pass_count: 0,
      examine_status_before_count: 0,
    }
  },
  methods: {
    handleTabClick() {
      this.$refs.finder.$refs.finderSearch.value.weapp_pages=[]
      this.$refs.finder.refresh(true)
    },
    //切换审核状态
    handleTabActiveClick(tab) {
      this.$refs.finder.refresh(true)
    },

    beforeSearch(params) {
      const status = {
        'all': '',
        'waiting': '1',
        'examine': '2',
        'refuse': '3',
        'pass': '4'
      }
      const { pageSize: page_size, page: page_no, weapp_pages, ...query } = params
      let pagetype = []
      console.log(this.platform,weapp_pages)
      
      if (this.platform=='wxapp'&&(!weapp_pages || weapp_pages.length == 0)) {
        pagetype = PAGE_TYPE.map(item => item.value)
      } else if(this.platform=='byte_mini'&&(!weapp_pages || weapp_pages.length == 0)) {
        pagetype = DOUYIN_PAGE_TYPE.map(item => item.value)
      }else {
        pagetype = weapp_pages
      }
      params = {
        ...query,
        page_size,
        page_no,
        weapp_pages: pagetype,
        examine_status: status[this.activeName],
        platform: this.platform
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.examine_status_before_count = data.examine_status_before_count
      this.examine_status_total_count = data.examine_status_total_count
      this.examine_status_waiting_count = data.examine_status_waiting_count
      this.examine_status_refuse_count = data.examine_status_refuse_count
      this.examine_status_pass_count = data.examine_status_pass_count
      return data
    },
    async handleDetails(row) {
      const _widgets = [],
        contentComps = []
      Object.keys(gWgts['1002']).forEach((index) => {
        _widgets.push(gWgts['1002'][index])
        Vue.component(gWgts['1002'][index].name, gWgts['1002'][index])
      })
      const { template_content } = await this.$api.template.getPagesTemplateDetail({
        pages_template_id: row[0].pages_template_id
      })
      let list = template_content.list || []
      list.forEach((li) => {
        // 是否存在挂件
        const wgt = _widgets.find((item) => item.name.toLowerCase() == li.name.toLowerCase())
        if (wgt) {
          const wgtInitParams = this.cloneDefaultField(wgt)
          const params = wgt.config.transformIn(li.params, _widgets)
          contentComps.push({
            wgtName: wgt.wgtName,
            ...wgtInitParams,
            ...params
          })
        }
      })
      useDialog(null, {
        title: `预览`,
        width: '860px',
        data: contentComps,
        component: () => import('@/view/decorate/preview.vue'),
        actions: []
      })
    },
    handleExamineDialog(row, type) {
      useDialog(null, {
        title: `审核记录`,
        width: '900px',
        id: row[0].pages_template_id,
        type,
        component: () => import('./template/examineCustomtem.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'medium'
          }
        ]
      }).then(() => {
        this.$refs.finder.refresh()
      })
    },
    cloneDefaultField(e) {
      const { uuid, wgtName, wgtDesc, config } = e
      const { setting, name } = cloneDeep(config)
      const compData = {
        uuid: uuid || uuidv4(),
        name,
        wgtName,
        wgtDesc
      }
      setting.forEach((item) => {
        compData[item.key] = item.value
      })
      return compData
    },
    async onExamineFormSubmit() {
      await this.$api.template.examineTemplate(this.examineForm)
      this.examineTem = false
      this.$message.success('审核成功!')
      this.examineForm = {
        text: '',
        remark: ''
      }
      this.$refs.finder.refresh()
    }
  }
}
</script>

<style lang="scss">
.custom-tem {
  .el-form {
    margin-right: 0px !important;
  }
}
</style>
