<style lang="scss">
.ad_type {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}
.ad-checkbox {
  margin-bottom: 10px;
}
.cus-radio-group {
  .el-radio {
    margin-bottom: 8px;
    margin-right: 10px;
  }
  .el-radio__label{
    padding-left: 5px;
  }
}
</style>

<template>
  <div>
    <el-form ref="form" :model="form" class="box-set" label-width="120px" :rules="rules" v-loading='loading' :disabled="is_expire">
      <el-card header="广告类型" shadow="naver" class="ad_type">
        <el-form-item prop="orderad_type" labe="">
          <el-radio-group v-model="form.orderad_type" @input="inputRadioGroup">
            <el-radio-button :disabled="(form.activity_id > 0) ? true : false" v-for="(opts_val, opts_key) in opts.orderad_type"
            :key="opts_key"  :label="opts_key"  @change="changeOrderAdType">{{ opts_val }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-card>
      <el-row :gutter="24">
        <el-col :span="14">
          <el-card header="广告内容" shadow="naver">
            <el-form-item label="广告名称" prop="activity_title">
              <el-col :span="20">
                <el-input v-model="form.activity_title" :maxlength=30 placeholder="最多30个字"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="广告标题" prop="orderad_title" v-if="form.orderad_type=='text' ">
              <el-col :span="20">
                <el-input v-model="form.orderad_title" :maxlength=30 placeholder="最多30个字"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="广告描述" v-if="form.orderad_type=='text' ">
              <el-col :span="20">
                <el-input type="textarea" :rows="3" v-model="form.activity_desc" placeholder="广告描述"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="广告图" prop="activity_cover" v-if="form.orderad_type=='image' ">
              <div @click="handleImgChange()" class="upload-box" style="margin-left: 12px;">
                <img
                  v-if="form.activity_cover"
                  :src="wximageurl + form.activity_cover"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog" />
            </el-form-item>

            <!-- 热区图 -->
            <el-form-item label="热区" v-if="form.orderad_type=='image'">
              <hotzone
                v-if="form.orderad_type=='image'"
                class="hotzone"
                :image="form.activity_cover"
                :zones-init="form.img_hotzone_info"
                @add="handleAdd"
                @change="handleChange"
                @remove="handleRemove"
              ></hotzone>
            </el-form-item>

            <div
                class="setting-item slider"
                v-for="(item, index) in form.img_hotzone_info"
                :key="index"
              >
              <!-- 热区连接 -->
                <el-form-item label="热区链接">
                  <el-col :span="20">
                    <el-radio-group v-model="item.adavter_type" @change="onAdavterTypeChange($event,index)">
                      <el-radio label="custom">自定义页</el-radio>
                      <el-radio label="compare">机型对比</el-radio>
                      <el-radio label="recommend">机型推荐</el-radio>
                      <el-radio label="question" v-if="form.orderad_type == 'text'">调查问卷</el-radio>
                    </el-radio-group>
                  </el-col>
                </el-form-item>
                <el-form-item label="" prop="customize_id">
                  <div v-if="item.adavter_type == 'custom'">
                    <el-button size="medium" class="button-new-tag" @click="addCustomizeAction(index)">+ 选择自定义页面 </el-button>
                    <div class="goods-select"
                      style=" width: 400px;text-align:left;
                      margin-left: 10px;" v-if="item.customize.customize_id > 0"
                    >
                      {{item.customize.customize_id}}  {{item.customize.customize_title}}
                    </div>
                  </div>
                  <div v-if="item.adavter_type == 'compare'|| item.adavter_type == 'recommend'">
                    <el-form-item>
                      <el-col :span="20">
                        <el-select v-model="item.compare_value" placeholder="请选择">
                          <el-option key="iPhone" label="iPhone" value="iPhone"  v-if="adavter_type == 'compare'"/>
                          <el-option key="iPad" label="iPad" value="iPad"  v-if="adavter_type == 'compare'"/>
                          <el-option key="Mac" label="Mac" value="Mac" />
                          <el-option key="Watch" label="Watch" value="Watch"  v-if="adavter_type == 'compare'"/>
                        </el-select>
                      </el-col>
                    </el-form-item>
                  </div>
                  <div v-if="item.type == 'question'">
                    <el-button size="medium" class="button-new-tag" @click="addQuestionAction(index)">+ 选择调查问卷 </el-button>
                    <div class="goods-select"
                      style=" width: 400px;text-align:left;
                      margin-left: 10px;" v-if="item.customize_id > 0"
                    >
                      {{item.customize.customize_id}}  {{item.customize.customize_title}}
                    </div>
                  </div>
                </el-form-item>
              </div>

            <el-form>
              <el-form-item label="有效期" prop="orderad_time">
                <el-col :span="20">
                  <el-date-picker
                    v-model="form.orderad_time"
                    type="datetimerange"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    align="right"
                    :default-time="['00:00:00', '23:59:59']"
                    :disabled="itemChecked"
                    @change="activityDateTimeChange"
                  />

                  <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
                  </el-checkbox-group>

                </el-col>
              </el-form-item>
            </el-form>
            <el-form-item label="广告链接" v-show="form.img_hotzone_info.length === 0">
              <el-col :span="20">
                <el-radio-group v-model="adavter_type" @change="onAdavterTypeChange">
                  <el-radio label="custom">自定义页</el-radio>
                  <el-radio label="compare">机型对比</el-radio>
                  <el-radio label="recommend">机型推荐</el-radio>
                  <el-radio label="question" v-if="form.orderad_type == 'text'">调查问卷</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
            <el-form-item label="" prop="customize_id" v-show="form.img_hotzone_info.length === 0">
              <div v-if="adavter_type == 'custom'">
                <el-button size="medium" class="button-new-tag" @click="addCustomizeAction('')">+ 选择自定义页面 </el-button>
                <div class="goods-select"
                  style=" width: 400px;text-align:left;
                  margin-left: 10px;" v-if="form.page_link.customize_id > 0"
                >
                  {{form.page_link.customize_id}}  {{form.page_link.customize_title}}
                </div>
              </div>
              <div v-if="adavter_type == 'compare'||adavter_type == 'recommend'">
                <el-form-item>
                  <el-col :span="20">
                    <el-select v-model="compare_value" placeholder="请选择">
                      <el-option key="iPhone" label="iPhone" value="iPhone" v-if="adavter_type == 'compare'"/>
                      <el-option key="iPad" label="iPad" value="iPad"  v-if="adavter_type == 'compare'"/>
                      <el-option key="Mac" label="Mac" value="Mac" />
                      <el-option key="Watch" label="Watch" value="Watch"  v-if="adavter_type == 'compare'"/>
                    </el-select>
                  </el-col>
                </el-form-item>
              </div>
              <div v-if="adavter_type == 'question'">
                <el-button size="medium" class="button-new-tag" @click="addQuestionAction('')">+ 选择调查问卷 </el-button>
                <div class="goods-select"
                  style=" width: 400px;text-align:left;
                  margin-left: 10px;" v-if="form.page_link.customize_id > 0"
                >
                  {{form.page_link.customize_id}}  {{form.page_link.customize_title}}
                </div>
              </div>
            </el-form-item>
            <el-form-item v-if="form.orderad_type=='text'" label="文案背景色">
              <el-col :span="20">
                <el-color-picker v-model="form.color_one"></el-color-picker>&nbsp;&nbsp;
                <el-color-picker v-model="form.color_two"></el-color-picker>
              </el-col>
            </el-form-item>
            <el-form-item v-if="form.orderad_type=='text'" label="文案颜色">
              <el-col :span="20">
                <el-color-picker v-model="form.text_color"></el-color-picker>
              </el-col>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="10">
          <el-card header="广告位设置（至少设置一个广告位）" shadow="naver" class="ad_type">
            <el-form-item label="" prop="orderad_page">
              <el-col :span="24">
                <el-checkbox-group v-model="form.orderad_page" class="ad-checkbox" v-for="(item, page_key, index) in opts.orderad_page[form.orderad_type]" :key="page_key" >
                  <el-checkbox :label="page_key" :key="page_key" :value="page_key" @change="handleChangeCheckbox(item,index,$event)">{{item.title}}</el-checkbox><br>
                  <div v-if="page_key=='items_category'">
                  <el-select size="mini" v-model="form.category_id" placeholder="请选择">
                    <el-option v-for="(category, category_key) in categoryOptions"
                      :key="category_key"
                      :label="category.category_name"
                      :value="category.category_id" >
                    </el-option>
                  </el-select>
                  <el-radio-group v-model="form.page_posno[index]" v-if="form.category_id" class="cus-radio-group">
                    <el-radio v-for="(opts_val, opts_key) in item.page"
                      :key="opts_key"
                      :label="opts_key"
                      @change="handleChangeRadio(page_key,item,$event)"
                    >
                    {{ opts_val }}
                    </el-radio>
                    <el-form v-if="showReserve" style="float: right;margin-top: -3px">
                      <el-switch v-if="form.orderad_type === 'text' && page_key === 'order_detail'" v-model="form.page_reserve[page_key]" @change="reserveChange" inactive-text="永久保留广告"></el-switch>
                    </el-form>
                    <div v-else style="float: right;margin-top: -3px">
                      <el-switch v-if="form.orderad_type === 'text' && page_key === 'order_detail'" v-model="form.page_reserve[page_key]" @change="reserveChange" inactive-text="永久保留广告"></el-switch>
                    </div>
                  </el-radio-group>
                  </div>
                  <el-radio-group v-model="form.page_posno[index]"  v-else  class="cus-radio-group">
                    <el-radio v-for="(opts_val, opts_key) in item.page"
                      :key="opts_key"
                      :label="opts_key"
                      @change="handleChangeRadio(page_key,item,$event)"
                    >
                    {{ opts_val }}
                    </el-radio>
                    <el-form v-if="showReserve" style="float: right;margin-top: -3px">
                      <el-switch v-if="form.orderad_type === 'text' && page_key === 'order_detail'" v-model="form.page_reserve[page_key]" @change="reserveChange" inactive-text="永久保留广告"></el-switch>
                    </el-form>
                    <div v-else style="float: right;margin-top: -3px">
                      <el-switch v-if="form.orderad_type === 'text' && page_key === 'order_detail'" v-model="form.page_reserve[page_key]" @change="reserveChange" inactive-text="永久保留广告"></el-switch>
                    </div>
                  </el-radio-group>
                </el-checkbox-group>
              </el-col>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-card header="适用商品" shadow="naver" class="ad_type">
            <el-form-item label="">
              <el-radio-group v-model="form.use_bound" @change="itemTypeChange">
                <el-radio label="all">全部商品</el-radio>
                <el-radio label="goods">按指定商品</el-radio>
              </el-radio-group>
            </el-form-item>
            <SkuSelector v-if="!zdItemHidden" @change="getItems" :can-edit="!is_expire" :data="relItems"></SkuSelector>
          </el-card>
        </el-col>
      </el-row>
      <div class="content-center">
        <el-form>
        <el-button type="primary" @click="submitActivityAction('form')" v-loading="saveload">保存</el-button>
        <el-button @click.native="handleCancel">返回</el-button>
        </el-form>
      </div>
    </el-form>

    <CustomizeSelect :store-visible="CustomizeVisible" :is-valid="true" :rel-data-ids="customizeList" :get-status="CustomizeStatus" @chooseStore="CustomizeChooseAction" @closeStoreDialog="closeStoreDialog" />
    <QuestionnaireSelect :visible="questionVisible" :radioId="form.page_link.customize_id" @saveAction="saveAction" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import hotzone from 'vue-hotzone'
import { getPreferentialInfo, createPreferential, updatePreferential} from '@/api/promotions'
import SkuSelector from '@/components/function/skuSelector'
import CustomizeSelect from '@/components/function/customizeSelect'
import QuestionnaireSelect from '@/components/function/questionnaireSelect'
import imgPicker from '@/components/imageselect'
import { getItemsList, getCategory, getSkuList } from '@/api/goods'

export default {
  inject: ['refresh'],
  components: {
    SkuSelector,
    imgPicker,
    CustomizeSelect,
    QuestionnaireSelect,
    hotzone
  },
  data () {
    return {
      loading: false,
      saveload: false,
      relItems: [],
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() < new Date(new Date(new Date().toLocaleDateString()).getTime());
        // },
      },
      is_expire: false,
      showReserve: true,
      form: {
        activity_id : 0,
        orderad_type: '',// 广告位管理名称
        orderad_page: [],// 广告位关联页面
        page_posno: {},// 广告位配置信息
        page_reserve: {}, // 永久保留广告信息
        page_link: {},// 广告链接配置
        activity_title: '',// 广告名称
        activity_desc: '',// 广告描述
        activity_cover: '',// 广告图
        orderad_title: '',// 广告文案
        customize_info: {},
        use_bound: 'goods',
        item_category: [],
        items: [],
        color_one: '#FD7624',
        color_two: '#FD7624',
        text_color: '#ffffff',
        start_time: 0,
        end_time: 0,
        customize_id: '0',
        orderad_time: '',
        img_hotzone_info: [],
        long_time: false,
        category_id:''
      },
      rules: {
        orderad_type: [{required: true, message: '请选择广告类型', trigger: 'blur'}],
        activity_title: [{required: true, message: '请输入广告名称', trigger: 'blur'},
                          {pattern: /^.{2,30}$/, message: '只能输入2-30个字'}],
        orderad_time: [{required: true, message: '请选择有效期', trigger: 'blur'}],
        activity_desc: [{required: true, message: '请输入广告描述', trigger: 'blur'},
                          {pattern: /^.{2,30}$/, message: '只能输入2-30个字'}],
        orderad_page: [{required: true, message: '请选择广告展示页', trigger: 'blur'}],
        page_posno: [{required: true, message: '请选择广告展示页2', trigger: 'blur'}],
        orderad_title: [{required: true, message: '请输入广告标题', trigger: 'blur'},
                        {pattern: /^.{2,30}$/, message: '只能输入2-30个字'}],
        activity_cover: [{required: true, message: '请上传广告图', trigger: 'blur'}],
        // customize_id: [{required: true, message: '请配置跳转页面', trigger: 'blur'}],
        color_one: [{required: true, message: '请配置文案背景色', trigger: 'blur'}],
        color_two: [{required: true, message: '请配置文案背景色', trigger: 'blur'}],
        text_color: [{required: true, message: '请配置文案颜色', trigger: 'blur'}]
      },
      opts: {
        orderad_type : {
          'text' : '文字广告',
          'image': '图片广告'
        },
        // TODO 广告配置
        orderad_page: {
          text : {
            item_detail: {
              title : '商品详情',
              page : {
                'rotation' : '轮播提示栏',
                'courtesy' : '礼遇',
              }
            },
            order_confirm : {
              title : '订单确认页',
              page : {
                'item_gift_card' : '商品赠券区域'
              }
            },
            order_detail : {
              title : '订单详情页',
              page : {
                'order_gift_card' : '商品赠券区域'
              }
            },
            after_sales: {
              title: '售后维修',
              page : {
                'after_sales_card' : '广告位1'
              }
            },
            payment_success: {
              title : '支付成功页',
              page: {
                'courtesy' : '礼遇',
              }
            }
          },
          image : {
            item_detail: {
              title : '商品详情',
              page : {
                'item_info_top' : '详情介绍上方区域',
                'item_info_bottom' : '详情介绍底部区域'
              }
            },
            order_detail : {
              title : '订单详情页',
              page : {
                'order_info' : '订单信息和寻求帮助中间',
                'order_foot' : '页面最底部'
              }
            },
            items_category : {
              title : '分类产品页',
              page : {
                'shelf_bot' : '分类页货架底部',
                'page_bot' : '分类页底部',
                'shelf_top_bot' : '分类页顶部'
              }
            },
            home_page : {
              title : '首页',
              page : {
                'home_open_ad' : '首页开屏广告'
              }
            },
            payment_success: {
              title : '支付成功页',
              page: {
                'recommend_foot' : '为你推荐与专属服务之间',
                'exclusive' : '页面最底部'
              }
            }
          }
        }
      },
      imgDialog: false,
      isGetImage: false,
      zdItemHidden: false,
      hasSaveButton: true,
      categoryList: [],
      CustomizeStatus: false,
      CustomizeVisible: false,
      customize: '',
      customizeList: [],
      sort: 1,
      dateTime: '',
      questionVisible: false,
      compare_value: 'iPhone',
      adavter_type: 'custom',
      page_detail_link: {},
      img_hotzone_link: [], // 保存接口获取的img_hotzone_link_obj 信息
      editImgHotzoonIndex: '',
      checkedCities: [],
      cities: ['永久有效'],
      itemChecked: false,
      categoryOptions:[]
    }
  },
  watch: {},
  methods:{
    async getCategoryOptions(){
      const {data} = await getCategory()
      this.categoryOptions=data.data
    },
    // 热区路径
    showLinks (index, _dataIndex) {
      console.log('00000', index)
      this.linksVisible = true
      if (index !== undefined) {
        this.editorDataIndex = index;
      }
      if (_dataIndex !== undefined) {
        this.editorZoneIndex = _dataIndex;
      }
    },
    // 增加热区
    handleAdd () {
      let item = {
        customize: {
          customize_id: '',
          customize_title: '',
          type: ''
        },
        compare_value: '',
        type: '',
        adavter_type: ''
      }
      this.form.img_hotzone_info.push(item)
    },
    // 修改热区
    handleChange (zone) {
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            let obj = {
              heightPer: item.heightPer,
              leftPer: item.leftPer,
              topPer: item.topPer,
              widthPer: item.widthPer
            }
            Object.assign(this.form.img_hotzone_info[index], obj)
          }
        })
      }, 500)
    },
    // 删除热区
    handleRemove (index) {
      this.form.img_hotzone_info.splice(index, 1)
    },

    handleChangeCheckbox(item,index,isCheck){
      // 点击checkbox后，如果下面只有一个的话，选中下面的radio
      // console.log('isCheck===>',item,isCheck,index);
      if(isCheck){
        if(Object.keys(item.page).length === 1){
          this.form.page_posno[index] = Object.keys(item.page)[0]
        }
      }else{
        // 取消选中checkbox后，radio去除选择
        delete this.form.page_posno[index]
      }
    },
    handleChangeRadio(page_key,item,isRadioCheck){
      // 如果radio选中了，上面的checkbox选中
      // console.log('handleChangeRadio===>',item,isRadioCheck);
      const pagesData = this.opts.orderad_page[this.form.orderad_type];

      if(Object.keys(pagesData[page_key].page).includes(isRadioCheck)){
          if(!this.form.orderad_page.includes(page_key)) this.form.orderad_page.push(page_key)
      }

      // Object.keys(pagesData).forEach((orderadPageItem)=>{
      //   if(Object.keys(pagesData[orderadPageItem].page).includes(isRadioCheck)){
      //     // console.log('是否包含',Object.keys(pagesData[orderadPageItem].page),isRadioCheck);
      //     if(!this.form.orderad_page.includes(orderadPageItem)) this.form.orderad_page.push(orderadPageItem)
      //   }
      // })
    },
    activityDateTimeChange() {
      const dateTime = JSON.parse(JSON.stringify(this.form.orderad_time))
      if (dateTime) {
        this.form.start_time = dateTime[0] === undefined ? 0 : dateTime[0] / 1000
        this.form.end_time = dateTime[1] === undefined ? 0 : dateTime[1] / 1000
      } else {
        this.form.start_time = this.form.end_time = 0
      }
    },
    changeOrderAdType() {
      this.form.orderad_page = [];
      this.form.page_posno = {};
      if ( this.form.orderad_type == 'text' ) {
        this.form.activity_cover = '';
      } else {
        this.form.activity_desc = '';
        this.form.orderad_title = '';
      }
    },
    reserveChange (val) {
      let msg = '关闭后，若广告失效，用户的历史订单详情页将不再保留该广告内容，确定关闭？'
      if (val) {
        msg = '开启后，用户在广告有效期内下单指定商品的订单详情页将永久保留该广告内容，确定开启？'
      }
      this.form.page_reserve['order_detail'] = !val
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.page_reserve['order_detail'] = val
      })

    },
    // 保存
    submitActivityAction (formName) {
      var is_check = 1;// 验证是否允许提交
      console.log('form data', this.form);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          is_check = 2;
          console.log('submit!!');
        } else {
          console.log('check error.');
          return false;
        }
      });
      if (is_check == 1) {
        return false;
      }
      if ( this.form.orderad_page.length == 0 ) {
        this.$message({
          message: '请选择广告位',
          type: 'error',
          duration: 2000
        });
        return false;
      }
      if ( Object.keys(this.form.page_posno).length == 0 ) {
        this.$message({
          message: '请选择广告位',
          type: 'error',
          duration: 2000
        });
        return false;
      }
      let itemlen = 0;
      if ( this.form.items != undefined ) {
       itemlen = this.form.items.length;
      }
      if ( this.form.use_bound != 'all' && itemlen == 0 ) {
        this.$message({
          message: '请选择商品',
          type: 'error',
          duration: 2000
        });
        return false;
      }
      if ( this.saveload ) {
        return false;
      }
      this.saveload = true;
      var params = this.activityItemField(this.form);
      console.log('params: ', params);
      // return false;
      if ( this.form.activity_id > 0 ) {// edit
        updatePreferential (this.form.activity_id, params).then(response => {
          const that = this
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 2000,
            onClose () {
              that.refresh();
              that.handleCancel()
            }
          })
        })
      } else {// add
        // console.log('submit params: ', params);
        params.activity_subtitle = ""

        createPreferential (params).then(res => {
          const that = this
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 2000,
            onClose () {
              that.refresh();
              that.handleCancel()
            }
          })
        })
      }
    },
    // 组装热区图
    handleHotzoneCompare(){
      if(this.form.img_hotzone_info){
        this.form.img_hotzone_info.map((item)=>{
          if(item.adavter_type==='compare'|| item.adavter_type == 'recommend'){
            item.customize.customize_title = item.compare_value
          }
          item.customize.type = item.adavter_type
        })
      }
    },

    // 组装自定义字段 用于提交表单
    activityItemField(form) {
      let itemConf = []
      if ( form.items[0] != undefined ) {
        itemConf = form.items;
      }
      let orderadConf = {
        type: form.orderad_type,
        title: form.orderad_title,
        desc: form.activity_desc,
        color_one: form.color_one,
        color_two: form.color_two,
        text_color: form.text_color,
        use_bound: form.use_bound,
        items: itemConf,
        page_link: form.page_link,
        page_arr: form.orderad_page,
        page_pos: form.page_posno,
        page_reserve: form.page_reserve,
        sort : this.sort,
        img_hotzone_info: form.img_hotzone_info,
        category_id: form.category_id,
      };
      orderadConf.page_link.type = this.adavter_type || 'custom'
      if (this.adavter_type == 'compare'|| this.adavter_type == 'recommend') {
        orderadConf.page_link.customize_title = this.compare_value
      }
      this.handleHotzoneCompare()
      let customize_id = '0';
      if ( form.page_link.customize_id != undefined ) {
        customize_id = form.page_link.customize_id;
      }
      return {
        'activity_title' : form.activity_title,// 标题
        'activity_cover' : form.activity_cover,// 背景图
        'customize_id' : customize_id,//自定义页面id
        'type' : 'orderAD',// 广告管理
        'distributor_ids': 0,
        'start_time' : form.start_time,
        'end_time' : form.end_time,
        'city_list' : '',
        'rel_city' : '',
        'items': orderadConf,
        'long_time': form.long_time,
      };
    },
    // 修改，页面初始化处理保存过的数据
    getActivityDetail(id) {
      this.loading = true;
      getPreferentialInfo(id).then(res => {
        this.loading = false;
        let response = res.data.data
        console.log('response: ', response);
        this.is_expire = response.is_expire
        let config = response.items_arr;
        for (const i in config.page_reserve) {
          config.page_reserve[i] = config.page_reserve[i] === 'true' ? true : false
        }
        if (!config.page_reserve) {
          config.page_reserve = {}
        }
        this.page_detail_link = config.page_link
        this.img_hotzone_link = config.img_hotzone_info && [...config.img_hotzone_info] || []
        let data = {};
        if (response.is_expire && ((config.page_reserve['order_detail'] && config.page_reserve['order_detail'] === 'false') || !config.page_reserve['order_detail'])) {
          this.showReserve = false
        }

        if ( config.type ) {// 新活动
          let page_link = {
            customize_id: '0',
            customize_title: ''
          }
          if ( config.page_link && config.page_link.customize_id != undefined ) {
            page_link.customize_id = config.page_link.customize_id;
            page_link.customize_title = config.page_link.customize_title;
          }
          var itemConf = [];
          if ( config.items && config.items[0] != undefined && config.items[0].item_id != undefined ) {
            itemConf = config.items;
          }
          console.log("🚀 ~ getPreferentialInfo ~ response:", response)

          data = {
            activity_id : id,
            orderad_type: config.type,// 广告位管理名称
            orderad_page: config.page_arr,// 广告位关联页面
            page_posno: config.page_pos,// 广告位配置信息
            page_reserve: config.page_reserve,// 永久保留信息
            page_link: page_link,// 广告链接配置
            activity_title: response.activity_title,// 广告名称
            activity_desc: config.desc,// 广告描述
            activity_cover: response.activity_cover,// 广告图
            orderad_title: config.title,// 广告文案
            customize_info: {},
            use_bound: config.use_bound,
            item_category: itemConf,
            color_one: config.color_one,
            color_two: config.color_two,
            text_color: config.text_color,
            items: itemConf,
            customize_id: '0',
            start_time: response.start_time,
            end_time: response.end_time,
            img_hotzone_info: config.img_hotzone_info || [],
            long_time: response.long_time || false,
            category_id: config.category_id,
          }

          // 设置永久有效
          if (response.long_time) {
            this.checkedCities = this.cities
            this.itemChecked = response.long_time
          }

          if ( data.page_link && data.page_link.customize_id != undefined ) {
            data.customize_id = data.page_link.customize_id;
          }
          this.form.orderad_time = [data.start_time * 1000, data.end_time * 1000]
        } else {
          let def_type = 'image';
          let page_link = {
            customize_id: config.customize_id | '0',
            customize_title: config.customize_title | '',
          }
          data = {
            activity_id : id,
            orderad_type: def_type,// 广告位管理名称, 老数据默认为图片
            orderad_page: [config.page_type],// 广告位关联页面
            page_posno: {},// 广告位配置信息
            page_reserve: {},// 永久保留信息
            page_link: page_link,// 广告链接配置
            activity_title: response.activity_title,// 广告名称
            activity_desc: '',// 广告描述
            activity_cover: response.activity_cover,// 广告图
            orderad_title: '',// 广告文案
            customize_info: {},
            use_bound: 'goods',
            item_category: [],
            color_one: '',
            color_two: '',
            items: [],
            customize_id: config.customize_id
          }
        }
        this.adavter_type = config.page_link.type || 'custom'
        if (this.adavter_type == 'compare'|| this.adavter_type == 'recommend') {
          this.compare_value = config.page_link.customize_title || ''
        }
        Object.assign(this.form, data)
        console.log('response_form: ', this.form);
        this.relItems = response.itemTreeLists || []
        this.sort = config.sort | 1
        this.zdItemHidden = true
        if (this.form.use_bound == 'goods') {
          this.zdItemHidden = false;
        }
      })
    },
    handleCancel () {
      this.$router.back(-1)
    },
    itemTypeChange (val) {
      this.zdItemHidden = true
      this.form.items = []
      if (val === "goods") {
        this.zdItemHidden = false
      }
    },
    getItems(data) {
      this.ItemsList = []
      let items = []
      data.forEach(item => {
        var itemData = {
          'item_id': item.item_id,
          'goods_id': item.goods_id,
          'default_item_id': item.default_item_id,
          'item_name': item.item_name,
          'item_bn': item.item_bn,
          'item_spec_desc': item.item_spec_desc,
          'limit_num_type': this.limit_num_type,
          'limit_num': item.limit_num ? item.limit_num : 0
        }
        if (this.limit_num_type === 'spu') { // 限购维度为spu时，只存储一条记录
          if (item.is_default == '1') {
            itemData.item_spec_desc = '全规格'
            items.push(itemData)
          }
        } else {
          items.push(itemData)
        }

        this.ItemsList.push(item);
      })
      this.form.items = items
    },
    // 图片选择弹层相关方法
    pickImg (data) {
      this.form.activity_cover = data.url
      this.imgDialog = false
    },
    closeImgDialog () {
      this.imgDialog = false
    },
    handleImgChange () {// 上传卡封面
      this.imgDialog = true
      this.isGetImage = true
    },
    closeStoreDialog () {
      this.CustomizeStatus = false
      this.CustomizeVisible = false
    },

    addCustomizeAction(index) {
      this.CustomizeStatus = true
      this.CustomizeVisible = true
      this.editImgHotzoonIndex = index
    },
    // 选择的自定义页面
    CustomizeChooseAction (data) {
      this.CustomizeVisible = false
      this.CustomizeStatus = false
      if (data === null || data.length <= 0) return
      // console.log("CustomizeChooseAction CustomizeChooseAction------------------data:", data)
      // console.log('this.editImgHotzoonIndex',this.editImgHotzoonIndex,99)
      if(this.editImgHotzoonIndex!==''){
        // 加上热区图的选择连接
        this.form.img_hotzone_info[this.editImgHotzoonIndex].customize.customize_id  = data[0].id
        this.form.img_hotzone_info[this.editImgHotzoonIndex].customize.customize_title = data[0].page_name
        this.editImgHotzoonIndex = ''
      }else{
        // 兼容旧版本，整个页面的链接数据
        this.form.customize_info  = data[0]
        this.form.page_link.customize_id = data[0].id
        this.form.page_link.customize_title = data[0].page_name
        this.form.customize_id = this.form.page_link.customize_id
      }
    },
    // 选择调查问卷
    addQuestionAction (index) {
      this.questionVisible = true
      this.editImgHotzoonIndex = index
    },
    // 调查问卷选择结果
    saveAction (data) {
      this.closeDialog()
      if (data === null || data.length <= 0) return

      if(this.editImgHotzoonIndex!=='') {
        // 热区图问卷调查数据
        this.form.img_hotzone_info[editImgHotzoonIndex].customize.customize_id = data[0].id
        this.form.img_hotzone_info[editImgHotzoonIndex].customize.customize_title = data[0].tpl_name
        this.editImgHotzoonIndex = ''
      }else{
        // 兼容旧版本，非热区图
        this.form.customize_info = data[0]
        this.form.page_link.customize_id = data[0].id
        this.form.page_link.customize_title = data[0].tpl_name
        this.form.customize_id = data[0].id
      }
    },
    closeDialog () {
      this.questionVisible = false
    },
    // 清空已选链接
    onAdavterTypeChange (value,imgHotZoonIndex) {
      console.log(value,imgHotZoonIndex,111)
      if(imgHotZoonIndex){
        const link_type = this.img_hotzone_link.length && this.img_hotzone_link[imgHotZoonIndex] && this.img_hotzone_link[imgHotZoonIndex].type || 'custom'
        if (value != link_type) {
          this.form.img_hotzone_info[imgHotZoonIndex].customize = {customize_id: '',customize_title: '',type: ''}
        } else {
          this.form.img_hotzone_info[imgHotZoonIndex].customize = this.img_hotzone_link.length && this.img_hotzone_link[imgHotZoonIndex] && this.img_hotzone_link[imgHotZoonIndex].customize || {customize_id: '',customize_title: '',type: ''}
        }
      }else{
        // 兼容旧版本，非热区图
        const link_type = this.page_detail_link.type || 'custom'
        if (value != link_type) {
          this.form.page_link = {}
        } else {
          this.form.page_link = this.page_detail_link
        }
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length > 0 ? true : false
      this.form.long_time = checkedCount
      this.itemChecked = checkedCount
      this.form.orderad_time = ''
      this.form.start_time = this.form.end_time = 0
    },
    inputRadioGroup(v) {
      if (v == 'text') {
        this.form.long_time = false
        this.itemChecked = false
        this.checkedCities =[]
      }
    }
  },
  mounted () {
    console.log('query : ', this.$route.query);
    if (this.$route.query.id) {
      this.getActivityDetail(this.$route.query.id)
    }else{
      this.$set(this.form,'orderad_type','image')
    }
    this.getCategoryOptions()
  },
}
</script>

