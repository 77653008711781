<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="430" :row-actions-align="'left'"
      :fixed-row-action="true" :split-count="6" url="pagestemplate/lists" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }">
      <template slot="tableTop">
        <el-tabs v-model="platform" type="card" @tab-click="handleTabPlatformClick">
          <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
          <el-tab-pane label="抖音小程序" name="byte_mini"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabActiveClick">
          <el-tab-pane :label="`全部(${examine_status_total_count})`" name="all"></el-tab-pane>
          <el-tab-pane :label="`待提交(${examine_status_before_count})`" name="waiting"></el-tab-pane>
          <el-tab-pane :label="`待审核(${examine_status_waiting_count})`" name="examine"></el-tab-pane>
          <el-tab-pane :label="`审核未通过(${examine_status_refuse_count})`" name="refuse"></el-tab-pane>
          <el-tab-pane :label="`审核已通过(${examine_status_pass_count})`" name="pass"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <SpDialog ref="customPageDialogRef" v-model="customPageDialog" :title="customPageTitle" :modal="false"
      :form="customPage" :form-list="customPageList" @onSubmit="onCustomPageSubmit" />
    <SpDialog ref="examineDialogRef" v-model="examineDialog" :title="examineTitle" :modal="false" :form="examineForm"
      :form-list="examineFormList" @onSubmit="handleExamineFormSubmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSetting, useDialog } from '@shopex/finder'
import { getCustomPageList, createCustomPage, delCustomPage, editCustomPage } from '@/api/wxa'
import { addPagesTemplate, deletePagesTemplate } from '@/api/template'
import { EXAMINE_STATUS, EXAMINE_STATUS_TYPE, PAGE_TYPE, PAGE_SENCE,DOUYIN_PAGE_TYPE } from '@/consts'
export default {
  data() {
    return {
      platform: 'wxapp',
      customPageTitle: '新增页面',
      customPage: null,
      customPageDialog: false,
      examineForm: {
        is_auto_replace: 1,
        cron_replace_time: ''
      },
      examineFormList: [{
        label: '页面更新方式',
        key: 'is_auto_replace',
        component: ({ key }) => {
          return <el-radio-group v-model={this.examineForm[key]} size="small" onChange={(e) => {
            this.$set(this.examineForm, 'cron_replace_time', '')
          }}>
            <el-radio-button label="1">自动更新</el-radio-button>
            <el-radio-button label="2">定时更新</el-radio-button>
          </el-radio-group>
        }
      }, {
        label: '定时更新时间',
        key: 'cron_replace_time',
        isShow: () => {
          return this.examineForm.is_auto_replace == 2
        },
        component: ({ key }) => {
          return <el-date-picker
            v-model={this.examineForm[key]}
            type="datetime"
            picker-options={
              {
                disabledDate(time) {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                }
              }
            }
            value-format='timestamp'
            placeholder="选择日期时间">
          </el-date-picker>
        }
      }],
      examineTitle: '',
      examineDialog: false,
      examine_status_total_count: 0,
      examine_status_waiting_count: 0,
      examine_status_refuse_count: 0,
      examine_status_pass_count: 0,
      examine_status_before_count: 0,
      activeName: 'all',
    }
  },
  methods: {
    beforeSearch(params) {
      const status = {
        'all': '',
        'waiting': '1',
        'examine': '2',
        'refuse': '3',
        'pass': '4'
      }
      const { pageSize: page_size, page: page_no, weapp_pages, ...query } = params
      let pagetype = []
      console.log(this.platform,weapp_pages)
      
      if (this.platform=='wxapp'&&(!weapp_pages || weapp_pages.length == 0)) {
        pagetype = PAGE_TYPE.map(item => item.value)
      } else if(this.platform=='byte_mini'&&(!weapp_pages || weapp_pages.length == 0)) {
        pagetype = DOUYIN_PAGE_TYPE.map(item => item.value)
      }else {
        pagetype = weapp_pages
      }
      params = {
        ...query,
        page_size,
        page_no,
        weapp_pages: pagetype,
        examine_status: status[this.activeName],
        platform: this.platform
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.examine_status_before_count = data.examine_status_before_count
      this.examine_status_total_count = data.examine_status_total_count
      this.examine_status_waiting_count = data.examine_status_waiting_count
      this.examine_status_refuse_count = data.examine_status_refuse_count
      this.examine_status_pass_count = data.examine_status_pass_count
      return data
    },
    //切换抖音小程序还是微信小程序
    handleTabPlatformClick() {
      this.$refs.finder.$refs.finderSearch.value.weapp_pages=[]
      this.$refs.finder.refresh(true)
    },
    //切换审核状态
    handleTabActiveClick(tab) {
      this.$refs.finder.refresh(true)
    },
    //新增/修改页面
    async onCustomPageSubmit() {
      await addPagesTemplate(this.customPage)
      this.$message({
        type: 'success',
        message: '保存页面成功'
      })
      this.customPageDialog = false
      this.$refs.finder.refresh(true)
    },
    //审核状态
    async handleExamineTem(v, row) {
      const { examine_status, status } = row
      if (examine_status != 4) {
        await this.$alert('该页面尚未通过审核！', '提示', {
          showConfirmButton: false,
          type: 'warning'
        }).catch(() => { })
        row.status = v == 1 ? 2 : 1
        return
      }
      this.$confirm(`确定${status == 2 ? '禁用' : '启用'}该页面吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.modifyPagesTemplateStatus({
            pages_template_id: row.pages_template_id,
            template_name: row.template_name,
            status: v
          })
          this.$message({
            type: 'success',
            message: '提交审核成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => {
          row.status = v == 1 ? 2 : 1
        })
    },
    //新增页面
    handleAddCustomPage() {
      this.customPageDialog = true
      this.customPage = {
        rel_city: false,
        template_title: '', //页面名称
        template_pic: '',
        platform: this.platform,
        template_name: this.template_name,
        page_type: 'default',
        distributor_id: 0, //固定值0
        pages_template_id: '', //页面id，更新时必填
        weapp_page: ''
      }
      this.customPageTitle = '新增页面'
    },
    //编辑页面
    async handleEditCustomPage(row) {
      this.customPageDialog = true
      this.customPageTitle = '编辑页面'
      this.customPage = row
    },
    //删除页面
    async handleDelCustomPage({ pages_template_id }) {
      this.$confirm('确认删除？').then(async () => {
        await deletePagesTemplate(pages_template_id)
        this.$message.success('删除成功')
        this.$nextTick(() => {
          this.$refs.finder.refresh()
        })
      })
    },
    //装修页面
    handleDecorationPage(row) {
      const { weapp_pages, pages_template_id } = row
      this.$router.push({
        path: this.matchInternalRoute('wxapp/manage/decorate'),
        query: {
          scene: PAGE_SENCE[weapp_pages],
          template_id: pages_template_id,
          template_douyin:this.platform=='byte_mini'?1:''
        }
      })
    },
    //审核记录
    handleLogDialog(row) {
      useDialog(null, {
        title: `审核记录`,
        id: row[0].pages_template_id,
        component: () => import('./comps/examineLog.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'medium'
          }
        ]
      })
    },
    //提交审核弹窗
    handleExamineDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        telId: row.pages_template_id,
      }
      if (row.status == 1) {
        this.examineTitle = '提交审核'
        this.examineDialog = true
      } else {
        this.handleExamineFormSubmit()
      }
    },
    //提交审核/更新时间
    async handleExamineFormSubmit() {
      const now = new Date();
      const halfHourLater = new Date(now.getTime());
      const timestap = Date.parse(halfHourLater)
      const { is_auto_replace, cron_replace_time, log_id, telId } = this.examineForm
      if (is_auto_replace == 2 && cron_replace_time < timestap) {
        this.$message.error('定时更新时间不能小于当前时间')
        this.$set(this.examineForm, 'cron_replace_time', '')
        return
      }
      if (log_id) {
        await this.$api.template.updateReplaceTime({
          log_id,
          is_auto_replace,
          cron_replace_time: cron_replace_time / 1000,
        })
        this.$message({
          type: 'success',
          message: '更新时间成功!'
        })
        this.$nextTick(() => {
          this.examineDialog = false
          this.$refs.finder.refresh()
        })
        return
      }
      this.$confirm('确定提交审核？审核期间将不能编辑该页面。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.submitExamineTemplate({
            template_id: telId,
            is_auto_replace,
            cron_replace_time: cron_replace_time / 1000,
          })
          this.$message({
            type: 'success',
            message: '提交审核成功!'
          })
          this.$nextTick(() => {
            this.examineDialog = false
            this.$refs.finder.refresh()
          })
        })
    },
    // 变更更新时间
    async handleChangeExamineTimeDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        log_id: row.log_id
      }
      this.examineTitle = '变更更新时间'
      this.examineDialog = true
    },
    //撤销审核
    async handleRevertExamineDialog(row) {
      this.$confirm('确定撤销审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.revertExamineTemplate({ log_id: row.log_id })
          this.$message({
            type: 'success',
            message: '撤销审核成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => { })
    },
  },
  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type', 'admin_type']),
    setting(){
      const options = this.platform == 'wxapp' ? PAGE_TYPE : DOUYIN_PAGE_TYPE
      return createSetting({
        search: [{ name: '页面ID', key: 'pages_template_id', placeholder: "模板 ID" },
        { name: '页面类型', placeholder: "请选择页面类型", key: 'weapp_pages', type: 'select', options:options, multiple: true }, { name: '页面名称', key: 'template_title', placeholder: "页面名称" }],
        columns: [
          { name: '页面ID', key: 'pages_template_id', minWidth: 80 },
          { name: '页面类型', key: 'template_title', minWidth: 220 },
          { name: '页面类型', key: 'weapp_pages', minWidth: 120, render: (h, { row }) => h('span',options.find(item => item.value == row.weapp_pages)?.label) },
          {
            name: '审核状态',
            key: 'examine_status', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          { name: '模版更新状态', key: 'replace_status', minWidth: 120 },
          { name: '最后更新时间', key: 'last_updated_time', minWidth: 160 },
          {
            name: '启用状态',
            key: 'status',
            showType: 'switch', minWidth: 100,
            componentProps: {
              inactiveValue: 2,
              activeValue: 1,
              change: (v, row) => {
                this.handleExamineTem(v, row)
              }
            }
          },
        ],
        actions: [
          {
            name: '新增页面',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            action: {
              handler: () => {
                this.handleAddCustomPage()
              }
            }
          },
          {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleEditCustomPage(row[0])
              }
            }
          },
          {
            name: '删除',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDelCustomPage(row[0])
              }
            }
          }, {
            name: '页面装修',
            key: 'detail',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDecorationPage(row[0])
              }
            }
          },
          {
            name: '审核记录',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleLogDialog(row)
              }
            }
          },
          {
            name: '提交审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 1
            },
            action: {
              handler: (row) => {
                this.handleExamineDialog(row[0])
              }
            }
          },
          {
            name: '变更更新时间',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 4 && row.update_cron_replace_time
            },
            action: {
              handler: (row) => {
                this.handleChangeExamineTimeDialog(row[0])
              }
            }
          },
          {
            name: '撤销审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 2
            },
            action: {
              handler: (row) => {
                this.handleRevertExamineDialog(row[0])
              }
            }
          },
        ]
      })
    },

    customPageList() {
      return [
        {
          label: '页面名称',
          key: 'template_title',
          type: 'input',
          maxlength: 30,
          placeholder: '请输入页面名称',
          required: true,
          message: '页面名称不能为空'
        }, {
          label: '页面类型',
          key: 'weapp_pages',
          type: 'select',
          options: this.platform == 'wxapp' ? PAGE_TYPE : DOUYIN_PAGE_TYPE,
          required: true,
          message: '页面类型不能为空'
        }]
    }
  },
}
</script>

<style lang="scss" scoped></style>
