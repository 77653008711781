<template>
  <div>
    <div v-if="$route.path.indexOf('_operation') === -1">
      <SpFinder
        ref="finder"
        row-key="id"
        :split-count="10"
        :url="table_url"
        :setting="setting"
        fixed-row-action
        reserve-selection
        :hooks="{
          beforeSearch
        }"
      >
        <template #tableTop>
          <div class="table-top">
            <div>
              <el-tabs v-model="activeTab" type="card" @tab-click="handleClickTab">
                <el-tab-pane
                  v-for="item in StatusOption"
                  :key="item.value"
                  :label="item.label"
                  :name="item.value"
                />
              </el-tabs>
            </div>
          </div>
        </template>
      </SpFinder>
      <ImportDialog
        ref="importAddDialog"
        v-show="false"
        buttonName="上传门店"
        buttonSize="medium"
        fileName="上传门店"
        fileType="normal_gift_activity_distributor"
        :relId="relId"
      />
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { tableSetting, StatusOption } from './config'
import { List } from './api'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      activeTab: 'all',
      table_url: List,
      StatusOption,
      relId: ''
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
    handleClickTab() {
      this.refresh()
    },
    openImportAddDialog(id) {
      this.relId = id
      this.$refs.importAddDialog.visible = true
    },
    beforeSearch(params) {
      return {
        ...params,
        activity_status_desc: this.activeTab,
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 80%;
}
</style>
