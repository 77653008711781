import { createSetting } from '@shopex/finder'
import { IS_DISTI_ADMIN } from '@/utils'

const ITEM_STATUS_ENUM = {
  0: '非ASP',
  1: 'ASP'
}

// 是否
const REST_DAY_OPTIONS = [
  {label: '周一', value: 1},
  {label: '周二', value: 2},
  {label: '周三', value: 3},
  {label: '周四', value: 4},
  {label: '周五', value: 5},
  {label: '周六', value: 6},
  {label: '周日', value: 7}
]

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
    {
      name: '归属经销商',
      key: 'dealer',
      slot: 'dealer'
    },
    {
      name: '分销商VAD',
      key: 'distributor_vad',
    },
    {
      name: '渠道RTM',
      key: 'channel_rtm',
    },
    {
      name: '作息日',
      key: 'session_week',
      type: 'select',
      multiple: true,
      options: REST_DAY_OPTIONS
    },
  ],
  columns: [
    {
      name: '门店名称',
      key: 'name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.name}
            </div>
            <div class="goods-code">
              <div>
                Apple ID：{row.shop_code}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '所属经销商',
      key: 'dealer_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.dealer_name}
            </div>
            <div class="goods-code">
              <div>
                HQID：{row.dealer_hqid}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '组织信息',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              分销商VAD：{row.distributor_vad}
            </div>
            <div>
              渠道RTM：{row.channel_rtm}
            </div>
          </div>
        )
      }
    },
    {
      name: '维修类型',
      key: 'is_repair_asp',
      width: 100,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '作息设置',
      key: 'sessions',
      minWidth: 250,
      render: (h, {row}) => {
        return (
          <div>
            {row.sessions_weekday?.map((item, index) => {
              return <span style={{color: item.pause? 'red': ''}}>
                {REST_DAY_OPTIONS.find(f => f.value == item.session_week)?.label}{item.pause ? `（暂停）`: ''}{item.has_sessions ? '': `（未设置）`}{row.sessions_weekday?.length !== index + 1 ? '、' : ''}
              </span>
            })}
          </div>
        )
      }
    },
  ],
  actions: [
    {
      name: '导出作息',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '导入作息',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      visible: () => !IS_DISTI_ADMIN(),
      action: {
        handler: () => {
          vm.openImportAddDialog()
        }
      }
    },
    {
      name: '编辑作息',
      type: 'button',
      buttonType: 'text',
      visible: () => !IS_DISTI_ADMIN(),
      action: {
        handler: (rows) => {
          vm.$router.push({
            path: vm.matchInternalRoute('aftersalessupport/repair/sessions'),
            query: {
              distributor_id: rows[0].distributor_id,
              distributor_name: rows[0].name,
              shop_code: rows[0].shop_code
            }
          })
        }
      }
    }
  ]
})
