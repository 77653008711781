import { pickBy } from "@/utils";
import CompHotSetting from "../../comps/comp_hotSetting";
import { isEmpty } from "lodash";
import AttrRight from "./attr-right.vue";

export default {
  name: "userRights",
  setting: [
    // { label: "标题", key: "title", component: "input", value: "热区图" },
    // {
    //   label: "副标题",
    //   key: "subtitle",
    //   component: "input",
    //   value: "图上随意画块块",
    // },
    { label: "楼层", key: "floor_var", component: "input" },
    {
      label: "权益类型",
      key: "right",
      component: function(h,{key}){
        return <AttrRight v-model={this.value[key]} uuid={this.value.uuid}/>
      },
      value:'coupon'
    }, 
    {
      label: "热区设置",
      key: "data",
      component: function (h, { key }) {
        const visible = this.value.visible?.some((v) => v === "one")
        return (
          <CompHotSetting show-type visible={visible} v-model={this.value[key]} uuid={this.value.uuid} padded={this.value.padded} on-change={(e) => {
            const hasRegister = e.data?.some((v) => v.id === "register");
            if (hasRegister) {
              this.$set(this.value, 'visible', ['one', 'two']);
              this.$set(this.value, 'channelType', 'all');
              that.$set(that.value, 'channel', []);
            }
          }} />
        );
      },
      value: { imgUrl: "", data: [] },
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base:{flowName_var,...base},
      config: { imgUrl, ...config },
      data,
    } = v;
    return {
      name,
      ...base,
      ...config,
      data: {
        imgUrl,
        flowName_var,
        data,
      },
    };
  },
  transformOut: (v) => {
    const { data: { flowName_var, imgUrl, data }, floor_var } = v
    if (!floor_var) {
      throw '[挂件]会员权益 请输入楼层';
    }
    if (imgUrl && !flowName_var) {
      throw '[挂件]会员权益 请输入热区设置-资源位名称';
    }
    for (let i in data) {
      if (!data[i].imgName) {
        throw '[挂件]会员权益 请输入热区设置-热区名称';
      }
    }

    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          floor_var: "floor_var",
          flowName_var: "data.flowName_var",
        });
      },
      config: (v) => {
        return pickBy(v, {
          imgUrl: "data.imgUrl",
          right: "right"
        });
      },
      data: "data.data",
    });
  },
};
