import { createSetting } from '@shopex/finder'
import { formatTimeStampToStr } from '@/utils/index'

export const StatusOption = [
  {
    label: '全部',
    value: '0'
  },
  {
    label: '已完成',
    value: '1'
  },
  {
    label: '退货',
    value: '2'
  },
]

export const SOURCE_ENUM = {
  'jd': '京东到家',
  'tb': '淘宝',
  'mt': '美团闪购',
  'wxapp': '微信小程序',
  'dy': '抖音',
  'jdzy': '京东自营',
  'eleme': '饿了么',
  'amap': '高德',
  'qyg': '企业购小程序',
  'pos': '线下单',
  'douchao': '抖音超市'
}

const ITEM_STATUS_OPTIONS = [
  { label: '优惠券', value: 1 },
  { label: '额外保修', value: 2 },
  { label: '实物赠品', value: 3 }
]

const ITEM_STATUS_ENUM = {
  1: '优惠券',
  2: '额外保修',
  3: '实物赠品'
}

const EnumToOptions = obj => {
  return Object.keys(obj).map(item => {
    return {
      label: obj[item],
      value: item
    }
  })
}

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '活动id',
      key: 'activity_id',
    },
    {
      name: '活动名称',
      key: 'activity_title',
    },
    {
      name: '设备名称',
      key: 'item_name',
    },
    {
      name: '设备SN',
      key: 'item_sn',
    },
    {
      name: '订单编号',
      key: 'customer_order_id',
    },
    {
      name: '会员id',
      key: 'vip_id',
    },
    {
      name: '订单归属平台',
      key: 'order_source',
      type: 'select',
      options: EnumToOptions(SOURCE_ENUM)
    },
    {
      name: '权益类型',
      key: 'interests_type',
      type: 'select',
      options: ITEM_STATUS_OPTIONS,
      clearable: true
    },
    {
      name: '领取时间',
      key: 'send_time',
      type: 'date-time-picker',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
  ],
  columns: [
    { name: '活动ID', key: 'activity_id', minWidth: 100 },
    { name: '活动名称', key: 'activity_title', minWidth: 100 },
    { name: '领取设备SN', key: 'item_sn', minWidth: 120 },
    { name: '领取设备名称', key: 'item_name', minWidth: 120 },
    { name: '设备MPN', key: 'item_bn', minWidth: 120 },
    { name: '平台订单编号', key: 'customer_order_id', minWidth: 120 },
    {
      name: '购买平台',
      key: 'order_source',
      minWidth: 100,
      formatter: (v) => SOURCE_ENUM[v]
    },
    {
      name: '订单状态',
      key: 'order_status',
      minWidth: 100,
      formatter: (v) => StatusOption.find(item => item.value === v)?.label
    },
    {
      name: '领取权益',
      key: 'interests_type',
      minWidth: 100,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    { name: '领取会员ID', key: 'vip_id', minWidth: 100 },
    {
      name: '领取时间',
      key: 'send_time',
      minWidth: 150,
      render(_, {row}) {
        if(!row.send_time) return '-'
        return formatTimeStampToStr(row.send_time)
      }
    }
  ],
  actions: [
    {
      name: '导出',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
  ]
})
