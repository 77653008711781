import { createSetting } from '@shopex/finder'

export const tableSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '商品ID', key: 'item_id', width: 90 },
    {
      name: '商品名称',
      key: 'item_name',
      minWidth: 150,
      render: (h, { row }) => {
        return (
          <div>
            { row.item_name }
            <div style="color: #8d8d8d">{ row.item_spec_desc }</div>
          </div>
        )
      }
    },
    { name: '规格', key: 'item_spec_desc', minWidth: 150 },
    { name: '商品货号', key: 'item_bn', minWidth: 150 },
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          vm.handleDelete(rows)
        }
      }
    },
  ]
})
