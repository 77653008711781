// 菜单路由
import Dashboard from '@/view/base/index' // 首页仪表盘
import Shopwxshop from '@/view/base/shop/wxshop' // 微信门店管理
import Wxshopsetting from '@/view/base/shop/shopsetting' // 门店信息配置
import Comments from '@/view/base/shop/comments' // 门店评论
import Assetpayment from '@/view/base/setting/payment' // 微信支付账户配置
import Assetaccountactivation from '@/view/base/asset/accountactivation/index' // 订购续费
import Datamessage from '@/view/base/shortmessage/index' // 短信账户
import Stepcheck from '@/view/base/stepcheck/index' // 跳步检测
import Shotrtlink from '@/view/base/shotrtlink/index' // shotrtlink
import Staffleader from '@/view/base/shopstaff/salesperson/leaderlist' // 门店管理员
import Accountmanagement from '@/view/base/account/management' // 平台管理员 --
import Accountroles from '@/view/base/account/roles' // 角色管理 --
import Basearticle from '@/view/base/article/index' // 文章管理
import Basecurrency from '@/view/base/currency/index' // 货币管理
import Baseexport from '@/view/base/exportlog/index' // 日志导出
import Brand from '@/view/brand/wechat/open' // 公众号授权
import Brandcustomer from '@/view/brand/wechat/customservice' // 公众号客服管理
import Brandmenu from '@/view/brand/wechat/custommenu' // 自定义公众号菜单
import Brandreply from '@/view/brand/wechat/reply' // 公众号消息自动回复设置
import Brandfans from '@/view/brand/wechat/fans' // 公众号粉丝管理
import Brandmaterial from '@/view/brand/wechat/aterialmanagement' // 素材管理
import Goodscategory from '@/view/mall/goods/category' // 商品分类
import Goodsmaincategory from '@/view/mall/goods/maincategory' // 商品分类
import Servicegoods from '@/view/mall/goods/service/index' // 服务类商品管理
import Goodsphysical from '@/view/mall/goods/physical/list' // 实体类商品
import Goodsappend from '@/view/mall/goods/append/list' // 实体类商品
import Physicalupload from '@/view/mall/goods/physical/normalGoodsUpload' // 实体商品导入
import Physicalstoreupload from '@/view/mall/goods/physical/normalGoodsStoreUpload' // 实体商品库存导入
import GoodsUnmount from '@/view/mall/goods/unmount/index' // 店铺商品上下架
// import GoodsPricecheck from '@/view/mall/goods/pricecheck/pricecheckindex' // 店铺商品上下架

import GoodsStage from '@/view/mall/goods/stage/index' // 可分期商品
import GoodsDraft from '@/view/mall/goods/draft/index' // 草稿商品
import Physicalgifts from '@/view/mall/goods/physicalGifts' // 实物赠品
import Buyxgivey from '@/view/mall/marketing/buyxgivey' // 买X赠Y
import Buyxgivey_operation from '@/view/mall/marketing/buyxgivey/operation' // 买X赠Y-操作

import Physicalrecommendupload from '@/view/mall/goods/physical/normalGoodsRecommendUpload' // 搭售商品导入
import Physicalprofitupload from '@/view/mall/goods/physical/normalGoodsProfitUpload' // 实体商品分润导入
import Goodsbrand from '@/view/mall/goods/attributes/brand' // 商品品牌管理
import Arrivalnotice from '@/view/member/subscribe/goods/list' // 到货通知
import Servicepayment from '@/view/mall/trade/list' // 交易单管理
import Tradeservice from '@/view/mall/trade/order/list' // 服务订单管理
import Tradenormalorders from '@/view/mall/trade/normalorders/list' // 实物订单管理
import QueryOrder from '@/view/mall/trade/normalorders/query' // 查看订单
import Tradenormalshoporders from '@/view/mall/trade/normalorders/shopList' // 自营订单管理
import Tradedrugorders from '@/view/mall/trade/normalorders/drugList' // 药品需求单
import Tradepayment from '@/view/mall/trade/list' // 交易单管理
import Tradeverification from '@/view/mall/trade/salesmanWorkRecords' // 服务核销单
import Shippingtemplates from '@/view/mall/trade/shipping/templates' // 运费模版
import Marketingdistributor from '@/view/mall/marketing/distributor' // 店铺管理
import Marketingdistributor_editor from '@/view/mall/marketing/distributor_editor' // 店铺编辑Marketing
import Marketingdistributor_template from '@/view/mall/marketing/distributor_template' // 店铺编辑Marketing
import Temporarydeliveryplace from '@/view/mall/marketing/temporaryDeliveryPlace' // 店铺管理
import Temporarydeliveryplace_editor from '@/view/mall/marketing/temporaryDeliveryPlace_editor' // 店铺编辑Marketing
import Deliverysetting from '@/view/mall/marketing/deliverysetting' // 店铺发货设置

import Marketingdistribution_orders from '@/view/mall/marketing/distributionOrders' // 店铺订单
import Distributortags from '@/view/mall/marketing/distributortags' // 商品标签
import Distributorrel_items from '@/view/mall/marketing/distributorRelItems' // 店铺关联商品



import Evaluationconfigset from '@/view/mall/marketing/evaluate_editor' // 评价配置
import Evaluationtag from '@/view/mall/marketing/evaluate_label' // 评价标签
import Evaluationdistributors from '@/view/mall/marketing/evaluate_shopList' // 评价店铺列表
import Evaluationdistributors_detail from '@/view/mall/marketing/evaluate_shopDetails' // 店铺评分详情




// import Distributoraftersalesaddress from '@/view/mall/marketing/distributorAftersalesAddress' //店铺售后地址
import Distributorinvoice from '@/view/mall/marketing/distributorInvoice' // 店铺发票
import Marketinglog from '@/view/mall/marketing/distributionLog' // 店铺记录
import ShopDecoration from '@/view/mall/marketing/shopDecoration' // 微商城店铺装修
import DistributorCode from '@/view/mall/marketing/distributorCode' // 微商城店铺装修
import Marketingsalesman from '@/view/guide/salesman' // 导购员管理
import Marketingsalesmanrole from '@/view/guide/salemanRoleConfig' // 导购员管理
import ShoppingGuideTask from '@/view/guide/shoppingGuideTask' // 导购任务
import ShoppingGuideTask_statistics from '@/view/guide/shoppingGuideTaskStatistics' // 导购任务统计
import Shoppingguidenotification from '@/view/guide/shoppingguidenotification' // 导购员管理
import Salemancustomercomplaints from '@/view/guide/salemanCustomerComplaints' // 导购员客诉管理
import SalemanLoginHistory from '@/view/guide/SalemanLoginHistory' // 导购员登录记录
import SalesmanRelationship from '@/view/guide/salesman_relationship' // 导购会员关系
import Shopactivities from '@/view/guide/shopactivities' // 导购知识库
import Shopnoticetempl from '@/view/guide/shopnoticetempl' // 导购消息模版
import Reservationsetting from '@/view/mall/reservation/setting/index' // 预约设置
import Mallreservation from '@/view/mall/reservation/resourcesetting/index' // 预约管理
import Reservationorder from '@/view/mall/reservation/resourcesetting/reservation' // 预约单
import Mallrecharge from '@/view/mall/storeddeposit/index' // 会员账户储值
import Aftersaleslist from '@/view/mall/aftersales/list' // 售后单管理
import Aftersalesrefundlist from '@/view/mall/aftersales/refundList' // 退款单管理
import Aftersalesrefundlist_detail from '@/view/mall/aftersales/refundDetail' // 退款单详情
import Fapiaolist from '@/view/mall/fapiao/list' // 发票列表
import Fapiao_set from '@/view/mall/fapiao/set' // 发票设置
import Fapiao_set_detail from '@/view/mall/fapiao/add' // 添加发票
import ModelData from '@/view/member/modelData/index' // 机型数据
import Member from '@/view/member/members/list' // 会员管理

import Wechatuseroff from '@/view/member/members/wechatuseroff' // 会员管理
import MemberApplyDelete from '@/view/member/members/applyDelete' // 会员管理

import Manageinfomation from '@/view/member/registersetting/index' // 会员注册信息
import ManageinfomationTwo from '@/view/member/registersetting/index_two' // 会员注册信息
import Bytemanageinfomation from '@/view/member/registersetting/byte_disit' // 会员注册信息
import Manageinfomationdisti from '@/view/member/registersetting/index_disti' // DISTI(payment小程序)会员注册信息
import Managecard from '@/view/member/membercard/index' // 会员卡与等级设置
import Managecardorder from '@/view/member/membercard/list' // 会员卡购买记录
import Managecustomer from '@/view/member/members/uploade' // 会员信息导入 **
import Trustlogin from '@/view/member/trustlogin/list' // 信任登录列表
import Managetag from '@/view/member/members/tags' // 会员标签
import Analysisrights from '@/view/member/members/rightsList' // 会员权益管理
import AnalysisrightsContent from '@/view/member/members/analysisrightsContent' // 会员权益管理
import Membermarketing from '@/view/member/cardticket/coupon' // 优惠券管理
import Couponcancel from '@/view/member/cardticket/couponcancel' // 优惠券管理
import Redpacketcover from '@/view/mall/marketing/redpacketcover'// 红包
import Consumecard from '@/view/member/coupon/consumecard' // 兑换券核销
import Couponthirdparty from '@/view/member/couponthirdparty/list' // 平台优惠券发放规则管理
import Couponthirdparty_editor from '@/view/member/couponthirdparty/add' // 平台优惠券发放规则管理
import Couponthirdpartycode from '@/view/member/couponthirdpartycode/list' // 平台导流万能码
import Activity from '@/view/member/lotteryactivity/list' // 抽奖列表
import Activity_editor from '@/view/member/lotteryactivity/edit' // 新增/编辑抽奖页
import Bundlediscount from '@/view/member/bundlediscount/list' // 优惠搭售列表
import Bundlediscount_editor from '@/view/member/bundlediscount/add' // 优惠搭售新增/编辑

import Couponsend from '@/view/member/members/list' // 群发优惠券，与会员管理同一组件
import Smssend from '@/view/member/members/list' // 群发短信，与会员管理同一组件
import Coupongive from '@/view/member/coupon/giveLog' // 优惠券后台发放记录

import Coupontpbind from '@/view/mall/ec/couponBind' // 本地优惠券和第三发优惠券绑定关系
import Ecsetting from '@/view/mall/ec/setting' // Ec设置
import Ecsettingcity from '@/view/mall/ec/ecsettingcity' // ec参与城市
import Goodslist from '@/view/mall/ec/goodsList' // ec参与城市
import Maintain from '@/view/mall/ec/maintain' // 维护相关

import Coupongrantset from '@/view/member/cardticket/couponGrantSet'  // 优惠券发放管理设置
import Marketingindex from '@/view/member/promotions/index' // 注册营销
import Marketingbargain from '@/view/member/bargain/index' // 微信助力
import Marketingactivity from '@/view/member/activity/index' // 自动化营销
import Groupsindex from '@/view/member/groups/index' // 拼团活动管理
import Popularizesetting from '@/view/popularize/setting' // 分销设置
import Promotersetting from '@/view/popularize/promoterSetting' // 推广员等级设置
import Popularizelist from '@/view/popularize/list' // 推广员列表
import Popularizedata from '@/view/popularize/data' // 推广员列表
import Popularizewithdraw from '@/view/popularize/withdraw' // 分销提现
import Popularizegoods from '@/view/popularize/goods' // 推广商品
import PopularizegoodsAudit from '@/view/popularize/goodsAudit' // 推广商品审核
import Taskbrokerage from '@/view/popularize/taskBrokerage' // 任务佣金明细
import Taskbrokeragecount from '@/view/popularize/taskBrokerageCount' // 任务佣金统计
import Marketingcommunity from '@/view/member/promotions/community/index' // 社区管理
import Marketingcommunityactivity from '@/view/member/promotions/community/activity/index' // 活动管理
import Marketingdeliveryorder from '@/view/member/promotions/community/deliveryorder' // 配送单
import Communitysetting from '@/view/member/promotions/community/setting' // 社区活动设置
import Communitywithdraw from '@/view/member/promotions/community/withdraw' // 积分提现
import Communityactivityorder from '@/view/member/promotions/community/activity/orderList' // 社区活动订单
import Marketingseckill from '@/view/member/promotions/seckill/list' // 秒杀活动管理
import Limitedtimesale from '@/view/member/promotions/limitedtimesale/list' // 限时特惠活动管理
import Oldbynew from '@/view/member/promotions/oldbynew/list' // 以旧换新活动管理
import Goodslimit from '@/view/member/promotions/goodslimit/list' // 商品限购管理
import Wxapp from '@/view/wxapp/index' // 小程序列表
import Templateedit from '@/view/wxapp/management' // 小程序模板编辑
import Distributortemplateedit from '@/view/wxapp/distributorManagement' // 店铺模板编辑
import Batemplateedit from '@/view/wxapp/bamanagement' // ba小程序模板编辑
import Pctemplate from '@/view/pc/homePage/default' // pc首页模板编辑
import Pcviptemplate from '@/view/pc/vipPage/default' // pc vip页面模板编辑
import Pcfootertext from '@/view/pc/footerPage/default' // pc 底部文案
import Tdkglobalset from '@/view/pc/tdk/globalset' // Tdk全局设置
import Tdkgivenset from '@/view/pc/tdk/givenset' // Tdk全局设置
import Pccustompage from '@/view/pc/custompage' // pc 自定义页面
import Editcategory from '@/view/wxapp/category' // 自定义小程序分类
import Custompage from '@/view/wxapp/custompage' // 自定义小程序页面
import CustomTem from '@/view/wxapp/customTem' // 自定义小程序页面
//自定义消息
import CustomMessage from '@/view/wxapp/customMessage/index' // 自定义小程序页面-自定义消息列表
import CustomMessage_operation from '@/view/wxapp/customMessage/operation' // 自定义小程序页面-自定义消息操作
import Functionpage from '@/view/wxapp/functionpage' // 自定义小程序页面
import Navmarketing from '@/view/wxapp/navmarketing' // 自定义小程序页面
import Tabbarsetting from '@/view/wxapp/tabbarSetting' // 自定义小程序页面
import Custompageagreement from '@/view/wxapp/custompageagreement' // 自定义小程序页面-协议
import Custompageec from '@/view/wxapp/custompageec' // 自定义小程序页面-Ec
import Questionnaire from '@/view/wxapp/questionnaire/list' // 小程序问卷模板列表
import Questionnaire_editor from '@/view/wxapp/questionnaire/edit' // 添加编辑问卷模板
import Questionnaire_submit from '@/view/wxapp/questionnaire/submitList' // 添加编辑问卷模板
import Floorguide from '@/view/wxapp/floor_guide' // 自定义楼层引导页面
import Colorstyle from '@/view/wxapp/color_style' // 自定义楼层引导页面
import Membercentersetting from '@/view/wxapp/member_center_setting' // 会员中心设置
import Editdashboard from '@/view/wxapp/statistics/dataanalysis/index' // 小程序数据分析
import Editsourcemanagement from '@/view/wxapp/statistics/sourcemanagement/index' // 小程序千人千码
import Editauthorize from '@/view/wxapp/authorize/index' // 小程序授权
import Pop from '@/view/wxapp/pop/index' // 小程序弹窗
import Marketingsfulldiscount from '@/view/member/promotions/fulldiscount/list' // 满折活动列表
import Marketingsfullminus from '@/view/member/promotions/fullminus/list' // 满减活动列表
import Marketingsfullgift from '@/view/member/promotions/fullgift/list' // 满赠活动列表
import Marketingspluspricebuy from '@/view/member/promotions/pluspricebuy/list' // 加价购活动列表
import Marketingpackage from '@/view/member/promotions/package/list' // 组合促销促销
import Wheel from '@/view/member/promotions/wheel/index' // 大转盘抽奖
import Ordersetting from '@/view/mall/trade/ordersetting' // 订单时效配置
import Createorderlimitsetting from '@/view/mall/trade/createOrderLimit' // 订单时效配置
import Goodsattributes from '@/view/mall/goods/attributes/attributes' // 商品规格列表
import Goodsparams from '@/view/mall/goods/attributes/params' // 商品参数列表
import Storeshopitemanagement from '@/view/mall/storeshop/itemlist'// 店铺商品列表
import Merchantstaff from '@/view/base/account/storestaff' // 商城（店铺）员工管理
import Merchantstaffroles from '@/view/base/account/storeroles' // 商城（店铺）员工角色管理
import Platformstaff from '@/view/base/account/normalstaff' // 平台员工管理
import Platformstaffroles from '@/view/base/account/normalroles' // 平台员工角色管理
import Staffshopgroup from '@/view/base/account/staffshopgroup' // 平台员工店铺分组管理
import Planting from '@/view/base/planting/index' // 种草列表
import Articlecolumns from '@/view/base/article/columns' // 文章栏目
import Articlegeneralcolumns from '@/view/base/article/generalcolumns' // 文章栏目
import Goodspresale from '@/view/member/promotions/goodspresale/list' // 新品发售

import Examinetemplateedit from '@/view/examine/management' // 小程序模板审核
import Examinedistributortemplateedit from '@/view/examine/distributorManagement' // 店铺模板审核
import Examinecustompage from '@/view/examine/custompage' // 自定义小程序页面审核
import Examinecustomtem from '@/view/examine/customTem' // 自定义模板审核
import Examinefunctionpage from '@/view/examine/functionpage' // 功能页审核

import Operatorlogs from '@/view/base/setting/companys/logs' // 商家操作日志列表
import Operatormongologs from '@/view/base/setting/companys/mongologs' // 商家操作日志列表

import Itemtags from '@/view/mall/goods/tags' // 商品标签
import Goodsaudit from '@/view/mall/goods/audit/list' // 商品标签
import Goodsaudit_editor from '@/view/mall/goods/audit/add' // 商品标签

import Selfdeliveryaddress from '@/view/base/address/setting' // 设置固定自提地址
import Distributoraftersalesaddress from '@/view/mall/marketing/distributorAftersalesAddress' // 店铺售后地址

import Ordercartsetting from '@/view/mall/trade/cartadsetting' // 购物车广告营销语

import Recommendlike from '@/view/member/promotions/recommendlike' // 设置固定自提地址

import Omsqueuelog from '@/view/mall/trade/omsqueuelog' // 查看oms通信日志

import Aftersale from '@/view/mall/trade/aftersale' // 售后商品设置


import Security from '@/view/mall/setting/security' // 安全设置
import Cart from '@/view/mall/setting/cart' // 购物袋设置

import Sftcsetting from '@/view/base/setting/sftcsetting' // 顺丰同城设置

import Omsskulog from '@/view/mall/trade/omsskulog' // 查看oms通信日志

import Refunderrorlogs from '@/view/mall/trade/refunderrorlogs' // 退款错误日志

import Rate from '@/view/mall/trade/rate/list' // 订单评价

import Certificate from '@/view/base/setting/certificate/index' // 授权

import DataAnalysis from '@/view/base/setting/dataAnalysis' // 数据分析配置

// import Basic_setting from '@/view/mall/marketing/basicSetting' // 基础配置
// import Onecode from '@/view/brand/codegoods/thingslist' // 商品码管理
// import Things from '@/view/brand/codegoods/thingsedit' // 新增编辑物品
// import Batchs from '@/view/brand/codegoods/batchsedit' // 批次管理&&新增编辑批次
// import Thingsbatchs_detail from '@/view/brand/codegoods/thingsbatchsdetail' //物品批次详情
// import Withdraw from '@/view/mall/marketing/withdraw' // 提现申请
// import Surveillance from '@/view/mall/marketing/surveillance' // 店铺二维码
// import Distributorlog from '@/view/mall/marketing/logByDistributor' // 商家店铺记录
// import List from '@/view/mall/reservation/resourcesetting/list' // 资源位管理
// import registerRromotionsAdd from '@/view/member/promotions/distributorEdit'

import Selformelementsetting from '@/view/member/selfservice/formsettinglist'   // 自助表单元素配置
import Selformtempsetting from '@/view/member/selfservice/formtemplatelist'     // 自助表单模板配置
// import Physicalmeasurement_detail from '@/view/member/selfservice/userdaliyrecord'   // 用户体测详情报表
import Physicalmeasurement from '@/view/member/selfservice/templatepreview'     // 用户体测列表和配置

/** *********member tag category begin*************/
import Membertagcategory from '@/view/member/members/tagcategory.vue'     // 用户体测列表和配置
/** *********member tag category end*************/
/** *********新的门店 begin*************/
import Distributorshops from '@/view/mall/marketing/shops/lists' // 店铺管理
import Distributorshops_editor from '@/view/mall/marketing/shops/edit' // 店铺管理
/** *********新的门店 end*************/

import Registrationactivity from '@/view/member/selfservice/registrationactivity'     // 报名活动管理
import Registrationactivity_editor from '@/view/member/selfservice/registrationactivityadd'     // 报名活动管理
import Registrationrecord from '@/view/member/selfservice/registrationrecord'     // 报名记录管理
import Registrationrecord_detail from '@/view/member/selfservice/registrationrecordetail'     // 报名记录管理
import Registrationrecord_editor from '@/view/member/selfservice/regRecordUpload'    // 报名审核结果上传日志

import Specificcrowddiscount from '@/view/member/promotions/specificcrowd/specificcrowddiscount'  // 定向促销
import Specificcrowddiscount_detail from '@/view/member/promotions/specificcrowd/userdiscoutlog'  // 定向促销促销详情

import Weburlsetting from '@/view/base/setting/weburlsetting'  // 外部链接配置
import Tooluat from '@/view/base/setting/tooluat'  // 外部链接配置

// 隐藏路由
import Home from '@/view/home' // 主框架
import Layout from '@/view/layout' // 主结构
import Authloading from '@/view/authloading' // 绑定微信
import Shopwxshop_editor from '@/view/base/shop/editshop' // 添加修改门店
import Basearticle_editor from '@/view/base/article/editor' // 添加编辑文章
import Brandmaterial_editor from '@/view/brand/wechat/artical/new_artical' // 新增编辑图文消息
import Servicegoods_editor from '@/view/mall/goods/service/items/add' // 服务类商品添加编辑
import Goodsphysical_editor from '@/view/mall/goods/physical/add' // 实体类商品添加编辑
// import Goodsappend_editor from '@/view/mall/goods/append/add' // 实体类商品附加信息添加编辑
import Tradenormalorders_detail from '@/view/mall/trade/order/detail' // 实体订单详情
import QueryOrder_detail from '@/view/mall/trade/order/detail' // 查看订单详情
import QueryOrder_process from '@/view/mall/trade/order/process' // 查看订单日志
import QueryOrder_after_refund from '@/view/mall/aftersales/refundDetail' // 查看退款详情
import QueryOrder_after_sales from '@/view/mall/aftersales/detail' // 查看售后详情
import Tradenormalorders_process from '@/view/mall/trade/order/process' // 实体订单详情
import Tradenormalshoporders_detail from '@/view/mall/trade/order/detail' // 自营订单详情
import Tradedrugorders_detail from '@/view/mall/trade/order/detail' // 药品需求单订单详情
import Marketingdistribution_orders_detail from '@/view/mall/trade/order/detail' // 店铺订单详情
import Tradeservice_detail from '@/view/mall/trade/order/detail' // 服务订单详情
import Shippingtemplates_editor from '@/view/mall/trade/shipping/add' // 添加编辑运费模板
import Mallreservation_editor from '@/view/mall/reservation/resourcesetting/add' // 资源位添加
import Coupongive_detail from '@/view/member/coupon/giveErrorLog' // 优惠券后台发放记录详情
import Aftersaleslist_detail from '@/view/mall/aftersales/detail' // 售后单详情
import Fapiaolist_detail from '@/view/mall/fapiao/detail' // 发票编辑
import Member_detail from '@/view/member/members/detail' // 会员详情页
import Membermarketing_detail from '@/view/member/cardticket/detail' // 优惠券领取记录
import Membermarketing_editor from '@/view/member/cardticket/edit' // 添加编辑优惠券
import Membermarketing_detail1 from '@/view/member/cardticket/list' // 添加编辑优惠券
import Marketingbargain_editor from '@/view/member/bargain/add' // 添加编辑微信助力
import Marketingactivity_templ from '@/view/member/activity/templ' // 自动化营销选择模版
import Marketingactivity_editor from '@/view/member/activity/add' // 新增编辑自动化营销
import Groupsindex_editor from '@/view/member/groups/add' // 新增编辑拼团活动
import Groupsindex_team from '@/view/member/groups/team' // 拼团活动数据
import Groupsindex_detail from '@/view/member/groups/teamDetail' // 拼团活动团详情
import Popularizelist_child from '@/view/popularize/children' // 推广员直属下级
import Popularizelist_detail from '@/view/popularize/brokerageLog' // 推广员佣金详情
import Marketingcommunity_detail from '@/view/member/promotions/community/points' // 社区积分
import Marketingcommunity_editor from '@/view/member/promotions/community/add' // 新增编辑社区
import Marketingcommunityactivity_editor from '@/view/member/promotions/community/activity/add' // 新增编辑活动
import Communityactivityorder_detail from '@/view/mall/trade/order/detail' // 社区活动订单详情
import Marketingseckill_editor from '@/view/member/promotions/seckill/add' // 新增编辑秒杀活动
import Limitedtimesale_editor from '@/view/member/promotions/limitedtimesale/add' // 新增编辑限时特惠活动
import Oldbynew_editor from '@/view/member/promotions/oldbynew/add' // 编辑新增以旧换新
import Goodslimit_editor from '@/view/member/promotions/goodslimit/add' // 新增商品限购
import Editsourcemanagement_detail from '@/view/wxapp/statistics/sourcemanagement/monito_detail'
import Marketingsfulldiscount_editor from '@/view/member/promotions/fulldiscount/add'  // 添加编辑满折活动
import Marketingsfullminus_editor from '@/view/member/promotions/fullminus/add'      // 添加编辑满减活动
import Marketingsfullgift_editor from '@/view/member/promotions/fullgift/add' // 添加编辑满赠活动
import Marketingpackage_editor from '@/view/member/promotions/package/add' // 组合促销促销
import Wxapp_templ from '@/view/wxapp/template' // 开通小程序模板
import Templatelist from '@/view/pc/pctemplate' // 开通PC模板
import Marketingdeliveryorder_detail from '@/view/member/promotions/community/deliverydetail' // 配送单详情
import Marketingspluspricebuy_editor from '@/view/member/promotions/pluspricebuy/add' // 加价购活动编辑
import Shopexerp from '@/view/base/setting/shopexerp' // 商派erp直连配置
import Planting_editor from '@/view/base/planting/editor' // 添加编辑种草
import Admininfo from '@/view/base/shop/admininfo' // 管理员信息
import Yilianyun from '@/view/base/setting/yilianyun/index' // 管理员信息
import Tradesetting from '@/view/base/setting/tradesetting/index' // 管理员信息
import Meiqia from '@/view/base/setting/im/list' // 管理员信息
import Echat from '@/view/base/setting/im/echat' // 一洽配置信息
import Fapiaosetting from '@/view/base/setting/fapiao/list' // 发票配置
import AddressBook from '@/view/base/setting/addressBook/index' // 企业微信通讯录
import Pointrule from '@/view/member/point/index.vue' // 管理员信息
import Pointoverview from '@/view/member/point/overview.vue' // 积分总览
import Goodspresale_editor from '@/view/member/promotions/goodspresale/add' // 新增新品发售

import Selformelementsetting_editor from '@/view/member/selfservice/formsettingadd'   // 自助表单元素配置
import Selformtempsetting_editor from '@/view/member/selfservice/formtemplateadd'     // 自助表单模板配置
import Physicalmeasurement_detail from '@/view/member/selfservice/userdaliyrecord'   // 用户体测详情报表
import Couponsend_detail from '@/view/member/members/detail' // 群发优惠券会员详情页
import Shoppingguidenotification_editor from '@/view/guide/shoppingguidenotification_editor' // 导购员管理 编辑
import ShoppingGuideTask_editor from '@/view/guide/shoppingGuideTask_editor' // 新增编辑导购任务
import Shopactivities_editor from '@/view/guide/shopActivities_editor' // 导购知识库 编辑

// 店铺端页面
import Shopstorelist from '@/view/shop/storelist' // 门店列表
import Shopstorelist_editor from '@/view/shop/editstore' // 门店编辑
import Companygoods from '@/view/shop/goods/companygoods' // 门店编辑
import Memberstatistics from '@/view/mall/datacube/companydata/member' // 商城统计信息-会员
import Orderstatistics from '@/view/mall/datacube/companydata/order' // 商城统计信息-订单
import ResourceStatistics from '@/view/mall/datacube/resource' // 商城统计信息-推广分享码统计
import Distributordata from '@/view/mall/datacube/distributordata' // 商城统计信息-订单
import Questionnairedata from '@/view/mall/datacube/questionnairedata' // 商城统计信息-问卷调查
import Hotkeywords from '@/view/mall/goods/hotkeywords' // 热门关键词
import Goodsstatistics from '@/view/mall/datacube/goodsdata' // 商品统计信息
import Posdata from '@/view/mall/datacube/posdata' // pos统计信息
import Hqdata from '@/view/mall/datacube/hqdata' // hq统计信息
import Tradetotal from '@/view/mall/datacube/tradetotal' // pos统计信息

import Logistics from '@/view/mall/trade/logistics'
import Noticemessage from '@/view/wxapp/messages' // 查看oms通信日志

import Kdniao from '@/view/base/setting/kdniao' // 快递配置
import Sfbsp from '@/view/base/setting/sfbsp' // 快递配置

import Workwechat from '@/view/base/setting/workwechat/workwechat'
import DistributionConfig from '@/view/base/setting/distribution/index'
import Wxaindex from '@/view/base/wxa/index'
import Profitlist from '@/view/mall/profit/list' // 交易单管理
import Publicsetting from '@/view/base/setting/publicsetting'     // 公共配置页面
import Sharesetting from '@/view/base/setting/sharesetting'     // 分享页面
import Liveroomlist from '@/view/mall/liveroom/list' // 直播间列表
import Liveroomlist_editor from '@/view/mall/liveroom/replay' // 直播间视频回放列表

import Normalordersupload from '@/view/mall/trade/normalorders/ordersupload' // 实体订单导入列表

// 跨境
import Origincountry from '@/view/crossborder/origincountry/list' // 产地国
import Crossbordersetinfo from '@/view/crossborder/crossborderset/info' // 跨境设置
import Godsphysicalkj from '@/view/mall/goods/physical/list' // 实体类商品
import Godsphysicalkj_editor from '@/view/mall/goods/physical/add' // 实体类商品添加编辑
import Taxstrategy from '@/view/crossborder/taxstrategy/list'// 跨境税率策略列表
import Taxstrategy_editor from '@/view/crossborder/taxstrategy/edit'// 跨境税率策略详情

import Financialaftersaleslist from '@/view/mall/aftersales/financial/list' // 财务-售后列表
import Salesreportlist from '@/view/mall/trade/financial/list' // 财务-销售报表

import Whitelistlist from '@/view/member/whitelist/list' // 会员-白名单
import Whitelistuploade from '@/view/member/whitelist/uploadeindex' // 会员-白名单导入

import Closelistlist from '@/view/member/closelist/list' // 会员-关闭会员申请列表

import Shopmenu from '@/view/menus/shopmenu' // 菜单管理

import Regionauth from '@/view/regionauth/list' // 地区权限

// 积分商城相关
import Pointsmallmaincategory from '@/view/mall/pointsmallgoods/maincategory' // 商品主类目
import Pointsmallcategory from '@/view/mall/pointsmallgoods/category' // 商品分类
import Pointsmallphysical from '@/view/mall/pointsmallgoods/physical/list' // 实体类商品
import Pointsmallphysical_editor from '@/view/mall/pointsmallgoods/physical/add' // 实体类商品添加编辑
import Pointsmallattributes from '@/view/mall/pointsmallgoods/attributes/attributes' // 商品规格列表
import Pointsmallparams from '@/view/mall/pointsmallgoods/attributes/params' // 商品参数列表
import Pointsmallbrand from '@/view/mall/pointsmallgoods/attributes/brand' // 商品品牌管理
import Pointsmallphysicalupload from '@/view/mall/pointsmallgoods/physical/normalGoodsUpload' // 实体商品导入
import Pointsmallphysicalstoreupload from '@/view/mall/pointsmallgoods/physical/normalGoodsStoreUpload' // 实体商品库存导入
// 设置
import Pointsmallsetting from '@/view/mall/pointsmallsetting/index' // 实体商品库存导入

import Pointsmallenormalorders from '@/view/mall/pointsmalltrade/normalorders/list' // 实物订单管理
import Pointsmallenormalorders_detail from '@/view/mall/pointsmalltrade/order/detail' // 实体订单详情
import Pointsmallrate from '@/view/mall/pointsmalltrade/rate/list' // 积分订单评价

import Openscreenad from '@/view/base/screen/openScreenAD' // 开屏广告

import Hotcity from '@/view/base/address/hotcity' // 热门城市设置
import Announcement from '@/view/base/announcement'
import Iupagreement from '@/view/base/iupagreement'


import Qrcodesetting from '@/view/base/qrcodesetting/index'

// import AfterServiceReason from
import Aftersalesreason from '@/view/mall/aftersales/reason' // 售后原因
// 大屏
import Shopscreen_category from '@/view/shopscreen/category' // 自定义大屏分类
import BigScreenAd from '@/view/shopscreen/bigScreenAd' // 大屏广告
import HomeCarousel from '@/view/shopscreen/homeCarousel' // 轮播广告
import SearchKeyWords from '@/view/shopscreen/searchKeyWords' // 关键词设置
// 分账
import Setting from '@/view/mall/splitbill/setting' // 分账设置
import StoreFenSetting from '@/view/mall/splitbill/storesetting' // 分账设置

import StoreInformation from '@/view/mall/splitbill/list' // 分账进件列表

import StoreInformation_editor from '@/view/mall/splitbill/edit' // 店铺认证信息录入
import StoreAccountingStatistics from '@/view/mall/splitbill/storeAccount' // 店铺分账统计
import PlatformAccountingStatistics from '@/view/mall/splitbill/platformAccount' // 平台分账统计

import Pointupvaluation from '@/view/member/promotions/pointupvaluation/list' // 积分升值活动管理
import Pointupvaluation_editor from '@/view/member/promotions/pointupvaluation/add' // 新增编辑积分升值活动

import Extrapoint from '@/view/member/promotions/extrapoint/list' // 额外积分活动列表
import Extrapoint_templ from '@/view/member/promotions/extrapoint/templ' // 额外积分活动选择模版
import Extrapoint_editor from '@/view/member/promotions/extrapoint/editor' // 额外积分编辑页面
// 经销商
import Dealerlist from '@/view/mall/marketing/dealer/dealerList' // 经销商列表
import Dealerlist_editor from '@/view/mall/marketing/dealer/editor' // 经销商编辑
import Platformdealer from '@/view/base/account/platformdealer' // 经销商列表

import DistiAccount from '@/view/base/account/distiAccount' // 总代/运营管理员列表

// 优惠活动
import Preferentialactivities from '@/view/member/promotions/preferentialactivities/index' // 优惠活动列表
import Preferentialactivities_editor from '@/view/member/promotions/preferentialactivities/editor' // 优惠活动编辑页面

// AB测试活动
import PreferentialactivitiesTemp from '@/view/member/promotions/preferentialactivities/indexTemp' // 优惠活动列表
import PreferentialactivitiesTemp_editor from '@/view/member/promotions/preferentialactivities/editorTemp' // 优惠活动编辑页面

// 广告管理
import PreferentialactivitiesOrderAD from '@/view/member/promotions/preferentialactivities/indexOrderAD' // 优惠活动列表
import PreferentialactivitiesOrderAD_editor from '@/view/member/promotions/preferentialactivities/indexOrderAD_editor' // 优惠活动列表

import Itemssyncdistributorlog from '@/view/mall/goods/syncDistributorLog' // 商品同步到店铺日志
import Changepassword from '@/view/base/setting/changepassword' // 修改密码
import Seriesgoods from '@/view/mall/goods/seriesgoods' // 系列商品
import Seriesgoods_editor from '@/view/mall/goods/seriesgoods_editor' // 系列商品编辑

// 模版审核
import Examinetemplate from '@/view/wxapp/template/mall/examineTemplate'

import AdapayMember from '@/view/mall/adapaymembers/list'
import AdapayMember_editor from '@/view/mall/adapaymembers/edit'
import AdapayMember_contactShop from '@/view/mall/adapaymembers/contactShop'
import Adapaycapital from '@/view/mall/adapaymembers/capital' // 实物订单管理

import Editauthorize_privacy from '@/view/wxapp/authorize/privacy' // 小程序用户隐私保护指引

import Distributorshowrule from '@/view/mall/marketing/distributorShowRule'
import Offlineactivity from '@/view/member/promotions/offlineactivity/list' // 线下活动
import Offlineactivity_editor from '@/view/member/promotions/offlineactivity/add' // 线下活动
import Offlineactivity_detail from '@/view/member/promotions/offlineactivity/detail' // 查看线下活动
import Distributorofflineactivity from '@/view/member/promotions/offlineactivity/distributorActivity' // 门店线下活动管理
import Offlineactivitysignuplog from '@/view/member/promotions/offlineactivity/signUpLog' // 门店线下活动报名记录
import Offlineactivitysignuplog_detail from '@/view/member/promotions/offlineactivity/signUpLogDetail' // 门店线下活动报名记录详情
import Activityprivacyagreement from '@/view/member/promotions/offlineactivity/privacyAgreementList' // 隐私协议列表
import Activityprivacyagreement_editor from '@/view/member/promotions/offlineactivity/privacyAgreementAdd' // 隐私协议列表
import Activityprivacyagreement_detail from '@/view/member/promotions/offlineactivity/privacyAgreementDetail' // 查看隐私协议
import Offlinejoinactivity from '@/view/member/promotions/offlineactivity/offlineJoinLog' // 线下活动线下体验
import Relationsalelog from '@/view/member/promotions/offlineactivity/relationSaleLog' // 线下活动搭售记录

import H5urllink from '@/view/wxapp/url_scheme' // url link
import Usersetting from '@/view/member/members/usersetting' // 订单时效配置

import Kefuops from '@/view/base/setting/kefuops' // kefuops
import DataSync from '@/view/base/setting/dataSync' // 神策数据同步

// import partShow from '@/view/base/setting/kefuops' // 营销展示
import Showpart from '@/view/member/promotions/showpart/list'
import Showpart_editor from '@/view/member/promotions/showpart/add'


import Material from '@/view/member/promotions/aftersaleMaterial/list'
import Aftersalematerial from '@/view/member/promotions/aftersaleMaterial/aftersalevideo'

import Aftersalematerial_editor from '@/view/member/promotions/aftersaleMaterial/aftersalevideo_edit'

// 售后维修相关
import Repairservicemanage from '@/view/mall/repair/repairServiceManage' //
import Sessions from '@/view/mall/repair/workandrestManage'
import Order from '@/view/mall/repair/repairManage'
import SleepChangeLog from '@/view/mall/repair/sleep-change-log' // 作息变更日志
import StoreRestManange from '@/view/mall/repair/store-rest-manange' // 门店作息管理
import AskHelp from '@/view/mall/repair/askHelp' // 找帮助
import WhiteList from '@/view/mall/repair/whiteList' // 售后白名单
// 作息管理   repairsessions
// 维修单管理   repairorder
import Gift from '@/view/mall/gift/giftManage' //Gift赠品管理
import Orderrecommenditem from '@/view/mall/goods/recommend' //推荐商品管理
import Materialcreate from '@/view/material/create-material' // 物料分发管理
import Materialrecord from '@/view/material/record-material' // 物料生成记录
import Materialstatistics from '@/view/material/statistics-material' // 物料明细
import PiiLog from '@/view/pii/pii-log' // pii日志
import PiiSetting from '@/view/pii/pii-setting' // pii设置

import Wework from "@/view/base/setting/wework"

//自闭环
import Selfhandlesetting from '@/view/tool/selfhandle-setting' // 自闭环订单列表
import SelfLog from '@/view/tool/selfhandle-log' // 自闭环订单处理日志日志
import Selfhandleorders from '@/view/tool/selfhandle-list' // 自闭环订单列表

import FastBtnSetting from '@/view/mall/trade/fastBtnSetting/index' // 快捷按钮设置
import RiskOrder from '@/view/mall/trade/riskOrder' // 风险订单处理
import Instructionsimg from '@/view/member/instructions/img-editor' // 测试文字说明图片配置

/** 安全 */
import AddressBlack from '@/view/safety/address-black' // 地址黑名单管理
import AddressBlackSetting from '@/view/safety/address-black-setting' // 地址黑名单设置


/** 会员 */
import OrderGift from '@/view/mall/marketing/orderGift' // 订单兑礼
import OrderGift_operation from '@/view/mall/marketing/orderGift/operation' // 订单兑礼-操作
import WarrantyCertificate from '@/view/member/warrantyCertificate' // 额外保修/保修凭证
import WarrantyOperation from '@/view/member/warrantyOperation' // 额外保修/保修操作
import DevExWarranty from '@/view/tool/devExWarranty' // 运维工具/额外保修
import OrderCollectionStatic from '@/view/member/orderCollectionStatic' // 会员营销/订单领取统计
import GoodsPricecheck from '@/view/mall/goods/priceReview' // 商品价格复核

export default {
  Redpacketcover,
  Editauthorize_privacy,
  Preferentialactivities_editor,
  Preferentialactivities,
  PreferentialactivitiesTemp_editor,
  PreferentialactivitiesTemp,
  PreferentialactivitiesOrderAD,
  Dealerlist,
  Dealerlist_editor,
  Platformdealer,
  Home,
  Wxaindex,
  Layout,
  Couponcancel,
  Consumecard,
  Couponthirdparty,
  Couponthirdparty_editor,
  Couponthirdpartycode,
  Coupontpbind,
  Ecsetting,
  Goodslist,
  Maintain,
  Ecsettingcity,
  Authloading,
  Dashboard,
  Shopwxshop_editor,
  Shopwxshop,
  Wxshopsetting,
  Comments,
  Assetpayment,
  Assetaccountactivation,
  Datamessage,
  Stepcheck,
  Shotrtlink,
  Staffleader,
  Accountmanagement,
  Accountroles,
  Basearticle,
  Basearticle_editor,
  Basecurrency,
  Baseexport,
  Brand,
  Brandcustomer,
  Brandmenu,
  Brandreply,
  Brandfans,
  Brandmaterial,
  Brandmaterial_editor,
  Goodscategory,
  Goodsmaincategory,
  Servicegoods,
  Servicegoods_editor,
  Goodsphysical,
  Goodsphysical_editor,
  Goodsappend,
  // Goodsappend_editor,
  Goodsaudit,
  Goodsaudit_editor,
  Physicalupload,
  Physicalstoreupload,
  GoodsUnmount,
  // GoodsPricecheck,
  GoodsStage,
  GoodsDraft,
  Physicalgifts,
  Buyxgivey,
  Buyxgivey_operation,
  Physicalrecommendupload,
  Physicalprofitupload,
  Goodsbrand,
  Arrivalnotice,
  Servicepayment,
  Tradeservice,
  Tradenormalorders,
  QueryOrder,
  QueryOrder_detail,
  QueryOrder_process,
  QueryOrder_after_refund,
  QueryOrder_after_sales,
  Tradenormalshoporders,
  Tradedrugorders,
  Tradepayment,
  Tradeverification,
  Tradenormalorders_detail,
  Tradenormalorders_process,
  Tradenormalshoporders_detail,
  Tradedrugorders_detail,
  Tradeservice_detail,
  Shippingtemplates,
  Shippingtemplates_editor,
  Marketingdistributor,
  Marketingdistributor_editor,
  Marketingdistributor_template,
  Temporarydeliveryplace,
  Temporarydeliveryplace_editor,
  Deliverysetting,
  Marketingdistribution_orders,
  Marketingdistribution_orders_detail,
  Distributortags,
  Distributorrel_items,
  Distributoraftersalesaddress,
  Distributorinvoice,
  Marketinglog,
  ShopDecoration,
  Marketingsalesman,
  Marketingsalesmanrole,
  ShoppingGuideTask,
  ShoppingGuideTask_statistics,
  Shoppingguidenotification,
  Shopnoticetempl,
  Salemancustomercomplaints,
  SalemanLoginHistory,
  Reservationsetting,
  Mallreservation,
  Mallreservation_editor,
  Reservationorder,
  Mallrecharge,
  Aftersaleslist,
  Aftersaleslist_detail,
  Aftersalesrefundlist,
  Aftersalesrefundlist_detail,
  Fapiaolist,
  Fapiaolist_detail,
  Fapiao_set,
  Fapiao_set_detail,
  ModelData,
  Member,
  Member_detail,
  Wechatuseroff,
  MemberApplyDelete,
  Manageinfomation,
  Bytemanageinfomation,
  Manageinfomationdisti,
  Managecard,
  Managecardorder,
  Managecustomer,
  Trustlogin,
  Managetag,
  Analysisrights,
  AnalysisrightsContent,
  Membermarketing,
  Couponsend,
  Couponsend_detail,
  Smssend,
  Coupongive,
  Coupongive_detail,
  Coupongrantset,
  Membermarketing_detail,
  Marketingindex,
  // registerRromotionsAdd,
  Membermarketing_editor,
  Membermarketing_detail1,
  Marketingbargain_editor,
  Marketingbargain,
  Marketingactivity,
  Marketingactivity_templ,
  Marketingactivity_editor,
  Goodslimit,
  Goodslimit_editor,
  Goodspresale,
  Goodspresale_editor,
  Groupsindex,
  Groupsindex_editor,
  Groupsindex_detail,
  Groupsindex_team,
  Popularizesetting,
  Promotersetting,
  Popularizelist,
  Popularizedata,
  Popularizelist_child,
  Popularizewithdraw,
  Popularizegoods,
  PopularizegoodsAudit,
  Taskbrokerage,
  Taskbrokeragecount,
  Popularizelist_detail,
  Marketingcommunity,
  Marketingcommunity_detail,
  Marketingcommunity_editor,
  Marketingcommunityactivity,
  Marketingcommunityactivity_editor,
  Marketingdeliveryorder,
  Marketingdeliveryorder_detail,
  Communitysetting,
  Communitywithdraw,
  Communityactivityorder,
  Communityactivityorder_detail,
  Marketingseckill,
  Marketingseckill_editor,
  Limitedtimesale,
  Limitedtimesale_editor,
  Oldbynew,
  Oldbynew_editor,
  Wxapp,
  Wxapp_templ,
  Templatelist,
  Templateedit,
  Distributortemplateedit,
  Examinetemplateedit,
  Examinedistributortemplateedit,
  Examinecustompage,
  Examinecustomtem,
  Batemplateedit,
  Editcategory,
  Floorguide,
  Colorstyle,
  Membercentersetting,
  Custompage,
  Custompageagreement,
  Custompageec,
  Questionnaire,
  Questionnaire_editor,
  Questionnaire_submit,
  Editdashboard,
  Editsourcemanagement,
  Editsourcemanagement_detail,
  Editauthorize,
  Pop,
  Marketingsfulldiscount,
  Marketingsfulldiscount_editor,
  Marketingpackage,
  Marketingpackage_editor,
  Marketingsfullminus,
  Marketingsfullminus_editor,
  Marketingsfullgift,
  Marketingsfullgift_editor,
  Marketingspluspricebuy,
  Marketingspluspricebuy_editor,
  Wheel,
  Shopactivities,
  Shopactivities_editor,
  ShoppingGuideTask_editor,
  Shoppingguidenotification_editor,
  Ordersetting,
  Createorderlimitsetting,
  Pctemplate,
  Pcviptemplate,
  Pcfootertext,
  Tdkglobalset,
  Tdkgivenset,
  Pccustompage,
  Shopexerp,
  Goodsattributes,
  Goodsparams,
  Storeshopitemanagement,
  Merchantstaff,
  Merchantstaffroles,
  Platformstaff,
  Platformstaffroles,
  Staffshopgroup,
  Planting,
  Planting_editor,
  Articlecolumns,
  Articlegeneralcolumns,
  Itemtags,
  Selfdeliveryaddress,
  // Distributoraftersalesaddress,
  Admininfo,
  Recommendlike,
  Omsqueuelog,
  Aftersale,
  Security,
  Cart,
  Sftcsetting,
  Omsskulog,
  Yilianyun,
  Tradesetting,
  Meiqia,
  Echat,
  Fapiaosetting,
  DistributorCode,
  // Onecode,
  // Things,
  // Batchs,
  // Thingsbatchsdetail,
  // Basic_setting,
  // Withdraw,
  // Surveillance,
  // Log,
  // Distributorlog,
  // List,
  Selformelementsetting,
  Selformelementsetting_editor,
  Selformtempsetting,
  Selformtempsetting_editor,
  Shopstorelist,
  Shopstorelist_editor,
  Companygoods,
  Memberstatistics,
  Orderstatistics,
  ResourceStatistics,
  Hotkeywords,
  Goodsstatistics,
  Operatorlogs,
  Operatormongologs,

  Logistics,

  Refunderrorlogs,
  Distributordata,
  Questionnairedata,
  Pointrule,
  Pointoverview,
  Noticemessage,
  Kdniao,
  Sfbsp,
  Rate,
  Physicalmeasurement,
  Physicalmeasurement_detail,
  Membertagcategory,
  Distributorshops,
  Distributorshops_editor,
  Workwechat,
  DistributionConfig,
  SalesmanRelationship,
  Certificate,
  Registrationactivity,
  Registrationactivity_editor,
  Registrationrecord,
  Registrationrecord_detail,
  Registrationrecord_editor,
  Profitlist,
  Specificcrowddiscount,
  Specificcrowddiscount_detail,
  Weburlsetting,
  Tooluat,
  Publicsetting,
  Sharesetting,
  Liveroomlist,
  Liveroomlist_editor,
  Normalordersupload,
  AddressBook,
  // 跨境
  Origincountry,
  Crossbordersetinfo,
  Godsphysicalkj,
  Godsphysicalkj_editor,
  Taxstrategy,
  Taxstrategy_editor,
  DataAnalysis,
  Financialaftersaleslist,
  Salesreportlist,
  Whitelistlist,
  Whitelistuploade,

  Closelistlist,
  // 菜单管理
  Shopmenu,
  // 地区权限
  Regionauth,
  // 积分商城相关
  Pointsmallmaincategory,
  Pointsmallcategory,
  Pointsmallphysical,
  Pointsmallphysical_editor,
  Pointsmallattributes,
  Pointsmallparams,
  Pointsmallbrand,
  Pointsmallphysicalupload,
  Pointsmallphysicalstoreupload,
  Pointsmallsetting,
  Pointsmallenormalorders,
  Pointsmallenormalorders_detail,
  Pointsmallrate,
  // 开屏广告
  Openscreenad,
  Aftersalesreason,
  // 大屏
  Shopscreen_category,
  BigScreenAd,
  HomeCarousel,
  SearchKeyWords,
  Setting,
  StoreInformation,
  StoreInformation_editor,
  StoreFenSetting,
  StoreAccountingStatistics,
  PlatformAccountingStatistics,
  Pointupvaluation,
  Pointupvaluation_editor,
  Extrapoint,
  Extrapoint_templ,
  Extrapoint_editor,
  Hotcity,
  Announcement,
  Iupagreement,
  Qrcodesetting,
  Itemssyncdistributorlog,
  Seriesgoods,
  Seriesgoods_editor,
  Changepassword,
  Adapaycapital,
  AdapayMember,
  AdapayMember_editor,
  AdapayMember_contactShop,
  DistiAccount,
  Examinetemplate,
  Posdata,
  Hqdata,
  Tradetotal,
  Offlineactivity,
  Offlineactivity_editor,
  Offlineactivity_detail,
  Distributorofflineactivity,
  Offlineactivitysignuplog,
  Offlineactivitysignuplog_detail,
  Activityprivacyagreement,
  Activityprivacyagreement_editor,
  Activityprivacyagreement_detail,
  H5urllink,
  Offlinejoinactivity,
  Relationsalelog,
  Usersetting,
  Kefuops,
  DataSync,
  Wework,
  Distributorshowrule,
  Evaluationconfigset,
  Evaluationtag,
  Evaluationdistributors,
  Evaluationdistributors_detail,
  Showpart,
  Showpart_editor,
  PreferentialactivitiesOrderAD_editor,
  Repairservicemanage,
  AskHelp,
  WhiteList,
  Sessions,
  Order,
  SleepChangeLog,
  StoreRestManange,
  Material,
  Aftersalematerial,
  Aftersalematerial_editor,
  Gift,
  Activity,
  Activity_editor,
  CustomTem,
  CustomMessage,
  CustomMessage_operation,
  Orderrecommenditem,
  Functionpage,
  Examinefunctionpage,
  Materialcreate,
  Materialrecord,
  Materialstatistics,
  PiiLog,
  PiiSetting,
  Bundlediscount,
  Bundlediscount_editor,
  SelfLog,
  Selfhandleorders,
  Selfhandlesetting,
  ManageinfomationTwo,
  FastBtnSetting,
  RiskOrder,
  AddressBlack,
  AddressBlackSetting,
  Instructionsimg,
  RiskOrder,
  OrderGift,
  OrderGift_operation,
  DevExWarranty,
  Instructionsimg,
  WarrantyCertificate,
  WarrantyOperation,
  DevExWarranty,
  OrderCollectionStatic,
  Navmarketing,
  Tabbarsetting,
  GoodsPricecheck
}

// 如果路由通过页面传值那么需要定义当前值的字段名称
export let routesParamsKey = {
  Preferentialactivities_editor: 'preferential_id',
  Shopstorelist_editor: 'wxShopId',
  Goodsphysical_editor: 'itemId',
  Godsphysicalkj_editor: 'itemId',
  Basearticle_editor: 'wxShopId',
  Brandmaterial_editor: 'media_id',
  Servicegoods_editor: 'itemId',
  Shippingtemplates_editor: 'templatesId',
  Marketingbargain_editor: 'bargain_id',
  Marketingactivity_editor: 'type',
  Groupsindex_editor: 'groups_id',
  Groupsindex_detail: 'team_id',
  Groupsindex_team: 'groups_id',
  Marketingcommunity_editor: 'community_id',
  Marketingcommunityactivity_editor: 'activity_id',
  Marketingseckill_editor: 'seckill_id',
  Limitedtimesale_editor: 'seckill_id',
  Oldbynew_editor: 'old_id',
  Marketingdeliveryorder_detail: 'delivery_id',
  Marketingsfulldiscount_editor: 'marketing_id',
  Marketingsfullminus_editor: 'marketing_id',
  Marketingsfullgift_editor: 'marketing_id',
  Marketingspluspricebuy_editor: 'marketing_id',
  Planting_editor: 'marketing_id',
  Shopwxshop_editor: 'wxShopId',
  Marketingpackage_editor: 'package_id',
  Goodslimit_editor: 'limit_id',
  ShopActivities_editor: 'item_id',
  ShoppingGuideTask_editor: 'task_id',
  Shoppingguidenotification_editor: 'id',
  Pointsmallphysical_editor: 'itemId',
  Taxstrategy_editor: 'id',
  StoreInformation_editor: 'id',
  Extrapoint_editor: 'id',
  AdapayMember_editor: 'id',
  AdapayMember_contactShop: 'id',
  Offlineactivity_editor: 'id',
  Activityprivacyagreement_editor: 'id',
  PreferentialactivitiesOrderAD_editor: 'id',
  Showpart_editor: 'activity_id',
  Aftersalematerial_editor: 'id',
  Seriesgoods_editor: 'id',
  Bundlediscount_editor: 'activity_id',
  Goodspresale_editor: 'activity_id'
}
