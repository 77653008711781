<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="430" :row-actions-align="'left'"
      :fixed-row-action="true" :split-count="6" url="byte_mini/customizepage/list" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }">
      <template slot="tableTop">
        <el-tabs v-model="platform" type="card" @tab-click="handleTabPlatformClick">
          <el-tab-pane label="抖音小程序" name="byte_mini"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabActiveClick">
          <el-tab-pane :label="`全部(${examine_status_total_count})`" name="all"></el-tab-pane>
          <el-tab-pane :label="`待提交(${examine_status_before_count})`" name="waiting"></el-tab-pane>
          <el-tab-pane :label="`待审核(${examine_status_waiting_count})`" name="examine"></el-tab-pane>
          <el-tab-pane :label="`审核未通过(${examine_status_refuse_count})`" name="refuse"></el-tab-pane>
          <el-tab-pane :label="`审核已通过(${examine_status_pass_count})`" name="pass"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <ExamineTemplate
      @closeDialog="closeExamineDialog"
      :examineDialogVisible="examineDialogVisible"
      :templateId="selectTemplate?.id"
      templateType="custompage"
    ></ExamineTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSetting } from '@shopex/finder'
import { delCustomPage } from '@/api/wxa'
import ExamineTemplate from '@/view/examine/template/examineTemplate'
import { EXAMINE_STATUS, EXAMINE_STATUS_TYPE } from '@/consts'

const PAGE_TYPE_OPTIONS = [
  {label: '活动', value: 'activity'},
  {label: '服务', value: 'services'},
  {label: '快捷入口', value: 'quick_entrance'},
  {label: '其他', value: 'other'}
]

export default {
  components: { ExamineTemplate },
  data() {
    return {
      setting: createSetting({
        search: [
          { name: '页面ID', key: 'id' },
          { name: '页面名称', key: 'page_name' },
          {
            name: '关联类型',
            key: 'related_type',
            type: 'select',
            options: PAGE_TYPE_OPTIONS
          },

        ],
        columns: [
          { name: '页面ID', key: 'id', minWidth: 80  },
          { name: '页面名称', key: 'page_name', minWidth: 150 },
          {
            name: '审核状态',
            key: 'examine_status', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          {
            name: '是否启用',
            key: 'is_open', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: row.is_open == '0' ? 'warning' : 'success'
                  }
                },
                row.is_open == '0' ? '禁用' : '启用'
              )
          },
          {
            name: '关联类型',
            key: 'related_type',
            minWidth: 150,
            formatter: (v) => PAGE_TYPE_OPTIONS.find((item) => item.value == v)?.label
          },
          { name: '有效期', key: 'expire_time', minWidth: 160, formatter: (v) => v == '0'? '' : v },
        ],
        actions: [
          {
            name: '删除',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDelCustomPage(row[0])
              }
            }
          },
          {
            name: '审核记录',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleExamineItemDialog(row)
              }
            }
          },
          {
            name: '审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 2
            },
            action: {
              handler: (row) => {
                this.handleExamineItemDialog(row[0])
              }
            }
          },
        ]
      }),
      platform: 'byte_mini',
      examine_status_total_count: 0,
      examine_status_waiting_count: 0,
      examine_status_refuse_count: 0,
      examine_status_pass_count: 0,
      examine_status_before_count: 0,
      activeName: 'all',
      selectTemplate: null,
      examineDialogVisible: false,
    }
  },
  methods: {
    beforeSearch(params) {
      const status = {
        'all': '',
        'waiting': '1',
        'examine': '2',
        'refuse': '3',
        'pass': '4'
      }
      params = {
        ...params,
        examine_status: status[this.activeName],
        getAll: true,
        template_name: this.template_name,
        platform: this.platform
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.examine_status_before_count = data.examine_status_before_count
      this.examine_status_total_count = data.examine_status_total_count
      this.examine_status_waiting_count = data.examine_status_waiting_count
      this.examine_status_refuse_count = data.examine_status_refuse_count
      this.examine_status_pass_count = data.examine_status_pass_count
      return data
    },
    //切换抖音小程序还是微信小程序
    handleTabPlatformClick() {
      if (this.platform == 'wxapp') {
        this.url = `/wxa/customizepage/list`
      } else {
        this.url = `byte_mini/customizepage/list`
      }
    },
    //切换审核状态
    handleTabActiveClick(tab) {
      this.$refs.finder.refresh(true)
    },
    //删除页面
    async handleDelCustomPage({ id }) {
      this.$confirm('确认删除当前页面吗？').then((_) => {
        delCustomPage(id).then((res) => {
          this.$message({ type: 'success', message: '操作成功！' })
          this.$refs.finder.refresh(true)
        })
      })
    },
    //审核审批
    closeExamineDialog() {
      this.examineDialogVisible = false
      this.$refs.finder.refresh()
    },
    //打开审核
    handleExamineItemDialog(item) {
      this.selectTemplate = item
      this.examineDialogVisible = true
    },
  },
  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type', 'admin_type'])
  },
  watch: {
    admin_type: {
      handler(newVal, oldVal) {
        if (newVal !== 'order') {
          this.platform = 'wxapp'
          this.url = `wxa/customizepage/list`
        } else {
          this.platform = 'byte_mini'
          this.url = `byte_mini/customizepage/list`
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped></style>
