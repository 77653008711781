import fetch from '@/utils/fetch'

export const List = '/aftersales/repair/distributors/restset/changeLog'

export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}
