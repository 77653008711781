import { pickBy, isObject } from "@/utils";
import AttrCusTom from "./attr-custom.vue";
import store from "@/store";
import AttrSearchAb from "./attr-searchAb.vue";
export default {
  name: "page",
  setting: [
    {
      label: "文字样式",
      key: "titleColor",
      component: "radiobutton",
      options: [
        { name: "白色", label: "white" },
        { name: "黑色", label: "black" },
      ],
      value: "white",
      // tip: '页面标题文字请在管理后台装修-商城信息中填写。'
    },
    {
      label: "标题位置",
      key: "titlePosition",
      component: "radiobutton",
      options: [
        { name: "居左", label: "left" },
        { name: "居中", label: "center" },
      ],
      value: "left",
    },
    {
      label: "导航风格",
      key: "style",
      component: "radiobutton",
      options: [
        { name: "标准", label: "standard" },
        { name: "沉浸式", label: "immersive" },
      ],
      value: "standard",
      tip: "沉浸式风格建议将图片/轮播图作为页面中第一个组件。",
    },{
      label: "沉浸式背景",
      key: "immersiveColor",
      component: "color",
      value:'',
      isShow: function () {
        return this.value.style == "immersive";
      },
    },
    {
      label: "导航图片",
      key: "titleBackgroundImage",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
      isShow: function () {
        const bg =
          store.getters.admin_type != "order"
            ? "https://cdn-image.aarstore.com.cn/constxcx/56792cecd7054278f1bee672b4d650a5OJ6auGe6ZvdexkaF7OzRJhMeA3kPA1Jp"
            : "https://uat-aarminiprogram-image.oss-cn-beijing.aliyuncs.com/image/1/2023/08/31/1812d6224b928aa818b554515d745dcbCjlvsdWBgsI8yFSHztMvo7tt1lzgpQFo";
        this.value.titleBackgroundImage = this.value.titleBackgroundImage || bg;
        return this.value.style == "standard" || this.value.custom == "1";
      },
    },
    {
      label: "导航图片",
      key: "titleBackgroundImage",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
      isShow: function () {
        const bg =
          store.getters.admin_type != "order"
            ? "https://cdn-image.aarstore.com.cn/constxcx/56792cecd7054278f1bee672b4d650a5OJ6auGe6ZvdexkaF7OzRJhMeA3kPA1Jp"
            : "https://uat-aarminiprogram-image.oss-cn-beijing.aliyuncs.com/image/1/2023/08/31/1812d6224b928aa818b554515d745dcbCjlvsdWBgsI8yFSHztMvo7tt1lzgpQFo";
        this.value.titleBackgroundImage = this.value.titleBackgroundImage || bg;
        return this.value.style == "standard" || this.value.custom == "1";
      },
    },
    {
      label: "自定义风格",
      key: "custom",
      component: "radiobutton",
      options: [
        { name: "是", label: "1" },
        { name: "否", label: "0" },
      ],
      value: "0",
      isShow: function () {
        if (this.value.style == "standard") {
          this.value.custom = "0";
        }
        return this.value.style == "immersive";
      },
    },
    {
      key: "setting",
      component: function (h, { key }) {
        console.log(this.info);
        return (
          <AttrCusTom
            info={this.info}
            v-model={this.value[key]}
            type={this.value.style}
          />
        );
      },
      isShow: function () {
        return this.value.custom == "1" && this.value.style == "immersive";
      },
    },
    {
      label: "导航背景",
      key: "navigateStyle",
      component: "radiobutton",
      options: [
        { name: "颜色背景", label: "1" },
        { name: "图片背景", label: "2" },
      ],
      value: "1",
      isShow: function () {
        return this.value.style == "standard" || this.value.custom == "1";
      },
    },
    {
      label: "",
      key: "navigateBackgroundColor",
      component: "color",
      value: "#000000",
      isShow: function () {
        if (this.value.navigateStyle == "1") {
          this.value.navigateBackgroundImage = "";
        }
        return (
          this.value.navigateStyle == "1" &&
          (this.value.style == "standard" || this.value.custom == "1")
        );
      },
    },
    {
      label: "",
      key: "navigateBackgroundImage",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
      isShow: function () {
        if (this.value.navigateStyle == "2") {
          this.value.navigateBackgroundColor = "#000000";
        }
        return (
          this.value.navigateStyle == "2" &&
          (this.value.style == "standard" || this.value.custom == "1")
        );
      },
    },
    {
      label: "是否展示搜索",
      key: "showsearch",
      component: "radiobutton",
      options: [
        { name: "开启", label: '1' },
        { name: "关闭", label: '0' },
      ],
      value: '1',
    },
    {
      label: "",
      key: "searchSetting",
      component: function (h, { key }) {
        return <AttrSearchAb v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        abId: '',
        abName:'',
        data: [{ label: '', name: '', value: '', data: 'icon',colorsetting:'white' }]
      },
      isShow: function () {
        return this.value.showsearch == '1';
      },
    },
    // {
    //   label: "购物袋入口",
    //   key: "cartIcon",
    //   component: "radiobutton",
    //   options: [
    //     { name: "开启", label: '1'},
    //     { name: "关闭", label: '0' },
    //   ],
    //   value: '1',
    // },

  ],
  transformIn: (v) => {
    //
    const { name, base } = v;
    let {
      navigateStyle,
      navigateBackgroundColor,
      navigateBackgroundImage,
      titleStyle,
      titleColor,
      titleBackgroundImage,
      titlePosition,
      showsearch = '1',
      cartEntrance = '0',
      cartImage = '',
      // pageBackgroundStyle,
      // pageBackgroundColor,
      // pageBackgroundImage,
      titleText,
      style,
      setting,
      custom,
      searchSetting = {
        abId: '',
        abName:'',
        data: [{ label: '', name: '', value: '', data: 'icon',colorsetting:'white' }]
      }
    } = base || {};
    return {
      name,
      ...base,
      navigateStyle,
      navigateBackgroundColor,
      navigateBackgroundImage,
      titleStyle: 2,
      titleColor,
      titleBackgroundImage: titleBackgroundImage,
      titlePosition,
      showsearch,
      cartEntrance,
      cartImage,
      // pageBackgroundStyle,
      // pageBackgroundColor,
      // pageBackgroundImage,
      titleText,
      style,
      setting,
      custom,
      searchSetting:{
        ...searchSetting,
        currentTest:'',
      }
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          navigateStyle: "navigateStyle",
          navigateBackgroundColor: "navigateBackgroundColor",
          navigateBackgroundImage: "navigateBackgroundImage",
          titleStyle: "titleStyle",
          titleColor: "titleColor",
          titleBackgroundImage: "titleBackgroundImage",
          titlePosition: "titlePosition",
          showsearch: "showsearch",
          cartEntrance: "cartEntrance",
          cartImage: "cartImage",
          // pageBackgroundStyle: "pageBackgroundStyle",
          // pageBackgroundColor: "pageBackgroundColor",
          // pageBackgroundImage: "pageBackgroundImage",
          titleText: "titleText",
          style: "style",
          setting: "setting",
          custom: "custom",
          searchSetting: "searchSetting"
        });
      },
    });
  },
};
