<template>
  <div>
    <el-alert
      v-if="!distributor_id && !IS_DISTRIBUTOR"
      title="请从【门店作息管理】菜单进入"
      type="warning">
    </el-alert>
    <div v-else>
      <div v-if="distributor_id" style="margin-bottom: 20px;">
        {{ distributor_name }}
        <div style="color: #888;font-size: 13px;margin-top: 8px">
          Apple ID：{{ shop_code }}
        </div>
      </div>
      <el-card v-for="item in data" :key="item.session_week" class="box-card">
        <el-row>
          <el-col :span="4">
            <div class="card-title card-item">
              <div class="week">{{ item.session_week | getWeek }}</div>
              <div class="statu" :style="{ color: item.pause ? '#E6A23C' : '#409EFF' }">{{ item.pause ? '暂停预约' : '可预约' }}
              </div>
            </div>
          </el-col>
          <el-col :span="16" class="content">
            <div class="bg" v-if="item.pause === 1"></div>
            <el-table :data="item.sessions"
              :header-cell-style="{ background: '#ecf5ff', color: '#606266', 'font-weight': 650 }">
              <el-table-column prop="id" label="场次ID" />
              <el-table-column prop="session_name" label="场次名称" />
              <el-table-column prop="name" label="不可维修产品">
                <template slot-scope="scope">
                  <div v-if="scope.row.not_repairable_products.length > 0">
                    <span v-for="item in scope.row.not_repairable_products" :key="item.product_id">{{ item.product_name
                    }}</span>
                  </div>
                  <span v-else>不限制</span>
                </template>
              </el-table-column>
              <el-table-column prop="quota" label="场次名额">
                <template slot-scope="scope">
                  {{ scope.row.quota == -1 ? '不限制' : scope.row.quota }}
                </template>
              </el-table-column>
              <el-table-column prop="session_end_time" label="最后可预约时间" />
              <el-table-column label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                  <el-button @click="handleChangeDistributorSessions(scope.row)" type="text" size="small">修改</el-button>
                  <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="4">
            <div class="card-item">
              <el-button type="primary" @click="handleChangeDistributorSessions(item)" plain>添加场次</el-button>
              <el-button v-if="item.pause === 0" type="success" @click="handleChangeStatus(item, 1)" plain>暂停预约</el-button>
              <el-button v-else plain style="border-style:dashed" @click="handleChangeStatus(item, 0)">开启预约</el-button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <el-dialog title="编辑场次" :visible.sync="dialogVisible" width="600px">
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <el-form-item label="场次名称" prop="session_name">
          <el-input type="text" placeholder="请添加场次名称" v-model="form.session_name" clearable></el-input>
        </el-form-item>
        <el-form-item label="场次名额" prop="quota">
          <el-radio-group v-model="isquota">
            <el-radio :label="true">限额</el-radio>
            <el-radio :label="false">不限额</el-radio>
          </el-radio-group>
          <el-input-number v-if="isquota" type="text" placeholder="请添加场次名额" controls-position="right" :min="0"
            v-model="form.quota" clearable />
          <!-- <p class="tips">不填写即为不限名额</p> -->
        </el-form-item>
        <el-form-item label="可预约截止时间" prop="session_end_time" >
          <el-time-picker v-model="form.session_end_time" placeholder="选择时间" format="HH:mm" value-format="HH:mm" />
        </el-form-item>
        <el-form-item label="最晚可履约时间" prop="use_end_time">
          <el-time-picker
            format="HH:mm"
            value-format="HH:mm"
            v-model="form.use_end_time"
            placeholder="最晚可履约时间">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="不可维修商品" prop="not_repairable_product_ids">
          <el-select placeholder="请选择" v-model="form.not_repairable_product_ids" multiple>
            <el-option v-for="item in options" :key="item.id" :label="item.product_name"
              :value="item.product_id"></el-option>
          </el-select>
          <p class="tips">不设置即为当前门店支持维修的商品</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAction">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getDistributorSessions, saveDistributorSessions, changeDistributorSessionsStatus, delDistributorSessions, getDistributorProductsContents, getDistributorSessionsDetail } from '../../../api/workandrest'
import { IS_DISTRIBUTOR } from '@/utils'

export default {
  data() {
    return {
      data: [],
      dialogVisible: false,
      form: {
        session_name: "",//场次名称
        session_week: "",//周几
        quota: "",//场次额度   不限额为-1
        session_end_time: null,//场次预约截止时间，如11:00
        not_repairable_product_ids: [],//维修产品id
        id: "",//场次ID，新增店铺场次时不传，修改时必填
        // use_time: ['00:00', '23:59'],
        use_end_time: null,
      },
      rules: {
        session_name: [
          { required: true, message: '请添加场次名称', trigger: 'blur' },
        ],
        session_end_time: [
          { required: true, message: '请选择可预约截止时间', trigger: 'blur' },
        ],
        use_end_time: [
          { required: true, message: '请选择最晚可履约时间', trigger: 'blur' },
        ],
      },
      options: [],
      isquota: true,
      distributor_id: undefined,
      distributor_name: '',
      shop_code: undefined,
      IS_DISTRIBUTOR: IS_DISTRIBUTOR()
    }
  },
  mounted() {
    if(this.$route.query) {
      const { distributor_id, distributor_name, shop_code } = this.$route.query
      this.distributor_id = distributor_id
      this.distributor_name = distributor_name
      this.shop_code = shop_code
    }
    if(!this.distributor_id && !this.IS_DISTRIBUTOR) return
    this.getList()
    this.getRepairGoods()
  },
  filters: {
    getWeek(value) {
      switch (value) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 7:
          return '周日'

      }
    }
  },
  computed: {
    ...mapGetters(['shopId'])
  },
  methods: {
    getList() {
      getDistributorSessions({distributor_id: this.distributor_id}).then(res => {
        console.log(res)
        this.data = res.data.data
      })
    },
    getRepairGoods() {
      getDistributorProductsContents(this.shopId || this.distributor_id).then(res => {
        this.options = res.data.data
      })
    },
    handleChangeDistributorSessions(item) {
      const form = {
        session_name: "",//场次名称
        session_week: "",//周几
        quota: 0,//场次额度   不限额为-1
        session_end_time: null,//场次预约截止时间，如11:00
        not_repairable_product_ids: [],//维修产品id
        id: "",//场次ID，新增店铺场次时不传，修改时必填
        use_time: ['00:00', '23:59'],
      }
      this.$refs.form && this.$refs.form.resetFields()
      this.dialogVisible = true
      this.isquota = item.quota != -1
      if (item.id) {
        getDistributorSessionsDetail({ id: item.id, distributor_id: this.distributor_id }).then(res => {
          const data = res.data.data;
          data.use_time = [
            data.use_start_time,
            data.use_end_time,
          ]
          const not_repairable_product_ids = data.not_repairable_products.map(item => item.product_id)
          this.form = Object.assign(form, { ...data, not_repairable_product_ids })
        })
      } else {
        this.form = Object.assign(form, item)
      }
      console.log()
      //

    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该场次, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      })
        .then(() => {
          delDistributorSessions(id, this.distributor_id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
    },
    handleChangeStatus(item, status) {
      const ids = item.sessions.map(e => e.id)
      if (ids.length < 1) {
        this.$message.warning('暂无场次可暂停')
        return
      }
      changeDistributorSessionsStatus({ session_ids: ids.join(','), pause: status, distributor_id: this.distributor_id }).then(res => {
        this.$message.success('更改成功')
        this.getList()
      })
    },
    cancelAction() {
      this.dialogVisible = false
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { not_repairable_product_ids, quota, ...data } = this.form;
          not_repairable_product_ids = not_repairable_product_ids.join(',')
          saveDistributorSessions({ not_repairable_product_ids, ...data, quota: this.isquota ? quota : -1, distributor_id: this.distributor_id }).then(res => {
            this.getList()
            this.dialogVisible = false
            this.$message.success('保存成功')
          })
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  display: flex;
  align-content: center;

  .el-col {

    .card-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .el-button {
        &:last-child {
          margin-top: 20px;
        }
      }
    }

    .card-title {
      .week {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .el-table {
      border: 1px solid #EBEEF5;
      border-bottom: none;
    }
  }
}

.tips {
  line-height: 24px;
  font-size: 10px;
}

.content {
  position: relative;

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($color: #FFF, $alpha: 0.5);
    z-index: 9;
  }
}
</style>
