import fetch from '@/utils/fetch'

export const List = '/normalgift/activity/list'
export const ActivityList = '/normalgift/activity/relshop/list'

/** 新增 */
export function create (data) {
  return fetch({
    url: '/normalgift/activity/create',
    method: 'post',
    data
  })
}

/** 更新 */
export function update (data) {
  return fetch({
    url: '/normalgift/activity/update',
    method: 'post',
    data
  })
}

/** 中止活动 */
export function stop (activity_id) {
  return fetch({
    url: `/normalgift/activity/close`,
    method: 'post',
    data: {activity_id}
  })
}
export function deleteItem (data) {
  return fetch({
    url: `/normalgift/activity/relshop/del`,
    method: 'post',
    data
  })
}

/** 详情 */
export function detail (activity_id) {
  return fetch({
    url: `/normalgift/activity/info`,
    method: 'get',
    params: {
      activity_id
    }
  })
}
