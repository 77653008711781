<style lang="scss">
.hidden-search {
  .wgt-page-content {
    padding-right: 0px !important;
  }
}

.wgt-page {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 999;

  &-content {
    // background-image: url('../../../../assets/imgs/weapp-header.png');
    height: 64px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    overflow: hidden;
  }

  .wgt-page-content {
    display: flex;
    box-sizing: border-box;

    .search-icon {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-top: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(153, 153, 153, 0.2);
      position: absolute;
      right: 100px;

      i {
        color: inherit;
      }
    }
  }

  .header-container {
    height: 32px;
    margin-top: 26px;
    flex: 1;
    line-height: 32px;

    // align-items: center;
    .title-image {
      display: inline-block;
      margin-top: 6px;
    }
  }

  &.immersive {
    position: absolute;
    z-index: 999;
    width: 100%;
    background-color: transparent !important;

    .title-image {
      visibility: hidden;
    }
  }

  .search-input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    &-content {
      height: 32px;
      background-color: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 0 15px;
      display: flex;
      align-items: center;
      color: #bdbdbd;
      font-size: 12px;

      .icon {}
      .el-icon-search{
        color: inherit!important;
      }

      .text {
        padding-left: 5px;
        padding-top: 2px;
      }
    }
  }
}
</style>
<template>
  <div v-if="value"
    :class="{ 'wgt-page': true, 'immersive': value.style == 'immersive', 'hidden-search': value.showsearch != 1 }"
    :style="headerStyle" @click="handleClickHeader">
    <div class="wgt-page-content" :style="contentStyle">
      <div class="header-container" :style="containerStyle">
        <span v-if="value.titleStyle == '1'">{{ value.titleText }}</span>
        <sp-image v-else fit="contain" :height="22" class="title-image" :src="value.titleBackgroundImage" />
      </div>
      <p class="search-icon" v-if="showIcon" :style="iconStyle">
        <i class="el-icon-search" />
      </p>
    </div>
    <div class="search-input" v-if="showSearchInput">
      <div class="search-input-content" :style="inputBgstyle">
        <i class="el-icon-search icon" />
        <span class="text">新年好礼到你身边</span>
      </div>
    </div>
  </div>
</template>

<script>
const weappHeaderDark = require('@/assets/imgs/weapp-header-dark.png')
const weappHeaderLight = require('@/assets/imgs/weapp-header-light.png')
import config from './config'
export default {
  name: 'Page',
  wgtName: '页面设置',
  wgtDesc: '',
  wgtType: 0,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    click: Function,
    value: [Object, Array]
  },
  data() {
    return {}
  },
  computed: {
    headerStyle() {
      const { navigateStyle, navigateBackgroundColor, style, navigateBackgroundImage } =
        this.value || {}
      const styleValue = {
        'background-size': 'contain',
        'background-position': '100%',
      }
      if (navigateStyle == '1') {
        Object.assign(styleValue, {
          'background-color': navigateBackgroundColor
        })
      } else {
        Object.assign(styleValue, {
          'background-size': 'contain',
          'background-position': '100%',
        })
      }
      if (style == 'standard') {
        Object.assign(styleValue, {
          'background-image': `url(${navigateBackgroundImage})`,
        })
      }
      return styleValue
    },
    contentStyle() {
      const { titleColor,immersiveColor,style } = this.value || {}
      return {
        'background-image': `url(${titleColor == 'black' ? weappHeaderDark : weappHeaderLight})`,
        'background-size': 'contain',
        'background-position': '100%',
      }
    },
    containerStyle() {
      const { titlePosition, titleColor } = this.value || {}
      return {
        'text-align': titlePosition,
        'color': titleColor,
        'margin-left': titlePosition == 'left' ? '15px' : '0px'
      }
    },
    showIcon() {
      const { showsearch, searchSetting } = this.value || {};
      const { data, currentTest, abId } = searchSetting || {}
      let type = ''
      if (abId) {
        type = data.find(item => item.value == currentTest)?.data
      } else {
        type = data[0]?.data
      }
      return showsearch == 1 && type == 'icon'
    },
    showSearchInput() {
      const { showsearch, searchSetting, style } = this.value || {};
      const { data, currentTest, abId } = searchSetting || {}
      let type = ''
      if (abId) {
        type = data.find(item => item.value == currentTest)?.data
      } else {
        type = data[0]?.data
      }
      return showsearch == 1 && type == 'searchInput' && style != 'immersive'
    },
    iconStyle() {
      const { searchSetting } = this.value || {};
      const { data, currentTest, abId } = searchSetting || {}
      let colorsetting = ''
      if (abId) {
        colorsetting = data.find(item => item.value == currentTest)?.colorsetting
      } else {
        colorsetting = data[0]?.colorsetting
      }
      if (colorsetting == 'black') {
        return {
          'background-color': '#292929',
          'color': '#929292'
        }
      } else {
        return {
          'background-color': '#FAFAFA',
          'color': '#BEBEBE'
        }
      }
    },
    inputBgstyle() {
      const { searchSetting } = this.value || {};
      const { data, currentTest, abId } = searchSetting || {}
      let colorsetting = ''
      if (abId) {
        colorsetting = data.find(item => item.value == currentTest)?.colorsetting
      } else {
        colorsetting = data[0]?.colorsetting
      }
      if (colorsetting == 'black') {
        return {
          'background-color': '#292929',
          'color': '#929292'
        }
      } else {
        return {
          'background-color': '#FAFAFA',
          'color': '#BEBEBE'
        }
      }
    }
  },
  created() { },
  methods: {
    handleClickHeader() {
      this.$emit('change')
    }
  }
}
</script>
