<template>
  <div class="warranty-container">
    <div class="warranty-card">
      <h2>额外保修操作</h2>
      <div  class="warranty-form">
        <div style="margin: 24px 0;">
          <el-radio-group v-model="type" size="small">
            <el-radio label="1">额外保修服务编号</el-radio>
            <el-radio label="2">额外保修绑定设备序列号</el-radio>
          </el-radio-group>
        </div>
        <el-input v-model="number" placeholder="请输入"></el-input>
        <el-button type="primary" class="search-btn" @click="handleSearch">查询</el-button>
      </div>
    </div>
    <div class="warranty-info" v-if="info">
      <div class="info-item" v-for="item of warrantyInfo" :key="'warranty' + item.key">
        <div class="info-item-label">{{ item.label }}</div>
        <div class="info-item-value" :class="item.key === 'status_msg' ? 'info-item-status' : ''">{{ item.value }}</div>
      </div>
      <el-divider />
      <h4>订单信息</h4>
      <div class="info-item" v-for="item of orderInfo" :key="'info' + item.key">
        <div class="info-item-label">{{ item.label }}</div>
        <div class="info-item-value">{{ item.value }}</div>
      </div>
      <el-divider />
      <div class="warranty-info-action" v-if="!this.error_msg">
        <el-button type="text" v-if="!show_repair_btn && info.status == 1" @click="handleChargeOffShop">查看核销门店</el-button>
        <el-button type="primary" v-if="info.status_desc == 'bound'" @click="handleUpdateSN">修改设备序列号</el-button>
      </div>
      <div class="warranty-info-tips" v-else>
        <p>{{ error_msg }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useDialog } from '@shopex/finder'
import { getMemberData } from './api'
import { formatTimeStampToStr } from '@/utils/index'

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '确定', key: 'save', type: 'primary', size: 'default' }
]

const SOURCE_ENUM = {
  'jd': '京东到家',
  'tb': '淘宝',
  'mt': '美团闪购',
  'wxapp': '微信小程序',
  'dy': '抖音',
  'jdzy': '京东自营',
  'eleme': '饿了么',
  'amap': '高德',
  'qyg': '企业购小程序',
  'pos': '线下单',
  'douchao': '抖音超市'
}

export default {
  data() {
    return {
      type: '1',
      number: '',
      warrantyInfo: [
        { label: '状态', key: 'status_msg', value: '' },
        { label: '保修服务编号', key: 'service_bn', value: '' },
        { label: '保修名称', key: 'guarantee_name', value: '' },
        { label: '绑定设备', key: 'device_name', value: '' },
        { label: '设备序列号', key: 'device_sn', value: '' },
        { label: '绑定时间', key: 'created', value: '' },
        { label: '有效期至', key: 'expire_time', value: '' }
      ],
      orderInfo: [
        { label: '订单编号', key: 'order_id', value: '' },
        { label: '设备名称', key: 'device_name', value: '' },
        { label: '购买平台', key: 'order_source', value: '' },
        { label: '购买门店', key: 'buy_distributor_name', value: '' },
        { label: '保修所属经销商', key: 'guarantee_dealer_name', value: '' },
      ],
      info: null,
      show_repair_btn: false, //是否显示维修按钮
      error_msg: '', //错误信息
    }
  },
  methods: {
    async handleSearch() {
      let params = {}
      if(this.type === '1') {
        if(!this.number) {
          this.$message.error('请输入服务编号')
          return
        }
        params = {
          service_bn: this.number
        }
      } else {
        if(!this.number) {
          this.$message.error('请输入绑定设备序列号')
          return
        }
        params = {
          device_sn: this.number
        }
      }
      const res = await getMemberData(params)
      const { data } = res.data
      this.show_repair_btn = data.show_repair_btn
      this.error_msg = data.error_msg
      const info = data.info
      if(info) {
        this.info = data.info
        this.warrantyInfo.forEach(item => {
          if (item.key === 'created' || item.key === 'expire_time') {
            item.value = formatTimeStampToStr(info[item.key])
          } else {
            item.value = info[item.key]
          }
        })
        this.orderInfo.forEach(item => {
          if(item.key === 'order_source') {
            item.value = SOURCE_ENUM[info[item.key]]
          } else {
            item.value = info[item.key]
          }
        })
      } else {
        this.$message.error('未查询到相关信息')
      }
    },
    handleChargeOffShop() {
      useDialog(null, {
        title: `核销门店`,
        width: '30%',
        info: this.info,
        component: () => import('./comp/chargeOffShop.vue'),
        actions: []
      })
    },
    handleUpdateSN() {
      useDialog(null, {
        title: `修改SN`,
        width: '30%',
        id: this.info.id,
        component: () => import('./comp/updateSN.vue'),
        actions: commonActions
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.warranty-container {
  display: flex;
  justify-content: space-around;
  padding: 24px;

  .warranty-card {
    margin-top: 48px;
    width: 40%;
    h2 {
      text-align: center;
      font-size: 34px;
    }
  }

  .warranty-form {
    .search-btn {
      width: 100%;
      margin: 24px 0;
    }
  }

  .warranty-info {
    width: 45%;
    background-color: #f5f5f7;
    padding: 24px;
    border-radius: 12px;
    h4 {
      font-weight: 600;
      color: #676a6e;
    }
    .info-item {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .info-item-status {
        color: #1480e3;
      }
    }
    &-action {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }
    &-tips {
      margin-top: 24px;
      color: red;
    }
  }
}
</style>
