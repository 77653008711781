<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="distributor_id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      :hooks="{
        beforeSearch
      }"
      @reset="onReset"
    >
      <template #shop>
        <el-button class="btn_input" @click="handleClick('shop')">
          {{ shopText }}
          <i :class="getIconClass(relDistributors)" @click.stop="handleClear('shop')"></i>
        </el-button>
      </template>
      <template #dealer>
        <el-button class="btn_input" @click="handleClick('dealer')">
          {{ dealerText }}
          <i :class="getIconClass(relDealers)" @click.stop="handleClear('dealer')"></i>
        </el-button>
      </template>
    </SpFinder>
    <ImportDialog
      ref="importAddDialog"
      v-show="false"
      buttonName="导入作息"
      buttonSize="medium"
      fileName="导入作息"
      fileType="upload_distributor_repair_sessions"
    />
    <DistributorSelect
      :store-visible="shopVisible"
      :rel-data-ids="relDistributors"
      @chooseStore="(data) => chooseAction('shop', data)"
      @closeStoreDialog="handleClose('shop')"
    />
    <DealerSelect
      :store-visible="dealerVisible"
      :rel-data-ids="relDealers"
      @chooseStore="(data) => chooseAction('dealer', data)"
      @closeStoreDialog="handleClose('dealer')"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List, handleExport } from './api'
import ImportDialog from '@/components/importDialog'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'

export default {
  components: {
    ImportDialog,
    DistributorSelect,
    DealerSelect
  },
  data () {
    return {
      table_url: List,
      shopVisible: false,
      dealerVisible: false,
      relDistributors: [],
      relDealers: [],
      distributor_id: '',
      dealer_id: '',
      selectConfig: {
        shop: {
          visible: 'shopVisible',
          defaultText: '归属门店',
          relData: 'relDistributors',
          idKey: 'distributor_id'
        },
        dealer: {
          visible: 'dealerVisible',
          defaultText: '归属经销商',
          relData: 'relDealers',
          idKey: 'dealer_id'
        }
      }
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    },
    shopText() {
      return this.getSelectedText(this.relDistributors, 'shop')
    },
    dealerText() {
      return this.getSelectedText(this.relDealers, 'dealer')
    }
  },
  mounted() {},
  methods: {
    openImportAddDialog() {
      this.$refs.importAddDialog.visible = true
    },
    openExportDialog() {
      const searchparams = this.$refs.finder.getSearchParams()
      const selection = this.$refs.finder.$refs.finderTable.getSelection()
      let params = {
        ...searchparams,
        is_export: 1,
        distributor_id: selection?.map(item => item.distributor_id)
      }
      handleExport(params).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    handleClick(type) {
      this[this.selectConfig[type].visible] = true
    },
    handleClear(type) {
      const config = this.selectConfig[type]
      this[config.relData] = []
      this[config.idKey] = ''
    },
    handleClose(type) {
      this[this.selectConfig[type].visible] = false
    },
    chooseAction(type, data) {
      const config = this.selectConfig[type]
      this[config.relData] = data
      const ids = data.map(item => item[config.idKey]).join(',')
      this[config.idKey] = ids
      this.handleClose(type)
    },
    refresh() {
      this.$refs.finder.refresh()
    },
    getIconClass(items) {
      return items.length === 0 ? 'el-icon-circle-plus icon' : 'el-icon-circle-close icon'
    },
    getSelectedText(items, type) {
      return items.length > 0 ? `已选择${items.length}` : this.selectConfig[type].defaultText
    },
    beforeSearch(params) {
      return {
        ...params,
        distributor_id: this.distributor_id,
        dealer_id: this.dealer_id
      }
    },
    onReset() {
      this.distributor_id = ''
      this.relDistributors = []
      this.dealer_id = ''
      this.relDealers = []
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .goods-title {
  padding-bottom: 5px;
}
::v-deep .goods-code {
  color: #888;
  font-size: 13px;
}
</style>
