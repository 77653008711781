// 微信小程序自定义页装修
import {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtStoreRuleList,
  WgtTabsCut,
  WgtGoodsLift,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFooterNav,
  WgtProductShelves,
  WgtCustomWgt,
  WgtLift,
  
  WgtContentPart,
  WgtImageText, 
  WgtClassify,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount

} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtStoreRuleList,
  WgtTabsCut,
  WgtGoodsLift,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFooterNav,
  WgtProductShelves,
  WgtCustomWgt,
  WgtLift,
  
  WgtContentPart,
  WgtImageText, 
  WgtClassify,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount
}


export default {
  widgets,
  initiWidgets
}
