<style lang="scss" scoped>
.wgt-content-part {
  .content-part-header {
    width: 100%;
    box-sizing: border-box;
    left: 0;
    right: 0;
    z-index: 20;

    &-inner {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;

      .content-part_h {
        &-icon {
          width: 24px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          position: absolute;
          transition: all 0.3s;

          &-l {
            left: 0px;
            z-index: 99;
          }

          &-r {
            right: 0;
          }
        }

        &-content {
          box-sizing: border-box;
          overflow-x: scroll;
          overflow-y: hidden;
          scroll-behavior: smooth;
          -webkit-overflow-scrolling: touch;
          scroll-snap-type: x mandatory;
          height: 100%;
          width: 100%;

          &::-webkit-scrollbar {
            display: none;
          }

          &_inner {
            width: max-content;
            min-width: 100%;
            box-sizing: border-box;
            transition: all 0.3s;
            height: auto;

            &-0 {
              width: max-content;
              width: 100%;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: space-around;
              box-sizing: border-box;
              transition: all 0.3s;
              height: auto;
            }
          }

          .header-item {
            height: 100%;
            display: inline-block;

            &-0 {
              flex: 1;

              .header-img {
                min-width: 100%;
              }
            }

            .header-img {
              width: fit-content;
              transition: all 0.3s;
              box-sizing: border-box;
              min-width: 100%;
            }
          }
        }
      }
    }
  }

  .content-part-body {
    &_two {
      overflow: hidden;
    }

    &_content {
      display: flex;
      transition: all 0.3s;

      .wgt-bd-content {
        flex-shrink: 0;
        width: 100%;
        height: fit-content;
      }
    }

  }
}
</style>


<template>
  <div :class="{ 'wgt-content-part': true }" :ref="`stickyElement_${value.uuid}`">
    <div class="content-part-header" :style='setOutHeaderStyle'>
      <div class="content-part-header-inner" :style='setInHeaderStyle'>
        <div class="content-part_h-icon-l content-part_h-icon" @click="handleClickIcon('left')"
          v-if="(value.navsetting == 1 && value.fixsetting.leftimgUrl) || (value.navsetting == 0 && value.defaultsetting.leftimgUrl)">
          <el-image fit="contain" style="width: 24px;height: 24px;"
            :src="value.navsetting == 1 ? value.fixsetting.leftimgUrl : value.defaultsetting.leftimgUrl"
            v-show="Math.abs(transformLeft) > 40">
            <div slot="error" class="image-slot" />
          </el-image>
        </div>
        <div class="content-part_h-content" ref="content-part_h">
          <div :class="setStyleInner" ref="content-part_h-inner"
            :style="{ transform: `translateX(${transformLeft || 0}px)` }">
            <div v-for="(item, index) in value.data" :key="`content-part_h_${index}`" :id="`content-part_h_${index}`"
              :class="setNavItemClass" :style='setItemStyle(item, index)' @click="handleClickNav(index)"
              :ref="`content-part_h_${index}`">
              <SpImage :class="setNavClass" :src="item.navActiveIcon" :style="`height: 100%`" fit="heightFix"
                v-show="curIndex == index" />
              <SpImage :class="setNavClass" :src="item.navdefaultIcon" :style="`height: 100%`" fit="heightFix"
                v-show="curIndex != index" />
            </div>
          </div>
        </div>
        <div class="content-part_h-icon-r  content-part_h-icon" @click="handleClickIcon('right')"
          v-if="(value.navsetting == 1 && value.fixsetting.rightimgUrl) || (value.navsetting == 0 && value.defaultsetting.rightimgUrl)">
          <el-image fit="contain" style="width: 24px;height: 24px;"
            :src="value.navsetting == 1 ? value.fixsetting.rightimgUrl : value.defaultsetting.rightimgUrl"
            v-show="Math.abs(transformLeft) <= 40">
            <div slot="error" class="image-slot" />
          </el-image>
        </div>
      </div>
    </div>
    <div class="content-part-body">
      <div class="content-part-body_one" v-if="value.animate == 0">
        <div class="wgt-bd-content" v-for="(item, key) in value.data" :key="`wgt-bd-content__${key}`"
          :ref="`conetnt_${value.uuid}_${key}`">
          <component v-for="(wgt, index) in item?.cusTom?.template" :is="wgt.name" :value="wgt"
            :key="`content-part-component__${index}`" />
        </div>
      </div>
      <div class="content-part-body_two" v-if="value.animate == 1">
        <div class="content-part-body_content" @pointerdown="handleEnter"
          :style="{ 'transform': 'translateX(-' + curIndex * 375 + 'px)', 'marginLeft': margintop + 'px' }">
          <div class="wgt-bd-content" v-for="(item, key) in value.data" :key="`wgt-bd-content__${key}`"
            :ref="`conetnt_${value.uuid}_${key}`" :style="{ height: `${curIndex == key ? 'auto' : '0'}` }">
            <component v-for="(wgt, index) in item?.cusTom?.template" :is="wgt.name" :value="wgt"
              :key="`content-part-component__${index}`" />
          </div>
        </div>
      </div>
      <div class="content-part-body_three" v-if="value.animate == 2">
        <component v-for="(wgt, index) in value.data[curIndex]?.cusTom?.template" :is="wgt.name" :value="wgt"
          :key="`content-part-component__${index}`" />
      </div>
    </div>

  </div>
</template>

<script>
import config from './config'

export default {
  name: 'contentPart',
  wgtName: '内容分区',
  wgtDesc: '',
  wgtIcon: 'wgt_content-part',
  wgtType: 2,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      curIndex: 0,
      transformLeft: 0,
      navwidth: [],
      headerwidth: 0,
      tabwidth: 0,
      isMoused: false,
      starty: 0, //开始的位置x
      endy: 0, //结束的位置y
      critical: 100, //触发翻页的临界值
      margintop: 0, //滑动左拉距离
      height: 0
    }
  },
  mounted() {
  },
  methods: {
    handleClickNav(indx) {
      this.curIndex = indx
      let transformLeft = 0
      const inner = this.$refs[`content-part_h`].offsetWidth;
      for (let index = 0; index <= indx + 1; index++) {
        const item = this.$refs[`content-part_h_${index}`];
        if (item) {
          transformLeft = transformLeft + item[0]?.offsetWidth || 0
        }
      }

      if (inner - transformLeft >= -2) {
        this.transformLeft = 0
        return
      }
      this.transformLeft = inner - transformLeft + 2
    },
    handleClickIcon(type) {
      if (type == 'left') {
        this.transformLeft = 0
        return
      }
      if (type == 'right') {
        let transformLeft = 0
        const inner = this.$refs[`content-part_h`].offsetWidth;
        for (let index = 0; index < this.value.data.length; index++) {
          const item = this.$refs[`content-part_h_${index}`][0];
          transformLeft = transformLeft + Math.floor(item.offsetWidth)
        }
        this.transformLeft = inner - transformLeft
      }
    },
    handleEnter(e) {
      this.isMoused = true
      this.starty = e.pageX
    },
    handleMove(e) {
      if (!this.isMoused) return
      let px = e.pageY;
      this.endy = px;
      if (px - this.starty < 100 && px - this.starty > -100) {
        this.margintop = px - this.starty;
      }

    },
    handleLeaves(e) {
      if (this.endy - this.starty > 100 && this.curIndex > 0) {
        this.curIndex--;
      } else if (this.endy - this.starty < -100 && this.curIndex < this.value.data.length - 1) {
        this.curIndex++;
      }
      this.starty = 0;
      this.endy = 0;
      this.margintop = 0;
      this.isMoused = false
      let transformLeft = 0
      if (this.curIndex > 1) {
        for (let index = 0; index < this.curIndex - 1; index++) {
          let width = this.navwidth[index]
          transformLeft -= width
        }
        if (transformLeft >= this.headerwidth - this.tabwidth) {
          this.transformLeft = transformLeft
        }
      }
    },
    setItemStyle(item, index) {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navActiveColor, otherColor } = this.value.data[this.curIndex] || {}
      const { slideritempadded: { paddedtb, paddedlr }, navitemHeight } = navsetting == 1 ? fixsetting : defaultsetting
      return {
        padding: `${paddedtb || 0}px ${paddedlr || 0}px`,
        backgroundColor: this.curIndex == index ? navActiveColor : otherColor,
        height: `${navitemHeight || 24}px`
      }

    },
  },
  computed: {
    setOutHeaderStyle() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navbgtype, navbgcolor, imgUrl, navpadded: { paddedt, paddedb, paddedl, paddedr } } = navsetting == 1 ? fixsetting : defaultsetting
      const position = this.value.fixedTop == 1 ? 'sticky' : 'static'
      let bgStyle = null
      //背景类型为颜色
      if (navbgtype == 0) {
        bgStyle = {
          'background-color': navbgcolor
        }
      }
      //背景类型为图片
      if (navbgtype == 1) {
        bgStyle = {
          'background-image': `url(${imgUrl})`,
          'background-size': '100% 100%',
          'background-position': 'center top',
          'background-repeat': 'no-repeat',
          'background-color': '#fff'
        }
      }
      return {
        position: position,
        padding: `${paddedt || 0}px ${paddedr || 0}px ${paddedb || 0}px ${paddedl || 0}px`,
        top: `0px`,
        ...bgStyle
      }
    },
    setInHeaderStyle() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navitemcolor, navitemHeight, navitemradius } = navsetting == 1 ? fixsetting : defaultsetting
      return {
        'background-color': navitemcolor,
        'border-radius': `${navitemradius || 0}px`,
        height: `${navitemHeight || 24}px`
        // 'width': `calc(100% - ${paddingLeft} - ${paddingRight})`
      }
    },
    setBgcolor() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navitemHeight } = navsetting == 1 ? fixsetting : defaultsetting
      return {
        backgroundColor: this.value.data[this.curIndex].otherColor,
        height: `${navitemHeight || 24}px`
      }
    },

    setNavClass() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navtype } = navsetting == 1 ? fixsetting : defaultsetting
      if (navtype == 0) {
        return 'header-img'
      } else {
        return 'header-img-0'
      }
    },
    setNavItemClass() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navtype } = navsetting == 1 ? fixsetting : defaultsetting
      if (navtype == 0) {
        return 'header-item'
      } else {
        return 'header-item-0'
      }
    },

    setStyleInner() {
      const { navsetting, fixsetting, defaultsetting } = this.value
      const { navtype } = navsetting == 1 ? fixsetting : defaultsetting
      if (navtype == 0) {
        return 'content-part_h-content_inner'
      } else {
        return 'content-part_h-content_inner-0'
      }

    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.transformLeft = 0
        this.curIndex = 0
      },
      deep: true,
      immediate: true
    },
  }
}
</script>