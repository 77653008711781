export const tabConfig = [
  {
    name: 'order',
    label: '订单、售后、维修',
    icon: 'el-icon-s-order',
    subTabs: [
      {
        name: 'normal_master_order',
        label: '实体主订单',
        permission: (login_type) => login_type !== 'admin' && login_type !== 'staff'
      },
      {
        name: 'normal_order',
        label: '实体子订单',
        permission: (login_type) => login_type !== 'admin' && login_type !== 'staff'
      },
      {
        name: 'pull_gift_order',
        label: '满赠订单信息',
        permission: (login_type) => login_type !== 'admin' && login_type !== 'staff'
      },
      {
        name: 'aftersale_record_count',
        label: '售后列表'
      },
      {
        name: 'refund_record_count',
        label: '退款单列表'
      },
      {
        name: 'capital',
        label: '资金流水',
        permission: (login_type) => login_type !== 'admin' && login_type !== 'staff'
      },
      {
        name: 'aftersales_material_video',
        label: '售后视频素材'
      },
      {
        name: 'repair_order_list',
        label: '维修单列表'
      },
      {
        name: 'questionnaire_submit_list',
        label: '问卷管理列表'
      },
      {
        name: 'risk_orders',
        label: '风险订单'
      },
      {
        name: 'distributor_repair_sessions',
        label: '门店作息管理'
      },
      {
        name: 'repair_distributor_session_change_log',
        label: '作息变更日志'
      }
    ]
  },
  {
    name: 'coupon',
    label: '卡券',
    icon: 'el-icon-s-ticket',
    subTabs: [
      {
        name: 'discountUser',
        label: '优惠券领取记录'
      },
      {
        name: 'couponUserDetail',
        label: '优惠券核销记录'
      },
      {
        name: 'coupon_list',
        label: '优惠券列表'
      },
      {
        name: 'dealer_coupon_list',
        label: '经销商优惠券列表'
      },
      {
        name: 'password_card',
        label: '口令卡生成日志'
      },
      {
        name: 'password_card_list',
        label: '口令卡明细'
      },
      {
        name: 'distributor_coupon_code_pool',
        label: '门店券券码列表'
      },
      {
        name: 'distributor_coupon_code_pool_statics',
        label: '门店券券码统计'
      }
    ]
  },
  {
    name: 'shop',
    label: '门店经销商',
    icon: 'el-icon-s-shop',
    subTabs: [
      {
        name: 'distributor_list',
        label: '店铺列表'
      },
      {
        name: 'dealer_export',
        label: '经销商列表'
      },
      {
        name: 'distributor_operators',
        label: '店铺账号导出'
      },
      {
        name: 'dealer_operators',
        label: '经销商账号导出'
      },
      {
        name: 'temporary_delivery_place',
        label: '代发货点列表'
      },
      {
        name: 'distributors_evaluation',
        label: '店铺评价'
      },
      {
        name: 'distributor_evaluation_list',
        label: '店铺评价明细'
      },
    ]
  },
  {
    name: 'promotion',
    label: '营销',
    icon: 'el-icon-s-promotion',
    subTabs: [
      {
        name: 'questionnaire_statistics',
        label: '问卷统计'
      },
      {
        name: 'questionnaire_detail',
        label: '问卷详情'
      },
      {
        name: 'questionnaire_submit_list',
        label: '问卷管理列表'
      },
      {
        name: 'activity_coupon_order_code',
        label: '平台导流兑换码'
      },
      {
        name: 'offline_activity_sign_up_log',
        label: '线下活动报名记录'
      },
      {
        name: 'offline_join_offline_activity_log',
        label: '线下体验记录'
      },
      {
        name: 'offline_activity_relation_sale_log',
        label: '线下活动搭售记录'
      },
      {
        name: 'offline_activity_distributors',
        label: '线下活动店铺'
      },
      {
        name: 'offline_activity_distributors_quota',
        label: '线下活动店铺名额'
      },
      {
        name: 'export_full_minus',
        label: '满减活动'
      },
      {
        name: 'export_lottery_gift',
        label: '抽奖赠品'
      },
      {
        name: 'export_lottery_gift_shop_stock',
        label: '赠品门店库存'
      },
      {
        name: 'lottery_record_list',
        label: '抽奖记录'
      },
      {
        name: 'lottery_winning_list',
        label: '中奖名单'
      },
      {
        name: 'export_lottery_shop_winning_limit',
        label: '门店中奖限额'
      },
      {
        name: 'material_detail',
        label: '物料明细'
      },
      {
        name: 'presale_activity_distributors',
        label: '新品发售门店'
      }
    ]
  },
  {
    name: 'goods',
    label: '商品',
    icon: 'el-icon-s-goods',
    subTabs: [
      {
        name: 'distributor_items',
        label: '店铺商品'
      },
      {
        name: 'items',
        label: '商品'
      },
      {
        name: 'items_recommend',
        label: '搭售商品'
      },
      {
        name: 'goods_data',
        label: '商品统计'
      },
      {
        name: 'goods_attribute_item_params',
        label: '商品参数'
      },
      {
        name: 'goods_attribute_brand',
        label: '商品品牌'
      },
      {
        name: 'drafts_items',
        label: '暂存商品'
      },
      {
        name: 'distributor_items_price_check_detail',
        label: '价格同步结果'
      }
    ]
  },
  {
    name: 'user',
    label: '会员',
    icon: 'el-icon-user-solid',
    subTabs: [
      {
        name: 'members_guarantee_list',
        label: '保修凭证'
      },
      {
        name: 'order_collection_static',
        label: '订单领取统计'
      }
    ]
  },
  {
    name: 'statistics',
    label: '统计/其他',
    icon: 'el-icon-s-data',
    subTabs: [
      {
        name: 'selfhandle_log',
        label: '自闭环日志'
      },
      {
        name: 'statisticslists_export',
        label: '来源统计'
      },
      {
        name: 'order_data_pos_export',
        label: 'pos统计'
      },
      {
        name: 'order_data_hq_export',
        label: 'hq统计'
      },
      {
        name: 'pii_api_log',
        label: 'PII调用日志'
      }
    ]
  },
  {
    name: 'safety',
    label: '安全',
    icon: 'el-icon-lock',
    subTabs: [
      {
        name: 'black_address',
        label: '黑名单'
      },
      {
        name: 'repair_white_list',
        label: '白名单'
      }
    ]
  }
]
