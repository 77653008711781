<style lang="scss">
.picker-link {
  .sp-filter-form {
    padding: 8px 8px 0px 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .sp-finder {
    &.no-multiple {
      .sp-finder-bd {
        .el-table__fixed-header-wrapper {
          table thead {
            tr {
              th {
                &:nth-child(1) {
                  .el-checkbox {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .el-table__fixed-body-wrapper {
        top: 38px !important;
      }
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-link">
    <!-- <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem prop="keywords">
        <el-input v-model="formData.keywords" placeholder="请输入页面名称" />
      </SpFilterFormItem>
    </SpFilterForm> -->
    <SpFinder ref="finder" :class="['shop-finder', { 'no-multiple': !multiple }]" :other-config="{
      height: 460
    }" :show-pager="false" :data="list" :fixed-row-action="true" :setting="{
      columns: [
        { name: 'ID', key: 'id', width: 200 },
        { name: '页面名称', key: 'title' }
      ]
    }" @select="onSelect" @selection-change="onSelectionChange" />
  </div>
</template>

<script>
import BasePicker from './base'
import PageMixin from '../mixins/page'
export default {
  name: 'PickerPages',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择页面'
  },
  props: ['value'],
  data() {
    return {
      formData: {
        keywords: ''
      },
      list: null,
      multiple: this.value?.multiple ?? true
    }
  },
  created() {
    const url = new URL(window.location.href);
    const params = Object.fromEntries(url.searchParams);
    if (this.value?.isDouyin) {
      this.list = [
        {
          title: '抖音小店',
          id: 'douyinStore'
        }, {
          title: '门店列表',
          id: 'wxstore'
        }, {
          title: '服务H5私教',
          id: 'serviceH5Coach'
        }, {
          title: '服务H5私教iPhone',
          id: 'serviceH5CoachiPhone'
        }, {
          title: '服务H5私教iPad',
          id: 'serviceH5CoachiPad'
        }, {
          title: '服务H5私教Mac',
          id: 'serviceH5CoachMac'
        }, {
          title: '服务H5私教Watch',
          id: 'serviceH5CoachWatch'
        }, {
          title: '服务H5公开课',
          id: 'serviceH5Classhour'
        }, {
          title: '活动路径-01',
          id: 'serviceH5active01'
        },
        {
          title: '活动路径-02',
          id: 'serviceH5active02'
        },
        {
          title: '活动路径-03',
          id: 'serviceH5active03'
        },
        {
          title: '活动路径-04',
          id: 'serviceH5active04'
        },
        {
          title: '活动路径-05',
          id: 'serviceH5active05'
        },
        {
          title: '活动路径-06',
          id: 'serviceH5active06'
        },
        {
          title: '活动路径-07',
          id: 'serviceH5active07'
        },
        {
          title: '活动路径-08',
          id: 'serviceH5active08'
        },
        {
          title: '活动路径-09',
          id: 'serviceH5active09'
        },
        {
          title: '活动路径-10',
          id: 'serviceH5active10'
        }
      ]
      if (this.system_mode !== 'standard') {
        this.list.push({
          title: '楼层引导',
          id: 'floorguide'
        })
      }
      if (this.value.wgtType === 'hotzone') {
        this.list.push({
          title: '抖音客服',
          id: 'douyinIm'
        },
          {
            title: '抖音直播',
            id: 'douyinLive'
          })
      }
    } else if (window.location.href.includes('customMessage_operation')) {
      this.list = [
        { title: '首页', id: 'indexPage' },
      ]
    } else {
      this.list = [{
        title: 'AR贺卡过渡页',
        id: 'ARBridgePage'
      }, {
        title: '会员开通',
        id: 'vipgrades'
      }, {
        title: '社区团购',
        id: 'grouppurchase'
      }, {
        title: '门店列表',
        id: 'wxstore'
      }, {
        title: '平台导流列表',
        id: 'platformlist'
      }, {
        title: '服务H5私教',
        id: 'serviceH5Coach'
      }, {
        title: '服务H5私教01',
        id: 'serviceH5CoachiPhone'
      }, {
        title: '服务H5私教02',
        id: 'serviceH5CoachiPad'
      }, {
        title: '服务H5私教03',
        id: 'serviceH5CoachMac'
      }, {
        title: '服务H5私教04',
        id: 'serviceH5CoachWatch'
      }, {
        title: '服务H5公开课',
        id: 'serviceH5Classhour'
      },

      {
        title: '服务H5维修售后',
        id: 'serviceH5Weixiufuwu'
      },
      {
        title: '服务H5新机设置',
        id: 'serviceH5Shezhifuwu'
      },
      {
        title: '我的门店活动',
        id: 'shopActivity'
      },
      {
        title: 'EC自定义页面',
        id: 'ecEustomPage'
      },
      {
        title: '活动路径-01',
        id: 'serviceH5active01'
      },
      {
        title: '活动路径-02',
        id: 'serviceH5active02'
      },
      {
        title: '活动路径-03',
        id: 'serviceH5active03'
      },
      {
        title: '活动路径-04',
        id: 'serviceH5active04'
      },
      {
        title: '活动路径-05',
        id: 'serviceH5active05'
      },
      {
        title: '活动路径-06',
        id: 'serviceH5active06'
      },
      {
        title: '活动路径-07',
        id: 'serviceH5active07'
      },
      {
        title: '活动路径-08',
        id: 'serviceH5active08'
      },
      {
        title: '活动路径-09',
        id: 'serviceH5active09'
      },
      {
        title: '活动路径-10',
        id: 'serviceH5active10'
      },

      {
        title: '活动路径-11',
        id: 'serviceH5active11'
      },
      {
        title: '活动路径-12',
        id: 'serviceH5active12'
      },
      {
        title: '活动路径-13',
        id: 'serviceH5active13'
      },
      {
        title: '活动路径-14',
        id: 'serviceH5active14'
      },
      {
        title: '活动路径-15',
        id: 'serviceH5active15'
      },
      {
        title: '活动路径-16',
        id: 'serviceH5active16'
      },
      {
        title: '活动路径-17',
        id: 'serviceH5active17'
      },
      {
        title: '活动路径-18',
        id: 'serviceH5active18'
      },
      {
        title: '活动路径-19',
        id: 'serviceH5active19'
      },
      {
        title: '活动路径-20',
        id: 'serviceH5active20'
      },


      ]
      if (params?.scene != 1013) {
        this.list.unshift({
          title: '会员权益',
          id: 'rights'
        })
      }
    }
  },
  mounted() {
    if (this.value.data) {
      const selectRows = this.list.filter((item) => this.value.data == item.id)
      const { finderTable } = this.$refs.finder.$refs
      setTimeout(() => {
        finderTable.$refs.finderTable.setSelection(selectRows)
      })
    }
  },
  methods: {
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (this.multiple) {
        // this.updateVal(selection)
      } else {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection([row])
          // this.updateVal([row])
        })
      }
    },
    onSelectionChange(selection) {
      this.updateVal(selection)
    }
  }
}
</script>
