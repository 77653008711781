<template>
  <div>
    <el-table :data="list">
      <el-table-column width="30">
      </el-table-column>
      <el-table-column
        prop="gift_after"
        width="250"
        label="申请商品"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-for="detail of scope.row.detail" :key="detail.detail_id">
            <div class="order-box">
              <el-tag v-if="scope.row.aftersales_order_type =='gift'">赠品</el-tag>
              <div class="order-image">
                <img :src="detail?.item_pic" alt="">
              </div>
              <div>
                <div class="order-name">
                  {{ detail?.item_name }}
                </div>
                <div class="order-price">
                  <span>实付：</span>
                  <span>￥{{ detail?.refund_fee / 100 }} </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="aftersales_bn"
        width="200"
        label="售后单号"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="order-num">
            {{ scope.row.aftersales_bn }}
            <el-tooltip effect="dark" content="复制" placement="top-start">
              <i
                v-clipboard:copy="scope.row.aftersales_bn"
                v-clipboard:success="onCopy"
                class="el-icon-document-copy"
              ></i>
            </el-tooltip>
          </div>
          <div class="order-time">
            <el-tooltip effect="dark" content="申请时间" placement="top-start">
              <i class="el-icon-time"></i>
            </el-tooltip>
            {{ scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200" label="订单" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="order-num">
            {{ scope.row.order_id }}
            <el-tooltip effect="dark" content="复制" placement="top-start">
              <i
                v-clipboard:copy="scope.row.order_id"
                v-clipboard:success="onCopy"
                class="el-icon-document-copy"
              ></i>
            </el-tooltip>
          </div>
          <div class="order-time" v-if="scope.row.ecid !== '' && scope.row.ecid != undefined">
            <el-tag size="mini" type="warning">员工购</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="所属店铺"
        property="shop_info"
      >
      </el-table-column>
      <el-table-column
        width="150"
        label="组织信息"
        property="info"
      >
      </el-table-column>
      <el-table-column
        width="150"
        label="收货人手机号"
        property="receiver_mobile"
      ></el-table-column>
      <el-table-column width="120" label="售后类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.aftersales_type === 'ONLY_REFUND'" type="info" size="mini"
            >仅退款</el-tag
          >
          <el-tag
            v-if="scope.row.aftersales_type === 'SEVEN_REFUND_GOODS'"
            type="info"
            size="mini"
            >7天无理由</el-tag
          >
          <el-tag v-if="scope.row.aftersales_type === 'REFUND_GOODS'" type="warning" size="mini"
            >7天质量问题</el-tag
          >
          <el-tag
            v-if="scope.row.aftersales_type === 'EXCHANGING_GOODS'"
            type="danger"
            size="mini"
            >15天内换货</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column width="120" label="售后状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.aftersales_status == '0'" size="mini">待处理</el-tag>
          <el-tag v-if="scope.row.aftersales_status == '1'" size="mini">处理中</el-tag>
          <el-tag v-if="scope.row.aftersales_status == '2'" type="success" size="mini"
            >已处理</el-tag
          >
          <el-tag v-if="scope.row.aftersales_status == '3'" type="success" size="mini"
            >已驳回</el-tag
          >
          <el-tag v-if="scope.row.aftersales_status == '4'" type="success" size="mini"
            >已关闭</el-tag
          >
          <el-tag v-if="scope.row.aftersales_status == '5'" type="success" size="mini"
            >已收货</el-tag
          >
          <el-tag v-if="scope.row.aftersales_status == '6'" type="success" size="mini"
            >强制关闭</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column minWidth="150" label="操作" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "subList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    onCopy() {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.order-num {
  font-size: 14px;
}
.order-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  .order-image {
    margin:0 8px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .order-price {
    display: flex;
  }
}
.org-info {
  font-size: 12px;
  color: #999;
}
::v-deep .el-table__header-wrapper {
  display: none;
}

</style>
