import {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtLift,
  
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtOfficialAccount

} from "../wgts/index";

export const initiWidgets = [
  // {
  //   name: "functionBlock",
  //   params: {
  //     name: "functionBlock",
  //     base: {
  //       wgtName: "功能区",
  //       wgtDesc: "",
  //       content: "member-header.png",
  //       type: "memberHeader",
  //     },
  //   },
  // },
  // {
  //   name: "functionBlock",
  //   params: {
  //     name: "functionBlock",
  //     base: {
  //       wgtName: "功能区",
  //       wgtDesc: "",
  //       content: "member-area1.png",
  //       type: "memberAreaOne",
  //     },
  //   },
  // },
  // {
  //   name: "functionBlock",
  //   params: {
  //     name: "functionBlock",
  //     base: {
  //       wgtName: "功能区",
  //       wgtDesc: "",
  //       content: "member-area2.png",
  //       type: "memberAreaTwo",
  //     },
  //   },
  // },
  // {
  //   name: "functionBlock",
  //   params: {
  //     name: "functionBlock",
  //     base: {
  //       wgtName: "功能区",
  //       wgtDesc: "",
  //       content: "member-area3.png",
  //       type: "memberAreaThree",
  //     },
  //   },
  // },
];

export const widgets = {
  WgtNewSlider,
  
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtLift,
  
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtOfficialAccount
  // WgtUserContainer
};

export default {
  widgets,
  initiWidgets,
};
