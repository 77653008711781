import { createSetting } from '@shopex/finder'


const REST_DAY_OPTIONS = [
  {label: '周一', value: 1},
  {label: '周二', value: 2},
  {label: '周三', value: 3},
  {label: '周四', value: 4},
  {label: '周五', value: 5},
  {label: '周六', value: 6},
  {label: '周日', value: 7}
]

const OP_TYPE_OPTIONS = [
  {label: '新增', value: 'insert'},
  {label: '更新', value: 'update'},
  {label: '删除', value: 'delete'},
]

const OP_METHOD_ENUM = {
  1: '后台修改',
  2: '导入修改'
}

const OPERATOR_TYPE_ENUM = {
  1: '店铺',
  2: '经销商',
  3: '运营'
}

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '添加时间',
      key: 'addTime',
      type: 'date-time-picker',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
    {
      name: '操作账号',
      key: 'operator_account',
    },
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
    {
      name: '归属经销商',
      key: 'dealer',
      slot: 'dealer'
    },
    {
      name: '作息日',
      key: 'session_week',
      type: 'select',
      multiple: true,
      options: REST_DAY_OPTIONS
    },
    {
      name: '操作类型',
      key: 'operate_type',
      type: 'select',
      options: OP_TYPE_OPTIONS
    },
  ],
  columns: [
    {
      name: '变更门店',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row?.distributorInfo?.name}
            </div>
            <div class="goods-code">
              <div>
                Apple ID：{row.shop_code}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '所属经销商',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row?.distributorInfo?.dealer_name}
            </div>
            <div class="goods-code">
              <div>
                HQID：{row?.distributorInfo?.dealer_hqid}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '变更作息日',
      key: 'session_week',
      width: 120,
      formatter: (v) => REST_DAY_OPTIONS.find((i) => i.value == v)?.label
    },
    {
      name: '操作类型',
      key: 'operate_type',
      minWidth: 120,
      formatter: (v) => OP_TYPE_OPTIONS.find((i) => i.value == v)?.label
    },
    {
      name: '操作时间',
      key: 'created',
      minWidth: 150,
    },
    {
      name: '操作方式',
      key: 'operate_source',
      minWidth: 120,
      formatter: (v) => OP_METHOD_ENUM[v]
    },
    {
      name: '操作账号',
      key: 'operator_account',
      minWidth: 150,
    },
    {
      name: '账号类型',
      key: 'operator_type',
      minWidth: 120,
      formatter: (v) => OPERATOR_TYPE_ENUM[v]
    },
    {
      name: '变更明细',
      key: 'change',
      minWidth: 120,
      render: (h, {row}) => {
        const changeDetails = []
        if (row.detail) {
          Object.keys(row.detail).map((key) => {
            const before = row.detail[key].before
            const after = row.detail[key].after
            changeDetails.push({ label: key, before, after })
          })
        }
        return (
          <el-popover
            placement="top-start"
            width="400"
            trigger="click">
            <div class="change-detail-container">
              <div class="change-detail-header">
                <span class="header-cell"></span>
                <span class="header-cell">变更前内容</span>
                <span class="header-cell">变更后内容</span>
              </div>
              {changeDetails.map(item => (
                <div class="change-detail-row">
                  <span class="label">{item.label}:</span>
                  <span class="content">{item.before}</span>
                  <span class="content">{item.after}</span>
                </div>
              ))}
            </div>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        )
      }
    },
  ],
  actions: [
    {
      name: '导出报表',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    }
  ]
})
