<style lang="scss" scoped>
.content-part-nav-item {
  margin-left: -80px;

  .cover-tips {
    display: block;
    font-size: 10px;
    text-align: center;
    width: calc(100% - 10px);
  }

  .cover-color {
    font-size: 10px;

  }

  .scope-header {
    display: flex;

    &-item {}
  }

  .color-cell {
    display: flex;
    align-items: center;
    padding-top: 2px;
  }
}
</style>
<style lang="scss">
.content-part-nav-item {
  .item-bd {
    display: flex;
  }

  .placeholder,
  .image-item {
    width: 60px !important;
    height: 60px !important;

    .add-text {
      display: none;
    }

    .image-meta {
      font-size: 10px;
    }
  }
}
</style>


<template>
  <div class="content-part-nav-item">
    <CompDragList v-model="localValue" @onAddItem="handleAddNavItem" type="text" btnText="添加导航项" :show-title="true"
      title="导航项">
      <template slot="body" slot-scope="scope">
        <div class="scope-header">
          <div class="scope-header-item">
            <SpImagePicker v-model="localValue[scope.index].navdefaultIcon" size="small" text="" />
            <span class="cover-tips">默认图标</span>
          </div>
          <div class="scope-header-item">
            <SpImagePicker v-model="localValue[scope.index].navActiveIcon" size="small" text="" />
            <span class="cover-tips">选中图标</span>
          </div>
        </div>
        <div>
          <div @click="handleSetLink(scope.index)" class="scope-content">
            <el-button v-if="!localValue[scope.index]?.cusTom?.pages_template_id" type="text"
              size="mini">请选择分区模块</el-button>
            <p v-else>
              <el-button type="text" size="mini" style="display: inline;">【{{
                localValue[scope.index].cusTom.pages_template_id }}】{{ localValue[scope.index].cusTom.template_title
                }}</el-button>
              <el-button type="text" size="mini" style="display: inline;" class="el-icon-delete"
                @click.stop="handledelLink(scope.index)"></el-button>
            </p>
          </div>
          <div class='color-cell'>
            <el-input v-model='localValue[scope.index].navItemName' size="mini" placeholder="导航项名称"/>
            </div>
          <div class='color-cell'>
            <div>
              <el-color-picker v-model='localValue[scope.index].navActiveColor' size="mini" />
              <span class="cover-color">选中颜色</span>
            </div>
            <div>
              <el-color-picker v-model='localValue[scope.index].otherColor' size="mini" />
              <span class="cover-color">其它颜色</span>
            </div>
          </div>
        </div>

      </template>
    </CompDragList>
  </div>
</template>

<script>
import { cloneDeep, throttle } from 'lodash'
import CompDragList from '../../comps/comp-dragList'
import { gWgts } from '../../scene'
import { transformSelectTem, getWgts } from "../../utils"
export default {
  name: "nav-item",
  components: { CompDragList },
  props: {
    value: Array,
    // 组件唯一标识
    uuid: String
  },
  data() {
    return {
      //
      localValue: []
    }
  },
  methods: {
    handleAddNavItem() {
      this.localValue.push({
        navItemName: '',//导航项名称
        navdefaultIcon: '',//导航项默认图标
        navActiveIcon: '',//导航项选中图标
        navdefaultColor: '',
        navActiveColor: '',

        cusTom: null
      })
    },
    async handleSetLink(index) {
      const { scene = '1001' } = this.$route.query
      const { pages_template_id: tid = '' } = this.localValue[[index]].cusTom || {}
      let params = {
        multiple: false,
        data: tid ? [tid] : [],
        isContentpart: true
      }
      const { data } = await this.$picker.customWgt(params)
      const title = data[0].template_title
      const id = data[0].pages_template_id
      const widgets = getWgts(scene)
      const value = await transformSelectTem(data, widgets)
      this.$set(this.localValue[index], 'cusTom', ...value)
    },
    async handledelLink(index) {
      this.$set(this.localValue[index], 'cusTom', null)
    },
  },
  watch: {
    uuid: {
      handler(newVal, oldVal) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    },
    localValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  }

}
</script>
