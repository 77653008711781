<template>
  <el-form ref="form" :model="form" class="box-set" label-width="120px">
    <el-card header="基础信息" shadow="naver">
      <el-form-item
        label="名称"
        prop="marketing_name"
        :rules="{ required: true, message: '活动名称必填', trigger: 'blur' }"
      >
        <el-col :span="20">
          <el-input
            :disabled="form.status == 'waiting' ? false : true"
            v-model="form.marketing_name"
            :maxlength="30"
            placeholder="最多30个字"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        label="活动描述"
        prop="marketing_info"
        :rules="{ required: true, message: '活动描述必填', trigger: 'blur' }"
      >
        <el-col :span="20">
          <el-input
            :disabled="form.status == 'waiting' ? false : true"
            v-model="form.marketing_info"
            :maxlength="25"
            placeholder="最多25个字"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="促销规则">
        <el-row :gutter="1">
          <el-col :span="7">
            <el-radio
              :disabled="form.status == 'waiting' ? false : true"
              v-model="form.condition_type"
              label="quantity"
              >按总件数</el-radio
            >
            <el-radio
              :disabled="form.status == 'waiting' ? false : true"
              v-model="form.condition_type"
              label="totalfee"
              >按总金额</el-radio
            >
          </el-col>
          <el-col :span="6">
            <span
              v-if="form.condition_type == 'totalfee'"
              v-for="(item, key) in conditionValue"
              :key="key"
              >消费满
              <el-input
                :disabled="form.status == 'waiting' ? false : true"
                type="input"
                v-model="item.full"
                placeholder="0.00"
                style="width: 100px"
                size="mini"
              ></el-input
              >元，送赠品
            </span>
            <span
              v-if="form.condition_type == 'quantity'"
              v-for="(item, key) in conditionValue"
              :key="key"
              >消费满
              <el-input
                :disabled=true
                type="input"
                v-model="item.full"
                placeholder=""
                style="width: 100px"
                size="mini"
              ></el-input
              >件，送赠品
            </span>
          </el-col>
          <el-col>
            <el-checkbox
              :disabled="form.status == 'waiting' ? false : true"
              v-model="form.canjoin_repeat"
              >上不封顶（以当前设置的条件，消费每满（X元/件）*n个，送n个赠品）</el-checkbox
            >
          </el-col>
        </el-row>
        <div>
<!--          <el-button-->
<!--            type="primary"-->
<!--            class="el-icon-plus"-->
<!--            @click="relGiftsClick"-->
<!--            size="mini"-->
<!--            round-->
<!--            >选赠品</el-button-->
<!--          >-->
          <el-button  v-if="hasSaveButton"
            type="primary"
            class="el-icon-plus"
            @click="relGiftsCouponClick"
            size="mini"
            round
            >选优惠券</el-button
          >

          <el-divider></el-divider>
          <el-tag v-if="relGifts.length > 0">商品：</el-tag>

          <el-table
            v-if="relGifts.length > 0"
            :data="relGifts"
            style="line-height: normal"
          >
            <el-table-column
              label="ID"
              prop="item_id"
              width="60"
            ></el-table-column>
            <el-table-column label="名称" prop="item_name"></el-table-column>
            <el-table-column
              label="规格"
              prop="item_spec_desc"
            ></el-table-column>
            <el-table-column label="赠品数量" width="90">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.gift_num"
                  width="90"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column label="其他配置" width="200">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.without_return">退货无需退回赠品</el-checkbox>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="50">
              <template slot-scope="scope">
                <i
                  class="iconfont icon-trash-alt"
                  @click="deleteGiftRow(scope.$index)"
                ></i>
              </template>
            </el-table-column>
          </el-table>

          <el-divider v-if="couponList.length > 0"></el-divider>
          <el-tag v-if="couponList.length > 0">优惠券：</el-tag>

          <el-table
            v-if="couponList.length > 0"
            :data="couponList"
            style="line-height: normal"
          >
            <el-table-column label="ID" prop="id" width="60"></el-table-column>
            <el-table-column label="名称" prop="title"></el-table-column>
            <el-table-column label="优惠券赠送数量">
              <template slot-scope="scope">
                <el-input type="number"  v-if="hasSaveButton"
                  :min="0"
                  :max="scope.row.max_num"
                  v-model = "scope.row.gift_num"
                  width="90"
                  size="mini"
                  @change="maxNumChange(scope,scope.row.gift_num,scope.row.max_num)"
                ></el-input>
                <el-input type="number"  v-if="!hasSaveButton" readonly
                          :min="0"
                          :max="scope.row.max_num"
                          v-model = "scope.row.gift_num"
                          width="90"
                          size="mini"
                          @change="maxNumChange(scope,scope.row.gift_num,scope.row.max_num)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="优惠券限领数量" prop="max_num"></el-table-column>
            <el-table-column label="操作" width="50"  v-if="hasSaveButton">
              <template slot-scope="scope">
                <i
                  class="iconfont icon-trash-alt"
                  @click="deleteGiftCouponRow(scope.$index)"
                ></i>
              </template>
            </el-table-column>
          </el-table>

          <GoodsSelect
            unwantedGift
            :items-visible="giftVisible"
            :get-status="setGiftStatus"
            :rel-items-ids="relGifts"
            is-spec
            :item-type="form.item_type"
            @chooseStore="chooseGiftsAction"
            @closeStoreDialog="closeGiftsDialogAction"
          ></GoodsSelect>
          <CouponPicker
            unwantedGift
            :visible="giftCouponVisible"
            :statusIn="true"
            :searchFirstOrder = false
            :get-status="setGiftCouponStatus"
            :rel-items-ids="couponList"
            receive="condition"
            is-spec
            @pickCoupon="pickCouponAction"
            @closeDialog="pickCouponDialogAction"
          ></CouponPicker>
        </div>
      </el-form-item>
      <el-form-item label="活动标签">
        <el-row :gutter="1">
          <el-col :span="7">
            <el-radio
              v-model="form.show_full_tag"
              :label=true
              >开启</el-radio
            >
            <el-radio
              v-model="form.show_full_tag"
              :label=false
              >关闭</el-radio
            >
          </el-col>
          <el-col :span="7">
            <span v-if="form.show_full_tag === true"> 标签内容：
              <el-input type="input"
                v-model="form.full_tag"
                placeholder="标签内容"
                :maxlength="9"
                style="width: 200px"
                size="mini"
              ></el-input>
            </span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="规则描述">
        <el-col :span="20">
          <el-input
            :disabled="form.status == 'waiting' ? false : true"
            type="textarea"
            :rows="3"
            v-model="form.marketing_desc"
            :maxlength="200"
            placeholder="规则描述，最多200个字"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="可参与次数">
        <el-col :span="20">
          <el-input
            :disabled="form.status == 'waiting' ? false : true"
            type="number"
            v-model="form.join_limit"
            placeholder="整数数字"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="发放日期">
        <el-col :span="20">
          签收/核销后<el-input
          :disabled="form.status == 'waiting' ? false : true"
          type="number"
          v-model="form.delayed_day"
          placeholder="整数数字"
          style="width: 100px"
          :min="0"
          size="mini"
        ></el-input>天，进行发放
        </el-col>
      </el-form-item>
<!--      <el-form-item label="适用会员">-->
<!--        <el-checkbox-group-->
<!--          :disabled="form.status == 'waiting' ? false : true"-->
<!--          v-model="validGrade"-->
<!--        >-->
<!--          <el-checkbox-->
<!--            v-for="grade in memberGrade"-->
<!--            :label="grade.grade_id"-->
<!--            :key="grade.grade_id"-->
<!--            >{{ grade.grade_name }}</el-checkbox-->
<!--          >-->
<!--          <el-checkbox-->
<!--            v-for="vipdata in vipGrade"-->
<!--            :label="vipdata.lv_type"-->
<!--            :key="vipdata.lv_type"-->
<!--            >付费{{ vipdata.grade_name }}</el-checkbox-->
<!--          >-->
<!--        </el-checkbox-group>-->
<!--      </el-form-item>-->
      <el-form-item label="适用平台">
        <el-radio-group v-model="form.used_platform">
          <el-radio :label="0">全场可用</el-radio>
          <!-- <el-radio :label="1">只用于pc端</el-radio>
          <el-radio :label="2">只用于小程序端</el-radio>
          <el-radio :label="3">只用于h5端</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="有效期">
        <el-col :span="20">
          <el-date-picker
            :disabled="form.status == 'waiting' ? false : true"
            v-model="activity_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="生效时间"
            end-placeholder="过期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item label="教育优惠参数">
        <el-col :span="20">
          <el-input
            class="input-m"
            :disabled="form.status == 'waiting' ? false : true"
            v-model="form.edu_param"
          >
          </el-input>
        </el-col>
      </el-form-item>
    </el-card>
    <el-card header="显示类型【用于订单详情展示】"  shadow="naver">
      <el-form-item label="显示类型">
        <el-radio-group v-model="form.show_type" @change="showTypeChange">
          <el-radio label="info">满赠信息</el-radio>
          <el-radio label="img">图片信息</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="form.show_type === 'img'">
        <el-row>
          <el-col :span="8">
            <div >
              <div @click="handleImgChange" class="upload-box">
                <img v-if="form.show_img" :src="wximageurl + form.show_img" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
            <div class="frm-tips">
              <p>最多可上传1个图片，大小不超过2M</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div >
              <span v-if="!customPageVisible && selectCustomPageRow.page_name">
                <div class="tag-group">
                  <span class="tag-group__title">自定义页面：</span>
                 <el-tag type="success"  effect="plain" closable @close="handleClose()">{{selectCustomPageRow.page_name}}</el-tag><br><br>
<!--                  <span class="frm-tips">pages/custom/custom-page?id={{selectCustomPageRow.id}}</span>-->
                </div>
              </span>
              <span class="frm-tips" v-if="!customPageVisible && !selectCustomPageRow.page_name">
                请点击下方按钮进行自定义页面选择。<br><br>
              </span>
              <br>
              <el-button @click="customPageVisible = true" size="mini" type="primary">选择自定义页面</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card header="选择商品" shadow="naver"   >
      <el-form-item label="适用商品">
        <el-radio-group v-model="form.use_bound" @change="itemTypeChange" :disabled="!hasSaveButton">
          <el-radio label="all">全部商品适用</el-radio>
          <el-radio label="goods">指定商品适用</el-radio>
<!--          <el-radio label="category">指定分类适用</el-radio>-->
          <!--          <el-radio label="tag">指定商品标签适用</el-radio>-->
          <!--          <el-radio label="brand">指定品牌适用</el-radio>-->
        </el-radio-group>

<!--        <el-alert  v-if="!hasSaveButton"  title="此选项请勿修改！" type="warning" :closable="false" show-icon></el-alert>-->
      </el-form-item>
      <div v-if="!zdItemHidden" style="position: relative">
        <SkuSelector @change="getItems" :data="relItems" :canEdit="hasSaveButton" ></SkuSelector>
        <div style="position: absolute; bottom: 0px; left: 112px"  >
          <el-upload  :disabled="!hasSaveButton"
            style="display: inline-block; height: 0"
            action=""
            :on-change="uploadHandleChange"
            :auto-upload="false"
            :show-file-list="false"
          >
            <el-button type="primary" :disabled="!hasSaveButton" >批量上传</el-button>
          </el-upload>
          <el-button :disabled="!hasSaveButton"
            style="margin-left: 10px"
            type="primary"
            @click="uploadHandleTemplate()"
            >下载模板</el-button
          >
        </div>
      </div>
      <el-col :xs="12" :sm="12" :md="12">
        <div style="height: 350px" v-if="!categoryHidden">
          <treeselect
            :options="categoryList"
            :show-count="true"
            :multiple="true"
            :disable-branch-nodes="true"
            :clearable="false"
            v-model="form.item_category"
            @select="categorySelect"
            @deselect="categoryDeselect"
          >
          </treeselect>
        </div>
      </el-col>
      <template v-if="!tagHidden">
        <div class="selected-tags view-flex">
          <div class="label">已选中标签：</div>
          <div class="view-flex-item">
            <el-tag
              :key="index"
              v-for="(tag, index) in tag.currentTags"
              closable
              size="small"
              :disable-transitions="false"
              @close="tagRemove(index)"
            >
              {{ tag.tag_name }}
            </el-tag>
          </div>
        </div>
        <div>
          <el-tag
            class="tag-item"
            :key="index"
            v-for="(tag, index) in tag.tags"
            size="medium"
            color="#ffffff"
            :disable-transitions="false"
            @click.native="tagAdd(tag, index)"
          >
            {{ tag.tag_name }}
          </el-tag>
        </div>
      </template>
      <template v-if="!brandHidden">
        <div class="selected-tags view-flex">
          <div class="label">已选中品牌：</div>
          <div class="view-flex-item">
            <el-tag
              :key="index"
              v-for="(brand, index) in brand.currentBrands"
              closable
              size="small"
              :disable-transitions="false"
              @close="brandRemove(index)"
            >
              {{ brand.attribute_name }}
            </el-tag>
          </div>
        </div>
        <div>
          <el-tag
            class="tag-item"
            :key="index"
            v-for="(brand, index) in brand.brands"
            size="medium"
            color="#ffffff"
            :disable-transitions="false"
            @click.native="brandAdd(brand, index)"
          >
            {{ brand.attribute_name }}
          </el-tag>
        </div>
      </template>
    </el-card>

    <el-card
      header="绑定店铺【经销商】"
      shadow="naver"
      v-if="system_mode !== 'platform' && !is_distributor"
    >
      <el-button type="primary" @click="relStoresClick(0)">选店铺</el-button>
      <el-button type="primary" @click="relStoresClick(1)">选经销商</el-button>

      <span v-if="form.use_shop == 1">【当前选择：店铺维度】</span>
      <span v-if="form.use_shop == 2">【当前选择：经销商维度】</span>
      <el-table
        v-if="relStores.length > 0 && !form.marketing_id"
        :data="relStores"
        style="line-height: normal"
      >
        <el-table-column
          label="ID"
          prop="distributor_id"
          width="60"
        ></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <i
              class="iconfont icon-trash-alt"
              @click="deleteStoreRow(scope.$index, form.items)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="relDealers.length > 0 && !form.marketing_id" :data="relDealers">
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column
          label="经销商hqid"
          prop="dealer_hqid"
        ></el-table-column>
        <el-table-column
          prop="distributor_hqid"
          label="门店hqid"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="分销商vad"
          prop="distributor_vad"
        ></el-table-column>
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <i
              class="iconfont icon-trash-alt"
              @click="deleteRow(scope.$index, form.items)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <StoreSelect
        :store-visible="storeVisible"
        :is-valid="true"
        :rel-data-ids="relStores"
        :get-status="setStatus"
        @chooseStore="chooseStoreAction"
        @closeStoreDialog="closeStoreDialogAction"
        :typeStores="typeStores"
        :set-page-size="100"
        :distributor-valid="'true,false,delete'"
      ></StoreSelect>
      <DealerSelect
        :store-visible="DealerVisible"
        :is-valid="isValid"
        :rel-data-ids="relDealers"
        :get-status="DealerStatus"
        @chooseStore="handleDealerSelected"
        @closeStoreDialog="handleCloseDealer"
      ></DealerSelect>
    </el-card>
<!--    <div class="content-center">
      <el-button
        type="primary"
        v-if="hasSaveButton"
        @click="submitActivityAction()"
        >保存</el-button
      >  -->
      <div class="content-center">
      <el-button
        type="primary"
        :loading="submitLoading"
        @click="submitActivityAction()"
        >保存</el-button
      >
      <el-button @click.native="handleCancel">返回</el-button>
    </div>
    <el-dialog
      title="选择自定义页面"
      :visible.sync="customPageVisible"
      width="60%">
      <el-table
        v-loading="customPageLoading"
        highlight-current-row
        :data="customPageList"
        @current-change="changeCustomPageSelect">
   <el-table-column
          prop="id"
          label="页面id"
          width="110px"
        >
     <template slot-scope="scope">
       <el-radio v-model="page_radio" :label="scope.row.id"></el-radio>
     </template>
   </el-table-column>
        <el-table-column prop="page_name" label="页面名称"></el-table-column>
        <el-table-column label="审核状态" width="150px">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.examine_status == '1'"
            >待提交</el-tag
            >
            <el-tag v-else-if="scope.row.examine_status == '2'">审核中</el-tag>
            <el-tag type="danger" v-else-if="scope.row.examine_status == '3'"
            >审核未通过</el-tag
            >
            <el-tag type="success" v-else>审核通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.is_open == '0'">禁用</el-tag>
            <el-tag type="success" v-else>启用</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="customPageTotalCount > customPageParams.pageSize"
        class="content-padded content-center"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCustomPageCurrentChange"
          :current-page.sync="customPageParams.page"
          :total="customPageTotalCount"
          :page-size="customPageParams.pageSize"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="customPageVisible = false">取 消</el-button>
          <el-button type="primary" @click="chooseCustomPage">确 定</el-button>
        </span>
    </el-dialog>
  </el-form>

</template>

<script>
import { getCustomPageList } from '@/api/wxa'
import imgPicker from '@/components/imageselect'
import Treeselect from '@riophae/vue-treeselect'
import { addMarketingActivity, updateMarketingActivity, getMarketingActivityInfo, seckillActivityGetItemsList } from '../../../../api/promotions'
import { getGradeList } from '../../../../api/membercard'
import { listVipGrade } from '../../../../api/cardticket'
import StoreSelect from '@/components/storeListSelect'
import GoodsSelect from '@/components/goodsSelect'
import CouponPicker from '@/components/coupon_picker'
import SkuSelector from '@/components/function/skuSelector'
import DealerSelect from '@/components/function/dealerSelect'

import { getItemsList, getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import { handleUploadFile, exportUploadTemplate } from '../../../../api/common'
import store from '@/store'
export default {
  inject: ['refresh'],
  components: {
    GoodsSelect,
    CouponPicker,
    StoreSelect,
    SkuSelector,
    Treeselect,
    DealerSelect,
    imgPicker
  },
  data () {
    return {
      page_radio: '',
      customPageLoading: false,
      customPageTotalCount: 0,
      customPageVisible: false,
      customPageParams: {
        page: 1,
        pageSize: 10
      },
      isValid: true,
      DealerStatus: false,
      relDealers: [],
      DealerVisible: false,
      is_distributor: false,
      cursymbol: '￥',
      storeVisible: false,
      relItems: [],
      relStores: [],
      typeStores: null,
      setStatus: false,
      imgsVisible:false,
      form: {
        show_img:'',
        edu_param: '',
        marketing_id: '',
        marketing_type: 'full_gift',
        marketing_name: '',
        marketing_desc: '',
        marketing_info: '',
        delayed_day: 0,
        start_time: '',
        end_time: '',
        used_platform: 0,
        use_bound: 'goods',
        show_type: 'info',
        use_shop: 0,
        shop_ids: [],
        item_ids: [],
        item_type: 'normal',
        item_type_coupon: 'coupon',
        valid_grade: [],
        condition_type: 'quantity',
        condition_value: [],
        join_limit: 0,
        promotion_tag: '满赠',
        canjoin_repeat: false,
        status: 'waiting',
        show_full_tag: false,
        full_tag: '',
        item_category: [],
        tag_ids: [],
        brand_ids: [],
        in_proportion: false,
        relDealers: [],
        page_info: {
          page_name: "",
          page_path: ""
        }
      },
      selectCustomPageRow: {},
      vipGrade: [],
      memberGrade: [],
      conditionValue: [
        { full: '1' }
      ],
      validGrade: [],
      activity_date: '',
      selectItemType: 'normal',
      itemVisible: false,
      setItemStatus: false,
      imgDialog: false,
      isGetImage: false,
      isIndeterminate: true,
      checkAll: false,
      relGifts: [],
      setGiftStatus: false,
      setGiftCouponStatus: false,
      giftVisible: false,
      giftCouponVisible: false,
      giftType: '',
      hasSaveButton: true,
      zdItemHidden: false,
      categoryHidden: true,
      couponList: [],
      categoryList: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: []
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: []
        },
        currentBrands: [],
        brands: []
      },
      ItemsList: [],
      customPageList: [],
      invalidItemsList: [],
      params: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        templates_id: '',
        keywords: '',
        category: 0,
        is_warning: false,
        tag_id: '',
        is_gift: 'all',
        type: 0,
        is_sku: 'true',
        item_id: ''
      },
      submitLoading: false,
    }
  },
  watch: {
    'form.item_ids': {
      handler: function (val) {
        if (val) {
          // this.form.use_bound = val.length > 0 ? 1 : 0
        }
      }
    },
    'form.shop_ids': {
      handler: function (val) {
        if (val) {
          console.log('shop_ids', this.form.use_shop)
          if (this.form.use_shop !== 2) {
            this.form.use_shop = val.length > 0 ? 1 : 0
          }
        }
      }
    }
  },
  methods: {
    handleClose(){
      this.form.page_info.page_name =""
      this.form.page_info.page_path =""
      this.selectCustomPageRow = {}
    },
    changeCustomPageSelect(row) {
      this.selectCustomPageRow = row
      this.page_radio =  this.selectCustomPageRow.id
    },
    chooseCustomPage() {
      this.customPageVisible = false
      this.form.page_info.page_name = this.selectCustomPageRow.page_name
      this.form.page_info.page_path = 'pages/custom/custom-page?id=' + this.selectCustomPageRow.id
      console.log(this.form.page_info)
    },
    getCustomPageList() {
      this.customPageLoading = true
      let params = JSON.parse(JSON.stringify(this.customPageParams))
      getCustomPageList (params) .then (response => {
        this.customPageList = response.data.data.list
        this.customPageTotalCount = response.data.data.total_count
        this.customPageLoading = false
      }) . catch (error => {
        this.customPageLoading = false
      })
    },
    handleCustomPageCurrentChange (page_num) {
      this.customPageParams.page = page_num
      this.getCustomPageList()
    },
    showTypeChange: function (val) {
      this.form.show_type = val
    },
    closeImgDialog () {
      this.imgDialog = false
    },
    handleImgChange () {
      this.imgDialog = true
      this.isGetImage = true
    },
    pickImg (data) {
      this.form.show_img = data.url
      this.imgDialog = false
    },
    getItems (data) {
      let ids = []
      data.forEach(item => {
        ids.push(item.itemId)
      })
      this.form.item_ids = ids
    },
    addRules () {
      this.conditionValue.push({ full: '' })
    },
    delRules (index) {
      this.conditionValue.splice(index, 1)
    },
    relStoresClick (type) {
      if (type === 1) {
        this.relStores = []
        this.DealerStatus = true
        this.DealerVisible = true
      } else {
        this.relDealers = []
        this.storeVisible = true
        this.setStatus = true
      }
    },
    chooseStoreAction (data) {
      this.relDealers = []
      this.storeVisible = false
      this.form.shop_ids = []
      if (data === null || data.length <= 0) return
      this.relStores = data
    },
    closeStoreDialogAction (data) {
      this.storeVisible = false
    },
    handleCloseDealer () {
      this.DealerVisible = false
    },
    handleDealerSelected (dealerlist) {
      this.relStores = []
      this.DealerVisible = false
      this.form.shop_ids = []
      if (dealerlist === null || dealerlist.length <= 0) return
      this.relDealers = dealerlist
      // dealerlist.forEach(item => {
      //   this.relDealers.push(item)
      // })
      // this.relDealers = dealerlist
      console.log('dealerlist', this.relDealers)
    },
    deleteStoreRow (index) {
      this.setStatus = false
      this.relStores.splice(index, 1)
    },
    deleteRow (index) {
      this.setStatus = false
      this.relDealers.splice(index, 1)
      // this.form.rel_shops_ids = []
      let shopDatas = this.relDealers
      for (var i = 0; i < shopDatas.length; i++) {
        if (this.form.rel_shops_ids.indexOf(Number(shopDatas[i].wxShopId)) < 0) {
          this.form.rel_shops_ids.push(Number(shopDatas[i].wxShopId))
        }
      }
    },
    submitActivityAction () {
      try {
        const that = this
        let thisform = JSON.stringify(this.form)
        thisform = JSON.parse(thisform)
        if (this.activity_date.length > 0) {
          thisform.start_time = this.activity_date[0]
          thisform.end_time = this.activity_date[1]
        }

        if (this.conditionValue.length > 0) {
          thisform.condition_value = JSON.stringify(this.conditionValue)
        }
        if (this.form.delayed_day < 0) {
          this.$message.error('延迟发送天数不能小于0!')
          return false
        }
        if (this.form.show_full_tag  === false) {
          this.form.full_tag = ''
        }
        if (this.form.show_full_tag  === true && !this.form.full_tag) {
          this.$message.error('标签内容不能为空!')
          return false
        }
        if (this.validGrade.length > 0) {
          thisform.valid_grade = this.validGrade
        }
        if (this.relDealers.length > 0) {
          thisform.relDealers = this.relDealers
        }
        this.form.shop_ids = []
        if (this.relStores && this.relStores.length > 0) {
          this.relStores.forEach(item => {
            this.form.shop_ids.push(item.distributor_id)
          })
        }
        if (this.relDealers && this.relDealers.length > 0) {
          this.form.use_shop = 2
          thisform.use_shop = 2
          thisform.bind_type = 'dealer'
          this.relDealers.forEach(item => {
            this.form.shop_ids.push(item.dealer_id)
          })
        }

        if (this.relGifts.length <= 0 && this.couponList.length <= 0) {
          this.$message.error('请选择赠品!')
          return false
        }
        let giftData = []
        this.relGifts.forEach(item => {
          console.log(item)
          if (item.gift_num > item.max_num) {
            this.$message.error('优惠券数量超过限制【<='+item.max_num+'】!')
            return false
          }
          let itemdata = {
            item_id: item.item_id,
            item_name: item.item_name,
            gift_type: 'items',
            price: 0,
            without_return: item.without_return,
            gift_num: item.gift_num,
            pics: item.pics,
            filter_ful: this.conditionValue[0].full
          }
          giftData.push(itemdata)
        })
        this.couponList.forEach(card => {
          let coupondata = {
            item_id: card.id,
            item_name: card.title,
            gift_type: 'coupon',
            price: 0,
            without_return: '',
            gift_num: card.gift_num,
            pics: '',
            filter_ful: this.conditionValue[0].full,
            max_num: card.max_num
          }
          giftData.push(coupondata)
        })
        thisform.gifts = JSON.stringify(giftData)

        if (thisform.use_bound == 'all') {
          this.use_bound = 0
        } else {
          if (thisform.item_ids && thisform.item_ids.length <= 0) {
            this.$message.error('参加活动的商品必填!')
            return false
          }
          this.use_bound = 1
        }
        if (this.form.show_type !== 'info' && !this.form.show_img) {
          this.$message.error('请选择显示的图片!')
          return false
        }

        delete thisform.relDealers
        delete thisform.relStores
        thisform.item_ids = JSON.stringify(thisform.item_ids)
        thisform.shop_ids = JSON.stringify(this.form.shop_ids)
        if (this.form.marketing_id) {
          console.log('thisform', thisform)
          this.submitLoading = true
          updateMarketingActivity(thisform).then(res => {
            if (res.data.data.marketing_id) {
              this.$message({
                message: '更新成功',
                type: 'success',
                duration: 2 * 1000,
                onClose () {
                  that.refresh()
                  that.$router.go(-1)
                  that.submitLoading = false
                }
              })
            } else {
              this.$message.error('保存失败!')
              this.submitLoading = false
              return false
            }
          }).catch(() => {
            this.submitLoading = false
          })
        } else {
          this.submitLoading = true
          addMarketingActivity(thisform).then(res => {
            if (res.data.data.marketing_id) {
              this.$message({
                message: '添加成功',
                type: 'success',
                duration: 2 * 1000,
                onClose () {
                  that.refresh()
                  that.$router.go(-1)
                  that.submitLoading = false
                }
              })
            } else {
              this.$message.error('保存失败!')
              this.submitLoading = false
              return false
            }
          }).catch(() => {
            this.submitLoading = false
          })
        }

      } catch (e) {
        this.submitLoading = false
      }
    },
    getActivityDetail (id) {
      getMarketingActivityInfo({ marketing_id: id }).then(res => {
        let response = res.data.data
        console.log(response)
        let data = {
          marketing_type: 'full_gift',
          promotion_tag: '满赠',
          marketing_id: response.marketing_id,
          marketing_name: response.marketing_name,
          marketing_desc: response.marketing_desc,
          marketing_info: response.marketing_info,
          delayed_day: response.delayed_day,
          start_time: response.start_time,
          end_time: response.end_time,
          used_platform: response.used_platform,
          use_bound: response.use_bound,
          use_shop: response.use_shop,
          valid_grade: response.valid_grade,
          condition_type: response.condition_type,
          condition_value: response.condition_value,
          join_limit: response.join_limit,
          item_type: response.item_type,
          canjoin_repeat: response.canjoin_repeat,
          status: response.status,
          item_category: response.item_category,
          tag_ids: response.tag_ids,
          tag_list: response.tag_list,
          brand_ids: response.brand_ids,
          brand_list: response.brand_list,
          rel_brand_ids: response.rel_brand_ids,
          rel_category_ids: response.rel_category_ids,
          rel_tag_ids: response.rel_tag_ids,
          in_proportion: response.in_proportion,
          edu_param: response.edu_param,
          show_full_tag: response.show_full_tag,
          full_tag: response.full_tag,
          show_type: response.show_type,
          show_img: response.show_img,
          // page_info: response.page_info,
        }
        console.log(this.customPageVisible);
        console.log(this.selectCustomPageRow);
        if (response.page_info && response.show_type === 'img') {
          console.log(response.page_info);
          let  page_info_data= JSON.parse(response.page_info)
          if(page_info_data.page_name){
            this.form.page_info.page_path =page_info_data.page_path
            this.form.page_info.page_name =page_info_data.page_name
            this.selectCustomPageRow.page_name = page_info_data.page_name
            this.customPageVisible = false
          }
        }
        console.log(this.selectCustomPageRow);
        Object.assign(this.form, data)
        this.conditionValue = response.condition_value
        this.validGrade = response.valid_grade
        this.activity_date = [response.start_time, response.end_time]
        this.relItems = response.itemTreeLists || []
        this.relStores = response.storeLists || []
        this.relDealers = response.dealersLists || []
        this.form.relDealers = response.dealersLists || []
        this.relGifts = response.gifts || []
        this.couponList = response.giftscoupon || []
        this.zdItemHidden = true
        this.categoryHidden = true
        this.tagHidden = true
        this.brandHidden = true
        if (response.giftscoupon) {
          this.couponList = []// 重新处理数据
          response.giftscoupon.forEach(card => {
            let coupondata = {
              id: card.item_id,
              title: card.item_name,
              gift_type: 'coupon',
              price: 0,
              gift_num: card.gift_num,
              max_num: card.max_num,
              pics: ''
            }
            this.couponList.push(coupondata)
          })
        }

        if (response.use_bound == 0) {
          this.form.use_bound = 'all'
        }

        if (response.use_bound == 1) {
          this.form.use_bound = 'goods'
          this.zdItemHidden = false
        }

        if (response.use_bound == 2) {
          this.form.use_bound = 'category'
          this.categoryHidden = false
          this.generateSku()
        }

        if (response.use_bound == 3) {
          this.form.use_bound = 'tag'
          this.tagHidden = false
          this.tag.currentTags = response.tag_list || []
          this.showTags()
          this.generateSku()
        }

        if (response.use_bound == 4) {
          this.form.use_bound = 'brand'
          this.brandHidden = false
          this.brand.currentBrands = response.brand_list || []
          this.showBrands()
          this.generateSku()
        }
      })
    },
    handleCancel: function () {
      this.$router.back(-1)
    },
    relGiftsClick () {
      this.giftType = 'normal'
      this.giftVisible = true
      this.setGiftStatus = true
    },
    relGiftsCouponClick () {
      this.giftType = 'coupon'
      this.giftCouponVisible = true
      this.setGiftCouponStatus = true
    },
    chooseGiftsAction (data) {
      this.giftVisible = false
      let list = JSON.parse(JSON.stringify(data))
      if (data === null || data.length <= 0) return
      if (data.length > 4) {
        this.$message.error('赠品最多4个!')
        return false
      }
      list.forEach((item, index) => {
        item.gift_num = 1
        item.without_return = false
      })
      this.relGifts = list
    },
    pickCouponAction (data) {
      this.giftCouponVisible = false
      // 优惠券数据
      this.couponList = data
      console.log(this.couponList)
    },
    closeGiftsDialogAction () {
      this.giftVisible = false
    },
    pickCouponDialogAction (data) {
      this.giftCouponVisible = false
    },
    deleteGiftRow: function (index) {
      this.setGiftStatus = false
      this.setGiftCouponStatus = false
      this.relGifts.splice(index, 1)
    },
    deleteGiftCouponRow: function (index) {
      this.setGiftStatus = false
      this.setGiftCouponStatus = false
      this.couponList.splice(index, 1)
    },
    itemTypeChange: function (val) {
      this.params.main_cat_id = ''
      this.params.tag_id = ''
      this.params.brand_id = ''
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.items = []
      this.invalidItemsList = []
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === 'goods') {
        this.zdItemHidden = false
      } else if (val === 'category') {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === 'tag') {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === 'brand') {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    fetchMainCate: function () {
      getCategory({ is_main_category: true }).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag: function () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags: function () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
    },
    tagRemove: function (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      let tagItems = []
      let tagInvalidItems = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
        let items = []

        this.ItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) items.push(i)
        })
        tagItems = items

        let invalidItems = []
        this.invalidItemsList.forEach(i => {
          if (i.tag_ids.indexOf(item.tag_id) != -1) invalidItems.push(i)
        })
        tagInvalidItems = invalidItems
      })
      this.ItemsList = tagItems
      this.invalidItemsList = tagInvalidItems
      this.getItems(this.ItemsList)
    },
    tagAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
      this.params.tag_id = item.tag_id
      this.getGoodsList()
    },
    getAllTagLists: function () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    // 获取品牌列表
    getBrandList: function (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({ attribute_name: item.attribute_name, attribute_id: item.attribute_id })
        }
        this.brand.list = list
      })
    },
    dateChange (val) {
      console.log(val);
      if (val) {
        this.params.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.params.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.params.start_time = ''
        this.params.end_time = ''
      }
      this.params.page = 1
      this.getFullGiftLists()
    },
    maxNumChange: function (scope, num, max) {
      if (Number(num) > Number(max)) {
        scope.row.gift_num = max
      }
      if (Number(num) <= 0) {
        scope.row.gift_num = 1
      }
    },
    brandAdd: function (item, index) {
      if (this.activity_date.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择活动时间!'
        })
        return false
      }
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
      this.params.brand_id = item.attribute_id
      this.getGoodsList()
    },
    brandRemove: function (index) {
      let items = []
      this.ItemsList.forEach(item => {
        if (this.brand.currentBrands[index].attribute_id != item.brand_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (this.brand.currentBrands[index].attribute_id != i.brand_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)

      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    getGoodsList: function () {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.$route.params.marketing_id) {
        params.activity_id = this.$route.params.marketing_id
      }
      params.marketing_type = this.form.marketing_type
      params.activity_release_time = params.activity_start_time = this.activity_date[0]
      params.activity_end_time = this.activity_date[1]
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }
      seckillActivityGetItemsList(params).then(response => {
        let itemList = response.data.data.list.validItems.concat(this.ItemsList)
        let invalidItemList = response.data.data.list.invalidItems.concat(this.invalidItemsList)
        let newItemList = []
        let invalidItems = []
        let obj = {}
        let obj1 = {}
        itemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj[item.item_id]) {
            newItemList.push(item)
            obj[item.item_id] = true
          }
        })
        invalidItemList.forEach(item => {
          let tag_ids = []
          item.tagList.forEach(tag => {
            tag_ids.push(tag.tag_id)
          })
          item.tag_ids = tag_ids
          if (!obj1[item.item_id]) {
            invalidItems.push(item)
            obj1[item.item_id] = true
          }
        })

        this.ItemsList = newItemList
        this.invalidItemsList = invalidItems
        this.getItems(this.ItemsList)
      })
    },
    categorySelect: function (node, instanceId) {
      this.params.main_cat_id = node.category_id
      this.getGoodsList()
    },
    categoryDeselect: function (node, instanceId) {
      let items
      items = []
      this.ItemsList.forEach((item, index) => {
        if (node.category_id != item.item_main_cat_id) items.push(item)
      })
      let invalidItems = []
      this.invalidItemsList.forEach(i => {
        if (node.category_id != i.item_main_cat_id) invalidItems.push(i)
      })

      this.invalidItemsList = invalidItems
      this.ItemsList = items
      this.getItems(this.ItemsList)
    },
    generateSku () {
      let noSkuItem
      noSkuItem = []
      let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItems))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.params
        param.item_id = noSkuItem
        getItemsList(this.params).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id) {
                  item.spec_items.push(sku)
                }
              })
            }
          })
          goodsList.forEach(item => {
            if (!item.nospec) {
              response = [...response, ...item.spec_items]
            } else {
              response = [...response, item]
            }
          })
          this.ItemsList = response
          this.getItems(response)
        })
      } else {
        goodsList.forEach(item => {
          if (!item.nospec) {
            response = [...response, ...item.spec_items]
          } else {
            response = [...response, item]
          }
        })
        this.ItemsList = response
        this.getItems(response)
      }
    },
    /**
     * 下载模板
     * */
    uploadHandleTemplate () {
      let params = { file_type: 'marketing_goods', file_name: '商品模板' }
      exportUploadTemplate(params).then(response => {
        let { data } = response.data
        if (data.file) {
          var a = document.createElement('a')
          a.href = data.file
          a.download = data.name
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    /**
     * 上传模板
     * */
    uploadHandleChange (file, fileList) {
      let params = { isUploadFile: true, file_type: 'marketing_goods', file: file.raw }
      handleUploadFile(params).then(response => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })

        let { data } = response.data

        if (data.fail.length > 0) {
          let str = data.fail.map(item => {
            return item.item_bn
          })

          setTimeout(() => {
            this.$message({
              showClose: true,
              message: `以下商品编号不存在：${str}`,
              type: 'error',
              duration: 100000
            })
          }, 1500)
        }
        if (data.succ.length <= 0) return
        this.relItems = data.succ
        let list = []
        data.succ.forEach(item => {
          if (!item.nospec) {
            list.push(Object.assign(item, { spec_items: [] }))
          } else {
            list.push(item)
          }
        })
      })
    }
  },
  mounted () {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    if (this.$route.params.marketing_id) {
      this.getActivityDetail(this.$route.params.marketing_id)
    }
    if (this.$route.query.isnodata) {
      this.hasSaveButton = false
    }
    listVipGrade().then(response => {
      if (response != undefined && response.data.data && response.data.data.length > 0) {
        this.vipGrade = response.data.data
      }
    })
    getGradeList().then(response => {
      if (response != undefined && response.data.data && response.data.data.length > 0) {
        var result = response.data.data
        if (result) {
          this.memberGrade = result
        }
      }
    })
    this.fetchMainCate()
    this.getAllTagLists()
    this.getBrandList('', true)
    this.getCustomPageList()
  }
}
</script>
<style lang="scss">
.box-set .el-loading-spinner i{
  font-size: 20px;
  color: #F82C44;
}
.box-set .el-loading-spinner .el-loading-text{
  font-size: 16px;
  color: #F82C44;
}

</style>
