<template>
  <div class="">
    <div v-if="($route.path.indexOf('_detail') === -1 && $route.path.indexOf('_process') === -1)">

      <el-row class="filter-header" :gutter="20">
        <el-col>
          <shop-select v-if="0 && login_type !== 'distributor'" distributors @update="storeSearch"
            style="width: 280px"></shop-select>
          <el-select v-if="0" v-model="order_status" multiple filterable clearable collapse-tags default-first-option
            @change="selectChange" @clear="numberSearch" placeholder="请选择订单状态">
            <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.name">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 12px; padding-right: 25px;">{{ item.name }}</span>
            </el-option>
          </el-select>
          <el-date-picker v-model="create_time" type="datetimerange" :picker-options="pickerOptions"
            start-placeholder="开始日期时间" ange-separator="至" end-placeholder="结束日期时间" value-format="timestamp" align="left"
            @change="dateChange" placeholder="选择日期时间范围">
          </el-date-picker>
          <el-popover placement="top-start" width="200" trigger="hover" content="点击之后会显示“默认显示一个月内订单，如需查看历史订单，请选择日期范围”">
            <i slot="reference" class="el-icon-question" />
          </el-popover>
          <el-input class="input-m" placeholder="请输入订单号" v-model="identifier" clearable @clear="numberSearch"
            @change="numberSearch()"></el-input>

          <el-button type="primary" @click="resetParams">重置</el-button>
          <el-button type="primary" @click="exportMainDialog('matser_order')">导出主订单</el-button>
          <el-button type="primary" @click="exportMainDialog('order')">导出子订单</el-button>
          <el-button type="primary" @click="getOrders(params, 'pull_gift_order')">导出满赠订单信息</el-button>
          <!-- <el-button type="primary" plain @click="exportMainDialog('pull_gift_order')">导出满赠订单信息</el-button> -->
          <el-popover placement="top-start" width="200" trigger="hover" content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>

          <!--          <template v-if="params.order_status === 'delivery' && (login_type === 'distributor'||login_type === 'dealer')">
            <el-button
              :loading="exportReciverLoading"
              @click="downloadExportReciver(0)"
              plain
              title="下载所有待发货订单收货信息"
              type="primary">
              收货信息
            </el-button>
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="下载所有待发货订单收货信息">
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
          </template>-->

          <ImportDialog v-if="login_type !== 'disti' && false" style="margin-left: 10px" fileName="批量发货" buttonName="批量发货"
            fileType="normal_orders"></ImportDialog>
          <el-button class="collapse-btn" type="text" @click="panelCollapse(true)">
            <span>更多筛选项>></span>
          </el-button>
        </el-col>
      </el-row>

      <el-row v-if="dynamicTags.length > 0">
        <el-col>
          其他筛选条件：
          <el-tag class="ipt-tag" :key="index + 'index'" v-for="(tag, index) in dynamicTags" closable
            :disable-transitions="false" @close="handleEmpty(tag)">
            {{ tag.label ? tag.label + ':' + tag.value : tag.value }}
          </el-tag>
          <el-button type="text" @click="clearAll('params')">清空筛选项</el-button>
        </el-col>
      </el-row>

      <!-- TODO 订单列表 -->
      <sapn v-if="login_type === 'distributor'||login_type === 'dealer'">
        <el-tag v-if="open_self_handle" type="danger" style="font-size: 14px;margin-bottom: 15px;color: #333">已开启订单自闭环，点击
        <el-link :underline="false" type="danger" @click="toRoute('/shopadmin/order/entitytrade/selfhandleorders')">【自闭环订单列表】</el-link>
          前往处理！
        </el-tag>
      </sapn>
      <span v-else>
        <el-tag v-if="open_self_handle" type="danger" style="font-size: 14px;margin-bottom: 15px;color: #333">已开启订单自闭环</el-tag>
      </span>
      <el-tag v-if="new_btn"  type="success" style="float: right">当前使用为新按钮组</el-tag>

      <el-drawer title="订单筛选" :visible.sync="formDrawer" direction="rtl" :before-close="handleCloseDrawer" size="35%">

        <el-form class="drawer-list" label-position="left" ref="params" :model="params" label-width="150px">
          <!-- <el-form-item label="商品名称">
            <el-input class="input-m" placeholder="请输入商品名称" v-model="params.title" clearable @clear="numberSearch"
              @change="numberSearch()"></el-input>
          </el-form-item> -->


          <!-- <el-form-item label="商品货号" prop="item_bn">
            <el-input class="input-m" placeholder="请输入商品货号" v-model="params.item_bn" clearable @clear="numberSearch"
              @change="numberSearch()"></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="店铺名称">
            <el-input class="input-m" v-if="login_type !== 'distributor'" placeholder="请输入店铺名称"
              v-model="params.distributor_name" clearable @clear="numberSearch" @change="numberSearch()"></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="AppleID" v-if="login_type !== 'distributor'" prop="shop_code">
            <el-input class="input-m" placeholder="请输入AppleID" v-model="params.shop_code" clearable @clear="numberSearch"
              @change="numberSearch()"></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="经销商名称">
            <el-input class="input-m" v-if="login_type !== 'distributor'" placeholder="请输入经销商名称"
              v-model="params.dealer_name" clearable @clear="numberSearch" @change="numberSearch()"></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="经销商HQID" v-if="login_type !== 'distributor'" prop="dealer_id">
            <el-input class="input-m" placeholder="请输入经销商HQID" v-model="params.dealer_id" clearable
              @clear="numberSearch" @change="numberSearch()"></el-input>
          </el-form-item> -->

          <el-form-item label="订单商品" prop="item_bn">
            <el-button class="btn_input" @click="handleGoodsDialogShow">{{ goods_num }} <i
                class="el-icon-circle-plus icon"></i></el-button>
          </el-form-item>

          <el-form-item label="订单商品市场分类" prop="type_warehouse">
            <el-radio-group v-model="params.type_warehouse" @input="numberSearch()">
              <el-radio-button v-for="item in typeWarehouse" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="订单归属门店" prop="shop_code">
            <el-button class="btn_input" @click="onDialog('shop')">{{ shop_num }} <i
                class="el-icon-circle-plus icon"></i></el-button>
          </el-form-item>

          <el-form-item label="发货方" prop="delivery_warehouse">
            <el-radio-group v-model="params.delivery_warehouse" @input="numberSearch()">
              <el-radio-button v-for="item in deliveryWarehouse" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="归属经销商" prop="dealer_id">
            <el-button class="btn_input" @click="onDialog('sgency')">{{ agency_num }} <i
                class="el-icon-circle-plus icon"></i></el-button>
          </el-form-item>
          <el-form-item label="流水号" prop="serial_number" v-if="login_type === 'distributor'||login_type === 'dealer'">
            <el-input class="input-m" placeholder="请输入流水号" v-model="params.serial_number" clearable @clear="numberSearch"
                      @change="numberSearch()"></el-input>

          </el-form-item>

          <el-form-item label="归属分销商VAD" v-if="login_type !== 'distributor'" prop="distributor_vad">
            <el-input class="input-m" placeholder="请输入分销商VAD" v-model="params.distributor_vad" clearable
              @clear="numberSearch" @change="numberSearch()"></el-input>
          </el-form-item>

          <el-form-item label="渠道RTM" v-if="login_type !== 'distributor'" prop="channel_rtm">
            <el-input class="input-m" placeholder="请输入渠道RTM" v-model="params.channel_rtm" clearable @clear="numberSearch"
              @change="numberSearch()"></el-input>
          </el-form-item>

          <el-form-item label="订单来源" prop="is_wecom">
            <el-radio-group v-model="params.is_wecom" @input="numberSearch()">
              <el-radio-button v-for="item in is_wecom" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="教育优惠订单号" v-if="login_type !== 'distributor'" prop="edu_order_no">
            <el-input class="input-m" placeholder="请输入教育优惠订单号" v-model="params.edu_order_no" clearable
              @clear="numberSearch" @change="numberSearch()"></el-input>
          </el-form-item>

          <el-form-item label="是否教育优惠订单" prop="is_edu_order">
            <el-radio-group v-model="params.is_edu_order" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
            <!-- <el-select clearable v-model="params.is_edu_order" placeholder="是否教育优惠订单" @change="numberSearch()">
              <el-option v-for="item in is_edu_orders" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          </el-form-item>

          <el-form-item label="是否以旧换新" prop="is_tcb">
            <el-radio-group v-model="params.is_tcb" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="以旧换新回收类型" prop="tradein_service_type">
            <el-radio-group v-model="params.tradein_service_type" @input="numberSearch()">
              <el-radio-button v-for="item in tradeInSeriveList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否参与满赠" prop="is_full">
            <el-radio-group v-model="params.is_full" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否企业购订单" prop="is_ec">
            <el-radio-group v-model="params.is_ec" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否预售订单" prop="is_presale_order">
            <el-radio-group v-model="params.is_presale_order" @input="numberSearch()">
              <el-radio-button v-for="item in presaleSelectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否参与买X赠Y" prop="has_normal_gift_order">
            <el-radio-group v-model="params.has_normal_gift_order" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="是否实物赠品订单" prop="is_normal_gift_order">
            <el-radio-group v-model="params.is_normal_gift_order" @input="numberSearch()">
              <el-radio-button v-for="item in selectList" :label="item.value" :key="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="支付方式" prop="pay_type">
            <el-select
              v-model="params.pay_type"
              placeholder="请选择支付方式"
              clearable
              @change="numberSearch"
            >
              <el-option
                v-for="item in payMethods"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实际支付类型" prop="fund_channel">
            <el-select
              v-model="params.fund_channel"
              placeholder="请选择实际支付类型"
              clearable
              @change="numberSearch"
            >
              <el-option
                v-for="item in fundType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分期期数" prop="fund_fq_num">
            <el-select
              v-model="params.fund_fq_num"
              placeholder="请选择分期期数"
              clearable
              @change="numberSearch"
            >
              <el-option
                v-for="item in fund_fq_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="confirm-drawer">
          <el-button type="primary" @click="confirmDrawer">确认</el-button>
        </div>

      </el-drawer>

      <el-tabs v-model="activeOrderType" type="card" @tab-click="handleClickOrderType">
        <el-tab-pane
          v-for="item in OrderTypeOption"
          :key="item.value"
          :label="item.label"
          :name="item.value"
        />
      </el-tabs>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" :stretch="true" v-loading="loadingJd"
        element-loading-text="处理中，请稍后。。。">
        <template v-if="activeOrderType === 'all'">
          <el-tab-pane v-for="(item, index) in tabPane" :lazy="true" :label="item.label" :key="index" :name="item.name"></el-tab-pane>
        </template>
        <el-table :data="tableData" stripe :height="wheight - 350" v-loading="loading" element-loading-text="数据加载中">

          <el-table-column type="expand">

            <template slot-scope="scope">
              <el-form label-position="right" inline class="demo-table-expand" label-width="140px">
                <el-form-item label="经销商：">
                  <span>{{ scope.row.distributor_info.dealer_name }}</span>
                </el-form-item>

                <el-form-item v-if="scope.row.receipt_type === 'logistics'" label="收货人：">
                  <span>{{ scope.row.receiver_name }}</span>
                  <PiiInfo pageType='1' memberType='receiver_name' optionScene="order_list"  :associatedId='scope.row.order_id'/>
                </el-form-item>
                <el-form-item v-if="scope.row.receipt_type === 'ziti' && scope.row.receiver_name" label="取货人：">
                  <span>{{ scope.row.receiver_name }}</span>
                  <PiiInfo pageType='1' memberType='receiver_name' optionScene="order_list"  :associatedId='scope.row.order_id'/>
                </el-form-item>

                <el-form-item v-if="scope.row.receipt_type === 'ziti'" label="门店地址：">
                  <span>{{ scope.row.distributor_info.city + scope.row.distributor_info.area +
                    scope.row.distributor_info.address }}</span>
                </el-form-item>

                <el-form-item label="联系方式：">
                  <span>{{ scope.row.receiver_mobile }}</span>
                  <PiiInfo pageType='1' memberType='receiver_mobile' optionScene="order_list"  :associatedId='scope.row.order_id'/>
                </el-form-item>

                <el-form-item v-if="scope.row.receipt_type === 'ziti'" label="门店电话：">
                  <span>{{ scope.row.distributor_info.mobile || '暂无' }}</span>
                </el-form-item>

                <el-form-item v-if="scope.row.receipt_type === 'logistics'" label="收货地址：">
                  <span>{{ scope.row.receiver_city + scope.row.receiver_district + scope.row.receiver_address }}</span>
                  <PiiInfo pageType='4' memberType='receiver_address' optionScene="order_list" :associatedId='scope.row.order_id'/>
                </el-form-item>

                <el-form-item v-if="scope.row.edu_order_no" label="教育优惠订单号：">
                  <span>{{ scope.row.edu_order_no }}</span>
                  <el-tooltip effect="dark" content="复制" placement="top-start"><i
                      v-clipboard:copy="scope.row.edu_order_no" v-clipboard:success="onCopy"
                      class="el-icon-document-copy"></i></el-tooltip>
                </el-form-item>
              </el-form>

              <el-table :data="scope.row.items" style="width: calc(100% - 47px); border: 1px solid #efefef; " stripe>

                <el-table-column prop="pic" label="图片" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="item_name" label="名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="item_spec_desc" label="规格" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.item_spec_desc ? scope.row.item_spec_desc : '单规格' }}
                  </template>
                </el-table-column>
                <el-table-column prop="item_bn" label="货号" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="单价" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.price / 100 }} </span>
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="数量" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="总原价" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.item_fee / 100 }} </span>
                  </template>
                </el-table-column>
                <el-table-column label="总支付价" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
                <el-table-column label="教育优惠订单号：">
                  <template slot-scope="scope">
                    <div v-if="scope.row.edu_order_no">
                      <span>{{ scope.row.edu_order_no }}</span>
                      <el-tooltip effect="dark" content="复制" placement="top-start"><i
                          v-clipboard:copy="scope.row.edu_order_no" v-clipboard:success="onCopy"
                          class="el-icon-document-copy"></i></el-tooltip>
                    </div>
                  </template>src/view/mall/goods/physical/add.vue
                </el-table-column>

              </el-table>
              <br>

              <el-table :data="scope.row.sf_response"  style="width: calc(100% - 47px)" stripe border v-if="scope.row.receipt_type !== 'ziti' && scope.row.sf_response && scope.row.sf_response.length > 0">
<!--                <el-table-column prop="customer_order_code" min-width="150" label="客户单号" align="center" show-overflow-tooltip></el-table-column>-->
<!--                <el-table-column prop="sf_order_id"  min-width="100" label="顺丰单号" align="center" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="pre_delivery_corp_name" label="系统呼叫快递" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="delivery_type" label="呼叫逻辑" align="center" show-overflow-tooltip>
                  <template slot-scope="scopenew">
                    {{ scopenew.row.delivery_type }}
                    <br>
                    <el-tag type="success" v-if="scope.row.extInfo.pre_time && scope.row.extInfo.pre_time !== ''"
                      size="mini">{{ scope.row.extInfo.pre_time }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="delivery_corp_name" label="实际承运" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column  prop="delivery_remark" label="承运切换原因" align="center" show-overflow-tooltip>
                  <template slot-scope="scopeErr">
                    {{ scopeErr.row.delivery_remark }}
                  </template>
                </el-table-column>
                <!--                <el-table-column prop="delivery_corp" label="物流编码" align="center" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="delivery_code" min-width="100" label="物流单号" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-popover placement="right" width="400" @show="getDeliveryInfo(scope.row)" trigger="click"
                      title="物流轨迹详情" :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }">
                      <br>
                      <el-timeline v-loading="deliveryLoading" style="max-height: 300px; overflow: auto">
                        <el-timeline-item v-for="(activity, index) in deliveryInfo" :key="index" placement="top"
                          :timestamp="activity.AcceptStatus">
                          <el-card>
                            <h4>{{ activity.AcceptStation }}</h4>
                            <h6 v-if="activity.OpCode">操作码：{{ activity.OpCode }}</h6>
                            <h6 v-if="activity.ReasonCode">原因码：{{ activity.ReasonCode }}</h6>
                            <br>
                            <span>{{ activity.AcceptTime }}</span>
                          </el-card>
                        </el-timeline-item>
                      </el-timeline>

                      <el-tag size="mini" slot="reference">{{ scope.row.delivery_code }}</el-tag>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column prop="delivery_time" min-width="100" label="发货时间" align="center"
                  show-overflow-tooltip></el-table-column>

              </el-table>

            </template>

          </el-table-column>

          <el-table-column label="订单信息" min-width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="order-num">
                {{ scope.row.order_id }}
                <el-tooltip effect="dark" content="复制" placement="top-start"><i v-clipboard:copy="scope.row.order_id"
                    v-clipboard:success="onCopy" class="el-icon-document-copy"></i></el-tooltip>
              </div>

              <div>
                实付金额：<span class="cur">{{ scope.row.fee_symbol }}</span>{{ scope.row.total_fee / 100 }}
              </div>

              <!-- <div v-if="scope.row.distributor_name" class="order-store">
                <el-tooltip effect="dark" content="店铺名" placement="top-start"><i
                    class="el-icon-office-building"></i></el-tooltip>
                {{ scope.row.distributor_name }}
              </div>
              <div v-if="scope.row.distributor_info.shop_code" class="order-store">
                <el-tooltip effect="dark" content="店铺名" placement="top-start"><i
                    class="el-icon-office-building"></i></el-tooltip>
                {{ scope.row.distributor_info.shop_code }}
              </div> -->

              <div class="order-time">
                <el-tooltip effect="dark" content="下单时间" placement="top-start"><i class="el-icon-time"></i></el-tooltip>
                {{ scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}
              </div>
              <div class="order-tag">
                <el-tag v-if="scope.row.order_tier === 'iup'" @click="setIupInfo(scope.row)" type="primary"
                  style="margin-left: 16px;">
                  值享焕新【查看协议】
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column label="门店AppleID" min-width="120" align="center" show-overflow-tooltip>-->
          <!--            <template slot-scope="scope">-->
          <!--              <span class="cur">{{ scope.row.distributor_info.shop_code }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->

          <el-table-column label="归属门店" min-width="170" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.distributor_name }}</div>
              <div>{{ scope.row.distributor_info.shop_code }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="订单类型" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div><el-tag size="mini" v-if="scope.row.type == '0'">普通订单</el-tag></div>
              <div><el-tag type="warning" size="mini" v-if="checkPreOrder(scope.row)">预售订单</el-tag></div>
            </template>
          </el-table-column> -->

          <el-table-column v-if="false" label="经销商" min-width="150" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="cur">{{ scope.row.distributor_info.dealer_name }}</span>
            </template>
          </el-table-column>

          <el-table-column label="发货方" min-width="100" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button v-if="scope.row.is_warehouse == '0'" type="warning" plain size="mini">门店</el-button>
              <el-button v-if="scope.row.is_warehouse == '1' || scope.row.is_warehouse == '2'" type="info" plain size="mini">仓库</el-button>
            </template>
          </el-table-column>

          <el-table-column label="配送方式" min-width="150" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.receipt_type === 'ziti'">到店自取</span>
              <span v-else>
                <!-- 同城速递 -->
                快递
              </span>
              <div v-if="scope.row.receipt_type !== 'ziti'">
                <el-tag type="success">{{ scope.row.order_pre_type }}</el-tag>
              </div>

              <el-popover :key="scope.row.order_id" v-if="scope.row.receipt_type !== 'ziti'" placement="right" width="200" trigger="click">
                <div>
                  <div><span>配送员姓名：</span><span>{{ scope.row.extInfo.delivery_info.courierName }}</span></div>
                  <div><span>配送员电话：</span><span>{{ scope.row.extInfo.delivery_info.courierPhone }}</span></div>
                </div>
                <el-button v-if="scope.row.extInfo.delivery_info.courierName" type="text" slot="reference" size="mini">配送人员</el-button>
              </el-popover>
              <!--              <div v-if=" scope.row.receipt_type !== 'ziti'" >
                <el-tooltip v-if="checkIsNormal(scope.row)" class="item" effect="dark"
                            :content="checkIsNormal(scope.row)" placement="top-start">
                  <el-tag type="success">{{scope.row.order_pre_type}}</el-tag>
                </el-tooltip>
              </div>
              <div v-if="checkIsTc(scope.row) && scope.row.receipt_type !== 'ziti'" >
                <el-tag  type='danger' v-if="checkIsTc(scope.row) ===1">{{scope.row.order_pre_type}}</el-tag>
                <span v-else>
                      <el-tooltip class="item" effect="dark" :content="checkIsTc(scope.row)" placement="top-start">
                              <el-tag type="success">{{scope.row.order_pre_type}}</el-tag>
                      </el-tooltip>

&lt;!&ndash;                  <el-tag>{{ checkIsTc(scope.row)}}</el-tag>&ndash;&gt;
                </span>
              </div>-->
            </template>

          </el-table-column>


          <el-table-column label="订单渠道" min-width="100" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div><el-tag type="info" size="mini" v-if=!checkStatus(scope.row.lingzhi_scene)>普通</el-tag></div>
              <div><el-tag type="success" size="mini" v-if=checkStatus(scope.row.lingzhi_scene)>企业微信</el-tag></div>
            </template>
          </el-table-column>

          <el-table-column label="订单标签" min-width="100" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div><el-tag size="mini" v-if="checkPreOrderQh(scope.row)">期货预售</el-tag></div>
              <div><el-tag type="success" size="mini" v-if="scope.row.xxw_check_code != ''">教育优惠</el-tag></div>
              <!-- <div><el-tag type="success" size="mini" v-if="scope.row.send_oms == 'true'">互道</el-tag></div> -->
              <div><el-tag type="success" size="mini" v-if="scope.row.tcb_status >= 1">以旧换新</el-tag></div>
              <div><el-tag type="warning" v-if="scope.row.ecid !== ''" size="mini">员工购</el-tag></div>
              <div><el-tag type="success" v-if="scope.row.use_full_gift === '1'" effect="dark" size="mini">满赠</el-tag></div>
              <div><el-tag type="warning" v-if="checkMinusPromotion(scope.row)" effect="dark" size="mini">满减</el-tag></div>
              <div><el-tag v-if="scope.row.order_tier === 'acplus'" effect="dark" size="mini">AC+</el-tag></div>
              <div><el-tag v-if="scope.row.tcb_status >= 1 && scope.row.tradein_service_type == 1" size="mini">一站式回收</el-tag></div>
              <div><el-tag v-if="scope.row.tcb_status >= 1 && scope.row.tradein_service_type == 2" size="mini">上门回收</el-tag></div>
              <div><el-tag v-if="scope.row.tcb_status >= 1 && scope.row.tradein_service_type == 3" size="mini">旧机邮寄</el-tag></div>
              <div><el-tag size="mini" v-if="scope.row.type == '0'">普通订单</el-tag></div>
              <div><el-tag type="warning" size="mini" v-if="checkPreOrder(scope.row)">预售订单</el-tag></div>
              <div><el-tag size="mini" v-if="scope.row.is_bundle_discount == '1'">优惠搭售</el-tag></div>
              <div><el-tag size="mini" type="warning" v-if="scope.row.order_tier == 'normal_gift'">实物赠品</el-tag></div>
              <div><el-tag size="mini" v-if="scope.row?.extInfo?.has_normal_gift_order == '1'">买X赠Y</el-tag></div>
            </template>
          </el-table-column>

          <el-table-column v-if="false" label="收/取货地址" min-width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="cur" v-if="scope.row.receipt_type === 'ziti'">{{ scope.row.distributor_info.city +
                scope.row.distributor_info.area + scope.row.distributor_info.address }}</span>
              <span class="cur" v-else>{{ scope.row.receiver_city + scope.row.receiver_district +
                scope.row.receiver_address }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column label="订单金额" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="cur">{{ scope.row.fee_symbol }}</span>{{ scope.row.total_fee / 100 }}
            </template>
          </el-table-column> -->

          <el-table-column label="订单状态" min-width="100">
            <template slot-scope="scope">
              <el-tag type="warning" size="mini" v-if="scope.row.order_status === 'NOTPAY'">待付款</el-tag>

              <el-tag type="" size="mini"
                v-else-if="scope.row.order_status === 'PAYED' && scope.row.pay_type !== 'appointment'">

                <span v-if="scope.row.receive_status !== '0'">{{ scope.row.order_status_msg }}</span>
                <span v-else-if="scope.row.receive_status === '0'">待接单</span>
                <span v-else>已付款</span>

              </el-tag>

              <el-tag size="mini" v-else-if="scope.row.order_status === 'WAIT_BUYER_CONFIRM'
                && scope.row.cancel_status === 'NO_APPLY_CANCEL'">{{ scope.row.order_status_msg }}
              </el-tag>

              <el-tag type="danger" size="mini" v-else-if="scope.row.order_status === 'CANCEL'">
                <span>{{ scope.row.order_status_msg }} </span>
              </el-tag>

              <el-tag size="mini" v-else>{{ scope.row.order_status_msg }}</el-tag>
              <el-tag type="warning" size="mini"
                v-if="scope.row.delivery_corp_source == 'SF_API_ERROR' || scope.row.delivery_corp_source == 'SF_API_CHEXIAO'">快递异常</el-tag>

              <el-tag type="danger" size="mini" v-if="scope.row.order_lock">订单锁定</el-tag>

            </template>
          </el-table-column>

          <el-table-column label="查看" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <router-link
                :to="{ path: matchHidePage('detail'), query: { orderId: scope.row.order_id, resource: $route.path } }">详情
              </router-link>
              <!--              <router-link :to="{ path: matchHidePage('process'), query: { orderId: scope.row.order_id, resource: $route.path }}">日志</router-link>-->
              <el-link :underline="false" style="vertical-align: top;" type="primary"
                @click="setLogPage(scope.row.order_id)">日志</el-link>

            </template>
          </el-table-column>
          <el-table-column label="流水号" min-width="100" show-overflow-tooltip v-if="login_type === 'distributor'||login_type === 'dealer'">
            <template slot-scope="scope" >
              <span v-if="scope.row.serial_number">
                <el-tag type="warning" effect="plain" :hit="true" size="medium">&nbsp;&nbsp;{{ scope.row.serial_number }}&nbsp;&nbsp;</el-tag>
                <br><el-link type="warning" disabled style="font-size: 12px">{{ scope.row.create_time | datetime('yyyy-MM-dd') }}</el-link>
            </span>
            </template>
          </el-table-column>+

          <el-table-column v-if="activeName == 'invoice' || activeName == 'invoiced'" label="开票状态" min-width="100"
            align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.order_invoice_timeout == 1" size="mini" type="danger">超时未开票</el-tag>
              <el-tag v-else-if="scope.row.is_invoiced == 1" size="mini" type="success">已开票</el-tag>
              <el-tag v-else size="mini" type="warning">开票中</el-tag>
            </template>
          </el-table-column>

          <el-table-column v-if="activeOrderType === 'all'" label="操作" min-width="270" key="opt">

            <template slot-scope="scope">

              <!--            新的按钮组-->
              <span v-if="new_btn">
                      <div class="operate-btns">
                    <div v-if="scope.row.qrcode_url_base" style="text-align: center">
                      <img :src="'data:image/png;base64,' + scope.row.qrcode_url_base"
                           style="display:inline-block; width: 60px; height: 60px">
                    </div>

                    <!--                新的按钮组----start-->
                    <!-- 订单处理-->
                    <el-button size="mini" plain type="primary" v-if="scope.row.opButton.receive_order && !isSelfhandle(scope.row)"
                               @click="receivedOrder(scope.row.order_id)">接单</el-button>

                    <el-button size="mini" plain type="primary" v-if="scope.row.opButton.recall && !isSelfhandle(scope.row)"
                               @click="beihuoAction(scope.row)">再次备货</el-button>
                        <!-- 快递按钮组-->
                    <el-button size="mini" class="sf-call" v-if="scope.row.opButton.call_sf && !isSelfhandle(scope.row)"
                               @click="deliveryAction(scope.row, 'delivery', 'call')">呼叫顺丰</el-button>
                    <el-button size="mini" class="mt-call" v-if="scope.row.opButton.call_mt && !isSelfhandle(scope.row)"
                               @click="deliveryAction(scope.row, 'delivery_mt', 'call')">呼叫美团</el-button>
                        <!--  转顺丰，文字暂时按照转顺丰，后面还成呼叫顺丰-->
                    <el-button size="mini" class="sf-exchange" v-if="scope.row.opButton.exchange_sf && !isSelfhandle(scope.row)"
                               @click="exchangeSfAction(scope.row, 'exchange','call')">呼叫顺丰</el-button>
                    <el-button size="mini" class="mt-cancel" v-if="scope.row.opButton.revoke_mt && !isSelfhandle(scope.row)"
                               @click="deliveryAction(scope.row,'delivery_mt','back')">撤销美团</el-button>
                    <el-button size="mini" class="sf-cancel" v-if="scope.row.opButton.revoke_sf && !isSelfhandle(scope.row)"
                               @click="deliveryAction(scope.row,'delivery','back')">撤销顺丰</el-button>

                        <el-button size="mini" class="mt-cancel" v-if="scope.row.opButton.intercept_mt && !isSelfhandle(scope.row)"
                                   @click="deliveryAction(scope.row,'delivery_mt','back')">拦截美团</el-button>
                    <el-button size="mini" class="sf-cancel" v-if="scope.row.opButton.intercept_sf && !isSelfhandle(scope.row)"
                               @click="deliveryAction(scope.row,'delivery','back')">拦截顺丰</el-button>


                 <!-- 强制取消并退款-->
                    <el-button size="mini" plain type="danger" v-if="scope.row.opButton.close_normal && !isSelfhandle(scope.row)"
                               @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款">关闭订单</el-button>
                    <!-- 云仓订单-->
                    <div v-if="!isWarehouse(scope.row) && !isSelfhandle(scope.row)">
                      <el-button size="mini" plain type="danger" v-if="scope.row.opButton.close_warehouse "
                                 @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款">取消订单</el-button>
                    </div>


                       <el-button size="mini" plain type="primary" v-if="scope.row.opButton.process && !isSelfhandle(scope.row)"
                                  @click="confirmCancelOrderAction(scope.row.order_id, scope.row)">审核</el-button>

                        <!-- 自闭环以及其他-->
                    <el-button size="mini" plain type="primary" v-if="scope.row.opButton.print_packing_list && !isSelfhandle(scope.row)"
                               @click="downloadExportPdf(scope.row)">打印随箱单</el-button>
                    <el-button type="text" v-if="scope.row.opButton.handle_self  && isSelfhandle(scope.row)"
                               @click='pathSelfhandle(scope.row)'>前往处理</el-button>
                    <div class="muted" v-if="scope.row.opButton.not_operable">不可操作</div>
                    <div class="muted" v-if="scope.row.opButton.not_operable_required">无需操作</div>
                    <!--                新的按钮组----end-->
                <div class="red small" v-if="scope.row.mtErrorMsg !== '' && showErrMsg(scope.row)">{{ scope.row.mtErrorMsg }}</div>
                  </div>
              </span>


              <!--            旧的按钮组-->

              <span v-else>
                 <div v-if="!open_self_handle">
                <div class="operate-btns" v-if="isWarehouse(scope.row)">
                  <div v-if="scope.row.qrcode_url_base" style="text-align: center">
                    <img :src="'data:image/png;base64,' + scope.row.qrcode_url_base"
                         style="display:inline-block; width: 60px; height: 60px">
                  </div>

                <el-button size="mini" v-if="isShowReceive(scope.row)"  @click="receivedOrder(scope.row.order_id)" plain type="primary">接单</el-button>
                <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'sftc' && scope.row.bsp_only === '0'" @click="deliveryAction(scope.row, 'delivery_tc', 'call')" class="sf-call">呼叫顺丰</el-button>
                <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'mtps' && scope.row.oms_store === '3' && scope.row.bsp_only === '0' && !scope.row.showjustCallSf" @click="deliveryAction(scope.row, 'delivery_tc', 'call')" class="sf-call">呼叫顺丰</el-button>
                <!--                <el-button size="mini" v-if="isShowCall(scope.row) && (scope.row.urgentDeliveryType === 'sftc' || (scope.row.urgentDeliveryType === 'mtps' && scope.row.oms_store  === '3')) && scope.row.bsp_only === '0'" @click="deliveryAction(scope.row, 'delivery_tc', 'call')" class="sf-call">呼叫顺丰</el-button>-->
                <el-button size="mini" v-if="isShowCall(scope.row) && scope.row.urgentDeliveryType === 'mtps' && scope.row.bsp_only === '0'" @click="deliveryAction(scope.row, 'delivery_mt', 'call')" class="mt-call">呼叫美团</el-button>
                <el-button size="mini" v-if="isShowCall(scope.row)  && (!scope.row.bsp_only || scope.row.bsp_only === '1' || scope.row.showjustCallSf === true)" @click="deliveryAction(scope.row, 'delivery','call')" class="sf-call">呼叫顺丰</el-button>
                <el-button size="mini" v-if="isShowCallBeihuo(scope.row)" @click="beihuoAction(scope.row)" plain type="primary">再次备货</el-button>

                <el-button size="mini" v-if="isShowCancel(scope.row) && scope.row.delivery_corp_order === 'MTPS'" @click="deliveryAction(scope.row,'delivery_mt','back')" class="mt-cancel">
                    <span v-if="scope.row.oms_store === '0'">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange ===''" size="mini" type="success">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store !== '3'" size="mini" type="success">拦截美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store === '3'" size="mini" type="success">撤销美团</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !==''" size="mini" type="success">拦截美团</span>
                    <span v-else>拦截美团</span>
                </el-button>
                <el-button size="mini" v-if="isShowExchange(scope.row) &&  scope.row.showExchangeSf === true" @click="exchangeSfAction(scope.row, 'exchange','call')" class="sf-call">呼叫顺丰</el-button>
                <el-button size="mini" v-if="isShowCancel(scope.row)  && scope.row.delivery_corp_order !== 'MTPS'" @click="deliveryAction(scope.row,'delivery','back')" class="sf-cancel">
                    <span v-if="scope.row.oms_store === '0'" size="mini" type="danger">撤销顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange ===''" size="mini" type="success">撤销顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store !== '3'" size="mini" type="success">拦截顺丰</span>
                    <span v-else-if="scope.row.oms_store !== '0' && scope.row.back && scope.row.oms_no_exchange  !=='' && scope.row.oms_store === '3'" size="mini" type="success">撤销顺丰</span>
                    <span v-else size="mini" type="warning">拦截顺丰</span>
                </el-button>
                <el-button size="mini" v-if="isShowCallBack(scope.row)  && scope.row.delivery_corp_order !== 'MTPS'" @click="deliveryAction(scope.row,'delivery','back')" class="sf-cancel">拦截顺丰</el-button>
                <el-button size="mini" v-if="isShowCallBack(scope.row)  && scope.row.delivery_corp_order === 'MTPS'" @click="deliveryAction(scope.row,'delivery_mt','back')" class="mt-cancel">拦截美团</el-button>


                <el-button size="mini" v-if="isShowClose(scope.row)"
                           @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款" plain
                           type="danger">{{scope.row.is_warehouse == '0' ? '关闭订单' : '取消订单'}}</el-button>
                <!-- <el-button size="mini" v-if="isShowInvoice(scope.row)" @click="handleInvoiceView(scope.row)" plain
                  type="primary">开票</el-button> -->
                <el-button size="mini" v-if="isShowAudit(scope.row)"
                           @click="confirmCancelOrderAction(scope.row.order_id, scope.row)" plain type="primary">审核</el-button>
                <!--              <el-button :loading="exportReciverLoading" size="mini" v-if="isShowExportReciver(scope.row)" @click="downloadExportReciver(scope.row)" plain title="下载收货信息" type="primary">收货信息</el-button>-->
                <el-button size="mini" v-if="isShowExportPdf(scope.row)" @click="downloadExportPdf(scope.row)" plain
                           type="primary">打印随箱单</el-button>

                <div class="red small" v-if="scope.row.mtErrorMsg !== '' && showErrMsg(scope.row)">
                  {{ scope.row.mtErrorMsg }}</div>
              </div>

              <div v-if="!isWarehouse(scope.row)">
                <el-button size="mini" v-if="isShowClose(scope.row)"
                           @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款" plain
                           type="danger">{{scope.row.is_warehouse == '0' ? '关闭订单' : '取消订单'}}</el-button>
              </div>
              <div class="muted" v-else-if="isShowDisabled(scope.row)">无需操作</div>
            </div>
                 <div v-else>
                <div v-if="!isWarehouse(scope.row)">
                  <el-button size="mini" v-if="isShowClose(scope.row)"
                             @click="cancelOrderAction(scope.row.order_id, scope.row)" title="强制取消并退款" plain
                             type="danger">{{scope.row.is_warehouse == '0' ? '关闭订单' : '取消订单'}}</el-button>
                </div>
                <el-button type="text"
                           v-else-if='isSelfhandle(scope.row)'
                           @click='pathSelfhandle(scope.row)'
                >前往处理</el-button>
                <div class="muted" v-else>不可操作</div>
              </div>
              </span>
            </template>



          </el-table-column>
          <el-table-column label="备注" width="100" prop="remark" key="remark">
            <template slot-scope="scope">
              <el-popover
                v-if='scope.row.remark'
                placement="bottom"
                title=""
                width="300"
                trigger="click"
                :content="scope.row.remark">
                <el-button type="text" slot="reference">查看</el-button>
              </el-popover>
              <!-- <el-popover
                placement="top-start"
                title=""
                width="200"
                trigger="click"
                :content="scope.row.remark">
                <el-button v-if='scope.row.remark' type="text" slot="reference">查看</el-button>
              </el-popover> -->
            </template>
          </el-table-column>
          <el-table-column v-if="activeOrderType !== 'all'" label="关闭原因" min-width="120" prop="risk_reason" key='risk_reason'></el-table-column>
        </el-table>

        <div class="content-padded content-center">
          <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
            @size-change="handleSizeChange" :current-page.sync="params.page" :page-sizes="[10, 20, 50]"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>

      <!-- 接单-dialog-->
      <el-dialog title="接单确认" width="57%" :visible.sync="receiveView" :before-close="handleColse">
        <template>
          <el-form class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ orderInfo.order_id }}</el-col>
            </el-form-item>

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                  min-width="200px"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量"
                  width="100px"></el-table-column>
                <el-table-column label="总支付价(元)" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

          </el-form>
        </template>
        <div slot="footer" class="dialog-footer content-center">
          <el-button type="success" plain @click="receivedOrder(orderInfo.order_id)"
            :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 发货-dialog -->
      <el-dialog :title="deliveryTitle" :visible.sync="deliveryVisibleNew" :before-close="handleCancel" width="57%">
        <template>
          <el-form ref="deliveryForm" :model="deliveryForm" class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ deliveryData.orderInfo.order_id }}</el-col>
            </el-form-item>
            <el-form-item label="发货类型" v-show="false">
              <el-radio-group :disabled="IsDisabled" v-model="deliveryForm.delivery_type">
                <el-radio label="batch">整单发货</el-radio>
                <el-radio label="sep">拆分发货</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品信息">
              <el-table :data="deliveryData.orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                  min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column v-if="false" prop="delivery_item_num" label="已发货数量" width="180"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{ scope.row.fee_symbol }}{{ scope.row.total_fee / 100 }}</span>
                  </template>
                </el-table-column>
                <template v-if="deliveryForm.delivery_type == 'sep'">
                  <el-table-column label="发货数量" width="200">
                    <template slot-scope="scope">
                      <el-input-number v-model="scope.row.delivery_num" placeholder="发货数量" controls-position="right"
                        :min="0" :max="scope.row.num - scope.row.delivery_item_num"
                        v-if="scope.row.num - scope.row.delivery_item_num != 0"></el-input-number>
                      <!-- <el-input v-model="scope.row.delivery_num" :maxlength=20 placeholder="发货数量"></el-input> -->
                      <span v-if="scope.row.num - scope.row.delivery_item_num == 0">已发完</span>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </el-form-item>

            <el-form-item label="快递公司" v-show="false">
              <el-col>
                <el-select v-model="deliveryForm.delivery_corp" filterable width="200px" placeholder="请选择快递公司，可搜索"
                  style="width: 300px">
                  <el-option v-for="item in dlycorps" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="物流单号" v-show="false">
              <el-col :span="16">
                <el-input v-model="deliveryForm.delivery_code" :maxlength=20 placeholder="物流公司单号"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item v-if="deliveryData.orderInfo.delivery_status === 'DONE'" v-show="false" label=" * ">
              <template v-if="deliveryData.orderInfo.delivery_status === 'DONE'">
                <el-col :span="20">此订单已经发货，重新填写，可以修改物流单号！</el-col>
              </template>
            </el-form-item>

          </el-form>
        </template>
        <div slot="footer" class="dialog-footer content-center">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitDeliveryAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 用户取消订单退款审核-开始 -->
      <el-dialog title="已接单，用户取消订单审核" :visible.sync="cancelVisible" :before-close="handleCancelOrderCancel" width="57%">
        <template>
          <el-row :gutter="100">
            <el-col :span="24">
              <span class="grid-content">订单号:{{ cancelData.order_id }}</span>
            </el-col>
            <el-col :span="24">
              <span v-if="cancelData.cancel_from == 'buyer'">取消来源:消费者申请</span>
              <span v-else>取消来源:系统自动取消</span>
            </el-col>
            <el-col :span="24">
              <span>申请时间:{{ cancelData.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
            </el-col>
            <el-col :span="24" style="display: none">
              <span>退款状态:</span>
              <span v-if="cancelData.refund_status == 'READY'">待审核</span>
              <span v-if="cancelData.refund_status == 'AUDIT_SUCCESS'">审核成功待退款</span>
              <span v-if="cancelData.refund_status == 'SUCCESS'">退款成功</span>
              <span v-if="cancelData.refund_status == 'SHOP_CHECK_FAILS'">商家审核不通过</span>
              <span v-if="cancelData.refund_status == 'CANCEL'">撤销退款</span>
              <span v-if="cancelData.refund_status == 'PROCESSING'">已发起退款等待到账</span>
              <span v-if="cancelData.refund_status == 'FAILS'">退款失败</span>
            </el-col>
            <el-col :span="24">
              <span>处理进度:</span>
              <span v-if="cancelData.progress == '0'">待处理</span>
              <span v-if="cancelData.progress == '1'">已取消</span>
              <span v-if="cancelData.progress == '2'">退款中</span>
              <span v-if="cancelData.progress == '3'">已完成</span>
              <span v-if="cancelData.progress == '4'">已驳回</span>
            </el-col>
            <el-col :span="24">
              <span>退款金额:{{ cancelData.fee_symbol }}{{ cancelData.total_fee / 100 }}</span>
            </el-col>
            <el-col :span="24">
              <span v-if="cancelData.pay_type == 'wxpay'">支付方式:微信支付</span>
            </el-col>
            <el-col :span="24" v-if="0">
              <span>取消原因:{{ cancelData.cancel_reason }}</span>
            </el-col>
          </el-row>
          <el-divider></el-divider>

          <el-form ref="cancelForm" :model="cancelForm" class="" label-width="100px">

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                  min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="处理结果:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-radio v-model="cancelForm.check_cancel" label="0">拒绝</el-radio>
                    <el-radio v-model="cancelForm.check_cancel" label="1">同意</el-radio>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="拒绝原因:" v-if="cancelForm.check_cancel == '0'">
              <el-row>
                <el-col :span="24">
                  <el-input type="textarea" v-model="cancelForm.shop_reject_reason" :rows="3"
                    placeholder="请输入拒绝原因"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancelOrderCancel">取消</el-button>
          <el-button type="primary" @click="submitCancelConfirmAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <!-- 取消订单 -->
      <el-dialog title="取消订单" :visible.sync="cancelOrderVisible" :before-close="handleCancelOrder" width="57%">
        <template>
          <el-form ref="cancelForm" :model="cancelOrderForm" class="" label-width="100px">
            <el-form-item label="订单号:">
              <el-col :span="20">{{ cancelOrderData.orderInfo.order_id }}</el-col>
            </el-form-item>

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名"
                  min-width="200"></el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100"></el-table-column>
                <el-table-column label="总支付价(元)" width="100" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="关闭原因:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-select v-model="cancel_order" filterable placeholder="请选择关闭订单原因" @change="cancelReasonSelect"
                      style="width: 300px">
                      <el-option v-for="item in order_cancel_reason" :key="item.value" :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-show="cancelOrderInput" v-model="cancelOrderForm.other_reason" type="textarea" :rows="3"
                      resize="none" style="width: 200px; margin-top: 10px" placeholder="请填写关闭订单原因"></el-input>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancelOrder">取消</el-button>
          <el-button type="primary" @click="submitCancelOrderConfirmAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="订单开票" width="57%" :visible.sync="invoiceVisible" :before-close="handleColse"
        :destroy-on-close="true">
        <template>
          <el-form class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ orderInfo.order_id }}</el-col>
            </el-form-item>

            <el-form-item label="商品信息">
              <el-table :data="orderInfo.items" stripe border>
                <el-table-column prop="pic" label="图片" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                  </template>
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="item_name" label="商品名" min-width="200px">
                </el-table-column>
                <el-table-column align="center" show-overflow-tooltip prop="num" label="数量" width="100px">
                </el-table-column>
                <el-table-column label="总支付价(元)" width="100px" align="center" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span> {{ scope.row.fee_symbol }} {{ scope.row.total_fee / 100 }} </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="上传发票">
              <el-col :span="14">
                <el-upload :multiple="true" accept="image/jpeg,image/png,application/pdf" action=''
                  :on-change="uploadInvoiceChange" :auto-upload="false" class="cus-upload"
                  :on-remove="uploadInvoiceRemoveChange">
                  <el-button type="primary" size="medium">上传发票信息</el-button>
                  <div slot="tip" class="el-upload__tip">仅支持jpg/png/pdf文件</div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button plain @click="handleColse()">取消</el-button>
          <el-button type="primary" @click="IsInvoiced(orderInfo)" :loading="buttonLoading">提交</el-button>
        </div>
      </el-dialog>

      <!-- 自提订单核销 -->
      <el-dialog title="自提订单核销" :visible.sync="writeoffOrderVisible" :before-close="handleWriteoffOrder" width="57%">
        <template>
          <el-form ref="cancelForm" :model="writeoffOrderForm" class="" label-width="100px">
            <el-form-item label="订单号:">
              <el-col :span="20">{{ writeoffOrderData.order_id }}</el-col>
            </el-form-item>
            <el-form-item label="商品:">
              <el-col v-for="item in writeoffOrderData.items" :key="item.item_id"> {{ item.item_name }} {{
                item.item_spec_desc }} × {{ item.num }}</el-col>
            </el-form-item>
            <el-form-item label="提货码:" v-if="writeoffOrderData.pickupcode_status">
              <el-input :maxlength=6 type="text" v-model="writeoffOrderForm.pickupcode" placeholder="请输入手机6位提货码"
                style="width:180px">
              </el-input>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleWriteoffOrder">取消</el-button>
          <el-button type="primary" @click="submitWriteoffOrderConfirmAction">确定</el-button>
        </div>
      </el-dialog>
      <!-- 自提订单核销完成 -->
      <el-dialog title="自提核销" :visible.sync="writeoffOrderSuccVisible" :before-close="handleWriteoffOrderSucc"
        width="57%">
        <template>
          <span>{{ writeoffOrderSucc.msg }}</span>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleWriteoffOrderSucc">知道了</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="deliveryTitle" :visible.sync="deliveryVisible" :before-close="handleCancel" width="65%" v-if="0">
        <template>
          <el-form ref="deliveryForm" :model="deliveryForm" class="demo-ruleForm" label-width="100px">
            <el-form-item label="订单号">
              <el-col :span="20">{{ deliveryData.orderInfo.order_id }}</el-col>
            </el-form-item>
            <el-form-item label="发货类型" v-if="0">
              <el-radio-group :disabled="IsDisabled" v-model="deliveryForm.delivery_type">
                <el-radio label="batch">整单发货</el-radio>
                <el-radio label="sep">拆分发货</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品信息">
              <el-col :span="30">
                <el-table :data="deliveryData.orderInfo.items">
                  <el-table-column prop="pic" label="图片" width="100px" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <el-image :src="scope.row.pic" fit="fill" style="width: 50px; height: 50px"></el-image>
                    </template>
                  </el-table-column>
                  <el-table-column prop="item_name" label="商品名" min-width="200px"></el-table-column>
                  <el-table-column prop="num" label="数量" width="180px"></el-table-column>
                  <el-table-column label="总支付价(元)">
                    <template slot-scope="scope">
                      <span>{{ scope.row.fee_symbol }}{{ scope.row.total_fee / 100 }}</span>
                    </template>
                  </el-table-column>
                  <template v-if="deliveryForm.delivery_type == 'sep'">
                    <el-table-column label="快递公司" width="200px">
                      <template slot-scope="scope">
                        <el-select v-model="scope.row.delivery_corp" filterable placeholder="请选择快递公司，可搜索"
                          style="width: 300px">
                          <el-option v-for="item in dlycorps" :key="item.value" :label="item.name"
                            :value="item.value"></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="物流单号" width="200">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.delivery_code" :maxlength=20 placeholder="物流公司单号"></el-input>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </el-col>
            </el-form-item>
            <template v-if="deliveryForm.delivery_type == 'batch'">
              <el-form-item label="快递公司">
                <el-col>
                  <el-select v-model="deliveryForm.delivery_corp" filterable placeholder="请选择快递公司，可搜索"
                    style="width: 300px">
                    <el-option v-for="item in dlycorps" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="物流单号">
                <el-col :span="14">
                  <el-input v-model="deliveryForm.delivery_code" :maxlength=20 placeholder="物流公司单号"></el-input>
                </el-col>
              </el-form-item>
            </template>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitDeliveryAction" :loading="buttonLoading">确定</el-button>
        </div>
      </el-dialog>

      <el-drawer title="协议内容" :visible.sync="drawer" :direction="direction" :withHeader="false"
        :before-close="handleCloseIUp">
        <span>

          <el-container style="text-align:center;font-size: 20px;">
            <el-header style="height: 120px;width:70%;margin-left: 15%;"><br>
              <div>{{ this.show_dealer_name }}<br>iPhone 值享焕新计划协议</div>
              <div style="font-size: 12px;margin-top: 10px"> 订单号：{{ this.iup_order_id }}</div>
            </el-header>
            <el-main>
              <img class="nav-icon" :src="this.iup_agreement" width="100%" />
            </el-main>
          </el-container>
        </span>
      </el-drawer>
      <el-drawer title="订单日志" :visible.sync="drawer_log" :direction="direction" :withHeader="false" :size="800"
        :before-close="handleCloseIUp">
        <span>
          <el-timeline :reverse="false" style="margin: 20px 0 0 20px">
            <el-timeline-item v-for="(key, index) in logList" :key="index"
              :timestamp="key.create_time | datetime('yyyy-MM-dd hh:mm:ss')" placement="top">
              <el-card>
                <h2>{{ key.remarks }}</h2>
                <p>操作人员：{{ key.operator_name }}</p>
                <p>人员类型：
                  <span v-if="'user' == key.operator_type">
                    用户
                  </span>
                  <span v-else-if="'salesperson' == key.operator_type">
                    导购员
                  </span>
                  <span v-else-if="'admin' == key.operator_type">
                    管理员
                  </span>
                  <span v-else-if="'system' == key.operator_type">
                    系统
                  </span>
                  <span v-else-if="'system_job' == key.operator_type">
                    系统自动
                  </span>
                  <span v-else>
                    未知
                  </span>
                </p>
                <p>操作详情：{{ key.detail }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </span>
      </el-drawer>

      <ExportDialog :title='exportTitle' :subtitle='exportSubTitle' :visible="exportDialogVisible"
        :left-data='exportLeftData' :exportListData="exportListData" :right-data='exportRightData' :select-all='exportSelectAll' :exportFunctionType="exportFunctionType"
        @closeDialog="exportCloseDialog" @saveDataAction="exportSaveDataAction" />

      <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
        :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
        returnType="selectRow"></DistributorSelect>

      <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
        :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
        :isSingleSelect="isSingleSelect"></DealerSelect>

      <GoodsSelector :single="single" :items-visible="itemVisible" :get-status="setItemStatus" :rel-items-ids="relItems"
        :item-type="item_type" @chooseStore="handleGoodsSubmit" @closeStoreDialog="handleGoodsDialogHide"></GoodsSelector>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { getOrderList, getOrderDetail, delivery, orderExport, getCancelOrderInfo, cancelConfirm, getLogisticsList, cancelOrderConfirm, isBind, exportInvoice, isInvoiced, getWriteoff, doWriteoff, receivedOrder, exportOrderReciverInfo, getDeliveryDetail, getExporLabel, uploadOrderInvoice, updateOrderInvoice } from '../../../../api/trade'
import { getSourcesList } from '../../../../api/datacube'
import shopSelect from '@/components/shopSelect'
import ImportDialog from '@/components/importDialog'
import ExportDialog from '@/components/exportDialog'
import store from '@/store'
import axios from 'axios'
import { getProcessLog } from '@/api/trade'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import GoodsSelector from '@/components/goodsSelect/index'
import PiiInfo from '@/components/piiInfo/index'
import { getSelfHandleStatus } from '../../../../api/tool'


export default {
  name: 'normalorders',
  components: {
    shopSelect,
    ImportDialog,
    ExportDialog,
    DistributorSelect,
    DealerSelect,
    GoodsSelector,
    PiiInfo
  },
  data() {
    return {
      logList: [],
      logParams: {
        is_view: false,
      },
      iup_agreement: '',
      dealer_alias_name: '',
      show_dealer_name: '',
      iup_order_id: '',
      drawer: false,
      drawer_log: false,
      direction: 'rtl',
      options: [{
        label: '全部',
        name: 'all'
      }],
      selectList: [
        {
          value: '',
          label: '不分'
        },
        {
          value: 'true',
          label: '是'
        }, {
          value: 'false',
          label: '否'
        }
      ],
      presaleSelectList: [
        {
          value: '',
          label: '不分'
        },
        {
          value: 1,
          label: '非预售订单'
        }, {
          value: 2,
          label: '预售订单'
        }, {
          value: 3,
          label: '期货预售'
        }
      ],
      payMethods: [
        {label: '微信支付', value: 'adapay'},
        {label: '花呗支付', value: 'adapay_fq_hua_bei'},
        {label: '支付宝信用卡分期', value: 'adapay_fq_credit_card'}
      ],
      fundType: [
        // {label: '旧机回收', value: 'tradein'},
        {label: '微信线上支付', value: 'adapay_online'},
        {label: '微信线下支付', value: 'adapay'},
        {label: '花呗支付', value: 'adapay_fq_hua_bei'},
        {label: '支付宝信用卡分期', value: 'adapay_fq_credit_card'},
        {label: '支付宝其他', value: 'adapay_fq_other'},
      ],
      fund_fq_options: [
        {label: '3', value: '3'},
        {label: '6', value: '6'},
        {label: '12', value: '12'},
      ],
      is_wecom: [
        {
          value: '',
          label: '不限'
        },
        {
          value: 'true',
          label: '企业微信'
        }, {
          value: 'false',
          label: '普通'
        }
      ],
      typeWarehouse: [
        {
          value: '',
          label: '不限'
        },
        {
          value: '2',
          label: '配件'
        }, {
          value: '1',
          label: '非配件'
        }
      ],
      deliveryWarehouse: [
        {
          value: '',
          label: '不限'
        },
        {
          value: 'distributor',
          label: '门店'
        }, {
          value: 'warehouse',
          label: '仓库'
        }
      ],
      tradeInSeriveList: [
        {
          value: '',
          label: '不限'
        },
        {
          value: 1,
          label: '一站式'
        }, {
          value: 2,
          label: '上门'
        }, {
          value: 3,
          label: '旧机邮寄'
        }
      ],
      value: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      exportReciverLoading: false,
      tabPane: [],
      invoiceVisible: false,
      invoice_number: '',
      operator_type: '',
      is_distributor: false,
      IsDisabled: false,
      IsBind: false,
      open_self_handle: false,
      activeName: 'all',
      loading: false,
      new_btn: false,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        order_class_exclude: 'drug,pointsmall',
        distributor_name: '',
        dealer_id: '',
        serial_number: '',
        distributor_vald: '',
        dealer_name: '',
        channel_rtm: '',
        title: '',
        item_bn: '',
        goods_id: '',
        is_edu_order: '',
        is_tcb: '',
        tradein_service_type: '',
        is_wecom: '',
        is_full: '',
        is_ec: '',
        is_presale_order: '',
        has_normal_gift_order: '',
        is_normal_gift_order: '',
        edu_order_no: '',
        distributor_vad: '',
        shop_code: '',
        type_warehouse: '',
        delivery_warehouse: '',
        tag: '',
        lng: '', // 经度
        lat: '' // 纬度
      },
      order_class_array: [
        { name: '全部订单', value: '' },
        { name: '团购订单', value: 'groups' },
        { name: '秒杀订单', value: 'seckill' },
        { name: '社区订单', value: 'community' },
        { name: '导购订单', value: 'shopguide' },
        { name: '跨境订单', value: 'crossborder' },
        { name: '助力订单', value: 'bargain' },
      ],
      cancelOrderInput: false,
      order_cancel_reason: [
        { name: '疑似黄牛单', value: 13 },
        { name: '库存超售', value: 14 },
        { name: '其他原因', value: 12 },
      ],
      order_status: '',
      time_start_begin: '',
      time_start_end: '',
      salesman_mobile: '',
      total_count: 0,
      order_type: 'normal',
      tableData: [],
      identifier: '',
      source_list: [],
      source_name: '',
      source_id: '',
      order_class: '',
      cancel_order: '',
      deliveryVisible: false,
      deliveryTitle: '',
      deliveryData: {
        orderInfo: {},
      },
      deliveryForm: {
        delivery_type: 'batch',
        order_id: '',
        delivery_corp: '',
        delivery_code: '',
        sepInfo: {},
        dOption: ''
      },
      dlycorps: [],
      cancelVisible: false,
      cancelData: {
        orderInfo: {},
      },
      cancelForm: {
        order_id: '',
        check_cancel: '1',
        shop_reject_reason: '',
      },
      cancelOrderVisible: false,
      cancelOrderData: {
        orderInfo: {},
      },
      cancelOrderForm: {
        order_id: '',
        cancel_reason: 0,
        other_reason: '',
      },
      writeoffOrderVisible: false,
      writeoffOrderData: {
        items: {},
      },
      writeoffOrderForm: {
        order_id: '',
        pickupcode: '',
      },
      writeoffOrderSuccVisible: false,
      writeoffOrderSucc: {
        msg: '',
      },
      downloadView: false,
      downloadUrl: '',
      downloadName: '',
      // 选择发货订单的类型，老订单还是新订单
      selectItemType: '',
      deliveryVisibleNew: false,
      receiveView: false,
      orderInfo: {},
      buttonLoading: false,
      deliveryInfo: [],
      deliveryLoading: true,
      loadingJd: false,
      oredrInvoiceUploadList: [],
      exportDialogVisible: false,
      exportTitle: '',
      exportSubTitle: '按当前筛选条件导出，导出字段拖拽排序。',
      exportLeftData: [],
      exportRightData: [],
      exportListData: [],
      exportType: '',
      exportFunctionType: '',
      export_field: [],
      exportSelectAll: false,
      formDrawer: false,
      dynamicTags: [],
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      distributorVisible: false,
      distributor_info: [],
      setDistributorStatus: false,
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      rolesListData: [],
      goods_num: '选择订单包含商品',
      single: false,
      itemVisible: false,
      setItemStatus: false,
      relItems: [],
      item_type: 'normal',
      activeOrderType: 'all',
      OrderTypeOption: [
        { label: '全部订单', value: 'all'},
        { label: '风险订单', value: 'risk'},
      ],
      cancelTokenSource: null // 用于切换tab取消请求
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type']),
    isSingleSelect() {
      const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
      return role && role.role_name !== 'VAD_Admin'
    }
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    getDeliveryInfo(row,order_id) {
      console.log(row);
      this.deliveryInfo = [];
      this.deliveryLoading = true;
      let query = {
        delivery_corp: row.delivery_corp,
        delivery_code: row.delivery_code,
        order_id: row.order_id,
      }
      getDeliveryDetail(query).then((response) => {
        this.deliveryInfo = response.data.data;
        console.log(this.deliveryInfo);
        this.deliveryLoading = false;
      });
    },
    selectChange(val) {
      this.params.order_status = val.join(',');
      this.numberSearch();
    },
    panelCollapse(type) {
      this.formDrawer = type
    },
    // 操作接单
    handleReceive(row) {
      this.receiveView = true;
      this.orderInfo = row;
    },
    handleCloseIUp(done) {
      done()

    },
    handleColse() {
      this.receiveView = false;
      this.orderInfo = {};
      this.invoiceVisible = false;
      this.invoice_number = '';
      this.buttonLoading = false;
      this.oredrInvoiceUploadList = []
    },
    isShowReceive(row) {
      if (this.isShowAudit(row)) {
        return false;
      }
      // 对接了  oms/erp  但是 erp没有落地好订单时  不能接单！
      if (row.send_oms == 'true' && row.oms_status != '3') {
        return false;
      }

      return this.login_type !== 'disti' && (row.receive_status === '0') && row.order_status === 'PAYED';
    },

    isWarehouse(row) {
      // 仓库订单没有任何操作
      if (row.is_warehouse == '0') {
        return true
      } else {
        return false
      }
    },

    isShowDelivery(row) {
      let now = (new Date).getTime() / 1000;
      if (this.login_type !== 'disti' && row.receipt_type === 'logistics'               // 非 经销商端 并且 是物流
        && (row.order_status === 'PAYED' || row.order_status === 'WAIT_BUYER_CONFIRM')  // 已付款 或者 待收货[并有顺丰数据]
        && (row.cancel_status === 'NO_APPLY_CANCEL' || row.cancel_status === 'FAILS')   // 没有申请取消 或者 取消审核被拒
        && (row.receive_status === '1' || row.receive_status === '4')                   // 接单1 接单后拒绝取消订单4   才可以 显示 发货 === 2021年08月27日16:44:41 新增
        // && (row.sf_is_cancel === '0')                                                // 未 撤销或取消
        && row.pay_type !== 'appointment'
        && row.is_show_delivery === true
        && ((row.send_oms == "true" && (row.oms_status == 51 || row.oms_status == 5)) || row.send_oms == "false") // 51备货完成// || row.oms_status == 5
      ) {


        //优化  预售订单，未到预售开售期， 根据订单附加信息记录的是否可呼叫快递来判断 2023.08.25 pre_call_delivery=1则不限制
        let just_open_slae_call = row.extInfo.pre_call_delivery === 0 ? true : false
        console.log('eeeeeee', row.extInfo)
        // 新增  预售订单，未到预售开售期，不可发货 === 2021年08月27日16:44:46 新增
        if (just_open_slae_call) {
          if (row.sale_start_time && row.sale_start_time > now) {
            return false;
          }
        }
        return true;
      }

      return false;
    },
    checkIsNormal(row) {
      if (row.extInfo.choose_normal_delivery !== 0) {
        return this.changeMsg(row.extInfo.choose_normal_delivery);
      }
      return false
    },
    changeMsg(type) {
      switch (type) {
        case 1:
          return '用户主动选择'
        case 2:
          return '商品不支持'
        case 3:
          return '门店不支持'
        case 4:
          return '范围外不支持'
      }
    },
    checkIsTc(row) {
      if (row.extInfo.choose_normal_delivery !== 0) {
        return false
      }
      if (row.extInfo.delivery_duration <= 0) {
        return false
      }

      let eta_time = row.extInfo.eta_time
      if (eta_time === 0 || eta_time === '' || eta_time === null || eta_time === '1') {
        return 1
      }
      let pre_delivery = (row.extInfo.select_time_slot !== 0 && row.extInfo.select_time_slot !== '') ? true : false
      if (pre_delivery) {
        return row.extInfo.pre_time
      }
      return false
    },

    // 再次备货
    isShowCallBeihuo(row) {

      if (this.login_type == 'disti') {
        return false;
      }
      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }

      console.log("isShowCallBeihuo 11 oms_status:", row.oms_status)
      //发货
      if (row.send_oms != 'true') {
        return false;
      }

      //  if (row.oms_status == 0 ){
      //   return false;
      // }
      // if(row.is_show_delivery === true && row.oms_store == 1){
      //   //已出库
      //   return true;
      // }
      if (row.order_status === 'CANCEL' || row.order_status === 'DONE') {
        return false;
      }

      if (row.oms_store === '1' && row.delivery_corp_source !== 'SF_API_RETURNED') {//
        //这里还要判断一下是否已经入库了
        if(!row.back){
          return false;
        }
      }

      if( row.oms_status == 5 && row.delivery_corp_source === 'SF_API_FIX' &&  row.receipt_type != 'logistics' && row.order_status === 'PAYED' ){
        //快递转自提 且备货已完成
        return true;
      }


      // 已撤销的 订单 支持 呼叫快递
      // if (row.is_show_delivery === true && row.sf_is_cancel == '1' && (row.delivery_corp_source === 'SF_API_ERROR' || row.delivery_corp_source === 'SF_API_RETURNED') && row.receive_status < 5) {
      //   return true;
      // }
      //发货
      // if (row.send_oms == 'true' && row.oms_status != 0 ){
      //   return false;
      // }

      // console.log("isShowCallBeihuo 22 row              :",row )
      console.log("isShowCallBeihuo 22 order_id       :", row.order_id)

      console.log("isShowCallBeihuo 22 oms_status       :", row.oms_status)
      console.log("isShowCallBeihuo 33 receive_status   :", row.receive_status)
      console.log("isShowCallBeihuo sf_is_cancel        :", row.sf_is_cancel)
      console.log("isShowCallBeihuo delivery_corp_source:", row.delivery_corp_source)

      if (!row.delivery_corp_source) {
        return false;
      }


      if (row.oms_status == 51) {
        return false;
      }

      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5
        && row.send_oms == 'true'
        && (row.oms_status == 3 || row.oms_status == 0)) {
        console.log("异常订单（SF_API_ERROR）")
        return true;
      }
      //  撤销（SF_API_CHEXIAO） 创单成功（oms_status=3）
      if (row.delivery_corp_source === 'SF_API_CHEXIAO_BEIHUO'
        && row.send_oms == 'true'
        && (row.oms_status == 0)) {
        console.log("撤销（SF_API_CHEXIAO_BEIHUO） 创单成功（oms_status=3）")
        return true;
      }
      if (row.delivery_corp_source === 'SF_API_CHEXIAO'
        && row.send_oms == 'true'
        && (row.oms_status == 0)) {
        console.log("撤销（SF_API_CHEXIAO） 创单成功（oms_status=3）")
        return true;
      }



      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 呼叫快递
    isShowCall(row) {

      if (this.login_type == 'disti') {
        return false;
      }

      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }

      console.log("呼叫快递-------000001----")

      if (row.order_status == "CANCEL") {
        return false;
      }

      if (row.receipt_type !== 'logistics') {
        return false;
      }

      console.log("呼叫快递 2222 oms_status       :", row.oms_status)
      console.log("呼叫快递 3333 receive_status   :", row.receive_status)
      console.log("呼叫快递 sf_is_cancel        :", row.sf_is_cancel)
      console.log("呼叫快递 delivery_corp_source:", row.delivery_corp_source)

      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5
        && row.oms_status == 51
      ) {
        return true;
      }
      console.log("呼叫快递-------000002 ---")

      if (row.send_oms == "true"
        && row.delivery_corp_source == "SF_API_CHEXIAO"
        && row.oms_status == 51
      ) {
        return true;
      }
      console.log("呼叫快递-------000003 ---")


      // if(row.is_show_delivery === true && row.oms_store == 0){//未出库
      //   ////未出库
      //   return true;
      // }
      // if( row.send_oms== "true" && (row.oms_status != 51 && row.oms_status != 5  )){
      //   return false;
      // }
      console.log(row.sf_is_cancel)
      console.log(row.sf_order_id)
      if (row.oms_store == 1 && !row.back) {
        return false;
      }
      if(this.isShowCallBeihuo(row)  && row.oms_store !=='3')//展示了再次备货。要看一下oms_store 是不是=3
      {
        return false;
      }

      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 转顺丰
    isShowExchange(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      if (row.receipt_type !== 'logistics') {
        return false;
      }
      if(row.oms_store === '1'){
        //已经出库了
          return false;
      }
      return  this.isShowDelivery(row);
    },
    // 撤销快递
    isShowCancel(row) {
      if (this.login_type == 'disti') {
        return false;
      }

      if (this.isShowCalBackDelivery(row)) {
        return false;
      }

      console.log("isShowCalBackDelivery =======  OK")

      return row.sf_order_id !== '0' && (row.delivery_corp_source === 'SF_API_PENDING') && this.isShowDelivery(row);
    },

    // 拦截快递
    isShowCallBack(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      if (this.isShowCalBackDelivery(row) || this.isShowCancel(row)) {
        return false;
      }

      // 顺丰单号 没有发生取消，并且 已发货 就 可以支持拦截快递
      return row.sf_is_cancel == 0 && (row.delivery_corp_source === 'SF_API_COLLECT' || row.delivery_corp_source === 'SF_API_ON_WAY') && this.isShowDelivery(row);
    },
    showErrMsg(row) {
      console.log('dasdsadada')
      if ((this.isShowCancel(row) || this.isShowCallBack(row)) && row.delivery_corp_order !== 'MTPS') {
        console.log('false')
        return false;
      }
      console.log(row)

      console.log(1122233445566)

      return true;
    },

    // 呼叫快递
    isShowCalBackDelivery(row) {
      if (this.login_type == 'disti') {
        return false;
      }
      // 如果有审核 就不要有 呼叫快递
      if (this.isShowAudit(row)) {
        return false;
      }
      // 已撤销的 订单 支持 呼叫快递
      if (row.is_show_delivery === true && row.sf_is_cancel == '1'
        && (row.delivery_corp_source === 'SF_API_ERROR'
          || row.delivery_corp_source === 'SF_API_CHEXIAO'
          || row.delivery_corp_source === 'SF_API_RETURNED')
        && row.receive_status < 5) {
        return true;
      }

      return row.sf_is_cancel == '0' && row.sf_order_id == '0' && this.isShowDelivery(row);
    },

    // 关闭订单
    isShowClose(row) { // 非  运营端   支付 自提未完成 且不在审核 状态的订单  才可以关闭
      if (this.login_type == 'disti') {
        return false;
      }

      if (row.order_status == "CANCEL") {
        return false;
      }

      if (row.is_warehouse > 0) {
        console.log("isShowClose---is_warehouse", row.is_warehouse)
        if (row.oms_status == 51) {
          console.log("isShowClose---oms_status", row.oms_status)
          return false
        }
          if (row.oms_store != 0) {
          console.log("isShowClose---oms_store", row.oms_store)
          return false
        }
        if (row.delivery_code) {
          console.log("isShowClose---delivery_code", row.delivery_code)
          return false
        }
      }

      // 能 审核订单 或者 撤销快递 就不能 关闭订单
      if (this.isShowAudit(row) || this.isShowCancel(row)) {
        return false;
      }

      // 能呼叫快递 就能 关闭订单
      if (this.isShowCall(row)) {
        return true;
      }

      if (row.is_show_delivery === true && (row.order_status === 'WAIT_BUYER_CONFIRM' || row.order_status === 'PAYED') && row.sf_order_id === '0') {
        return true;// 发货异常 的订单 支持 关闭订单
      }

      return this.login_type !== 'disti' && row.order_status === 'PAYED' && row.ziti_status !== 'DONE' && row.cancel_status !== 'WAIT_PROCESS';
    },
    isShowInvoice(row) {
      return this.login_type !== 'disti' && row.is_invoiced === '0' && row.invoice && row.invoice_source == 0 && row.order_status == 'DONE';
    },
    isShowExportReciver(row) {
      return this.login_type === 'distributor' && row.receipt_type === 'logistics' && (row.receive_status == '1' || row.receive_status == '4') && row.delivery_status == 'PENDING';
    },
    isShowExportPdf(row) {
      return this.login_type === 'dealer' || this.login_type === 'distributor';
    },
    isShowAudit(row) {
      return this.login_type !== 'disti' && (row.receive_status === '2' || row.cancel_status === 'WAIT_PROCESS') /*&& row.order_status === 'PAYED'*/;
    },
    isShowDisabled(row) {
      return !this.isShowReceive(row) && !this.isShowDelivery(row) && !this.isShowClose(row) && !this.isShowInvoice(row) && !this.isShowAudit(row) && !this.isShowExportReciver(row) && !this.isShowExportPdf(row);
    },
    onCopy() {
      this.$notify({
        message: '复制成功',
        type: 'success',
      })
    },
    checkStatus(source) {
      if (!source) {
        return false;
      }
      if (source == null) {
        return false;
      }
      if (source == '') {
        return false;
      }
      return true
    },
    toRoute(path) {
      this.$router.push({
        path: path
      })
    },
    checkPreOrder(row) {
      if (row.type == '2') {
        let qh = row.extInfo.pre_call_delivery === 1 ? true : false
        if (qh) {
          return false;
        }
        return true;
      }
      return false
    },
    checkPreOrderQh(row) {
      if (row.type == '2') {
        let qh = row.extInfo.pre_call_delivery === 1 ? true : false
        if (qh) {
          return true;
        }
        return false;
      }
      return false
    },
    // 切换风险订单
    handleClickOrderType(tab) {
      this.params.tag = tab.name === 'all' ? '' : tab.name
      this.params.page = 1
      this.params.order_status = '' // 清空状态
      this.getParams()
      this.getOrders(this.params)
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.order_status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    storeSearch(val) {
      val && val.shop_id
      this.params.distributor_id = val.shop_id
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    TypeHandle(val) {
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    numberSearch(e) {
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
      this.tagData()
    },
    tagData() {
      let arr = Object.keys(this.params)
      let list = []
      arr.forEach((item) => {
        let value = this.params[item]
        if (value) {


          if (item == 'distributor_name') {
            list.push(
              {
                label: '店铺名称',
                value,
                key: item
              }
            )
          }

          if (item == 'dealer_name') {
            list.push(
              {
                label: '经销商名称',
                value,
                key: item
              }
            )
          }

          if (item == 'channel_rtm') {
            list.push(
              {
                label: '渠道RTM',
                value,
                key: item
              }
            )
          }

          if (item == 'item_bn') {
            list.push(
              {
                label: '订单商品',
                value: `${this.relItems.length}个`,
                key: item
              }
            )
          }

          if (item == 'is_edu_order') {
            list.push(
              {
                label: '',//是否教育优惠订单
                value: value == 'true' ? '教育优惠订单' : '非教育优惠订单',
                key: item
              }
            )
          }

          if (item == 'is_tcb') {
            list.push(
              {
                label: '',//是否以旧换新
                value: value == 'true' ? '以旧换新订单' : '非以旧换新订单',
                key: item
              }
            )
          }

          if (item == 'tradein_service_type') {
            list.push(
              {
                label: '',
                value: value == 2 ? '上门' : value == 3 ? '旧机邮寄' : '一站式',
                key: item
              }
            )
          }

          if (item == 'is_wecom') {
            list.push(
              {
                label: '订单渠道',
                value: value == 'true' ? '企业微信' : '普通',
                key: item
              }
            )
          }

          if (item == 'is_full') {
            list.push(
              {
                label: '',//是否参与满赠
                value: value == 'true' ? '参与满赠' : '未参与满赠',
                key: item
              }
            )
          }

          if (item == 'is_ec') {
            list.push(
              {
                label: '',//是否是员工购
                value: value == 'true' ? '员工购' : '非员工购',
                key: item
              }
            )
          }

          if (item == 'is_presale_order') {
            let type = '';
            switch (value) {
              case 1:
                type = '非预售订单'
                break;
              case 2:
                type = '预售订单'
                break;
                case 3:
                type = '期货预售'
                break;
            }
            list.push(
              {
                label: '是否预售订单',//是否是员工购
                value: type,
                key: item
              }
            )
          }

          if(item == 'has_normal_gift_order') {
            list.push(
              {
                label: '是否参与买X赠Y',
                value: value == 'true' ? '参与买X赠Y' : '未参与买X赠Y',
                key: item
              }
            )
          }

          if(item == 'is_normal_gift_order') {
            list.push(
              {
                label: '是否实物赠品订单',
                value: value == 'true' ? '是实物赠品订单' : '非实物赠品订单',
                key: item
              }
            )
          }

          if (item == 'edu_order_no') {
            list.push(
              {
                label: '教育优惠单号',
                value,
                key: item
              }
            )
          }

          if (item == 'distributor_vad') {
            list.push(
              {
                label: '分销商VAD',
                value,
                key: item
              }
            )
          }

          if (item == 'shop_code') {
            list.push(
              {
                label: '归属店铺',
                value: `${this.distributor_info.length}个`,
                key: item
              }
            )
          }

          if (item == 'dealer_id') {
            list.push(
              {
                label: '归属经销商',
                value: `${this.relDealers.length}个`,
                key: item
              }
            )
          }
          if (item == 'serial_number') {
            list.push(
              {
                label: '流水号',
                value,
                key: item
              }
            )
          }

          if (item == 'title') {
            list.push(
              {
                label: '商品名称',
                value,
                key: item
              }
            )
          }

          if (item == 'type_warehouse') {
            list.push(
              {
                label: '商品市场分类',
                value: value == '1' ? '非配件' : '配件',
                key: item
              }
            )
          }

          if (item == 'delivery_warehouse') {
            list.push(
              {
                label: '发货方',
                value: value == 'distributor' ? '门店' : value == 'warehouse' ? '仓库' : '',
                key: item
              }
            )
          }

          if (item == 'pay_type') {
            const cur_pay_type = this.payMethods?.find(item => item.value === value)
            list.push(
              {
                label: '支付方式',
                value: cur_pay_type.label,
                key: item
              }
            )
          }

          if (item == 'fund_channel') {
            const cur_fund_channel = this.fundType?.find(item => item.value === value)
            list.push(
              {
                label: '实际支付类型',
                value: cur_fund_channel.label,
                key: item
              }
            )
          }

          if (item == 'fund_fq_num') {
            const cur_fund_fq_num = this.fund_fq_options?.find(item => item.value === value)
            list.push(
              {
                label: '分期期数',
                value: cur_fund_fq_num.label,
                key: item
              }
            )
          }

        }
      })
      this.dynamicTags = list
    },
    dateChange(val) {
      if (val && val.length > 0) {
        this.time_start_begin = val[0] / 1000;//this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.time_start_end = val[1] / 1000;//this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.time_start_begin = ''
        this.time_start_end = ''
      }
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    sourceSearch(item) {
      this.params.source_id = item.source_id
      this.params.page = 1
      this.getParams()
      this.getOrders(this.params)
    },
    handleCurrentChange(page_num) {
      this.loading = false
      this.params.page = page_num
      this.getParams()
      this.getOrders(this.params)
    },
    handleSizeChange(pageSize) {
      this.loading = false
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getParams()
      this.getOrders(this.params)
    },
    getParams() {
      this.params.time_start_begin = this.time_start_begin
      this.params.time_start_end = this.time_start_end
      this.params.order_type = this.order_type
      this.params.order_class = this.order_class
      this.params.salesman_mobile = this.salesman_mobile
      if (this.identifier.length == 11) {
        this.params.mobile = this.identifier
        this.params.order_id = ''
      } else {
        this.params.mobile = ''
        this.params.order_id = this.identifier
      }
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    getOrders(filter, type = '') {
      if (type) {
        const newFilterList = this.export_field.map(el => el.name_en)
        filter = {
          ...filter,
          type,
          export_field: newFilterList,
          order_status: this.activeName === 'all' ? '' : this.activeName,
        }
      } else {
        this.loading = true
      }
      if (this.cancelTokenSource) {
        // 取消上一次请求
        this.cancelTokenSource.cancel('请求已被取消');
      }
      // 创建新的 CancelToken
      this.cancelTokenSource = axios.CancelToken.source();
      getOrderList(filter, this.cancelTokenSource.token).then((response) => {
        this.exportDialogVisible = false
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载',
          })
          return false;
        } else {
          this.tableData = response.data.data.list
          this.total_count = Number(response.data.data.pager.count);
          this.iup_agreement = response.data.data.iup_agreement

          this.options = response.data.data.pane;
          this.loading = false
          this.new_btn =  response.data.data.new_btn
          let params = {
            ...filter,
            isShowCount: true,
          }
          getOrderList(params).then((res) => {
            this.tabPane = res.data.data.pane;
          });
        }
        this.getSelfHandleStatus('msg');
      })
    },
    getStatus() {
      isBind().then((response) => {
        this.IsBind = response.data.data.result
      })
    },
    getSelfHandleStatus(alert='box') {
      getSelfHandleStatus().then((response) => {
        console.log(response.data.data.open_status)
        if(response.data.data.open_status === '1' || response.data.data.shop_is_join){
          this.open_self_handle = true;
          this.noticeSelfOrder(alert)
        }else{
          this.open_self_handle = false;
        }
      })
    },
    getAllSourcesList() {
      let params = { page: 1, pageSize: 1000 }
      getSourcesList(params).then((response) => {
        if (response.data.data.list) {
          response.data.data.list.forEach((row) => {
            this.source_list.push({ value: row.sourceName, source_id: row.sourceId })
          })
        }
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.source_list
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    deliveryAction(data, type = '', option = '') {
      // 编辑物料弹框
      let order_id = data.order_id

      this.selectItemType = data.delivery_type
      // this.selectItemType = 'new'
      this.deliveryTitle = '订单发货相关操作'

      if ((type === 'delivery' || type === 'delivery_mt' || type === 'delivery_tc') && data.distributor_info.sf_call_transfer != '0' && data.distributor_info.alternate_place_id != '0') {
        this.$confirm('将由' + data.distributor_info.alternatePlaceInfo.name + '发货点进行发货', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getOrderDetailFunc(order_id, type, option)
        }).catch((error) => {
          console.log(error.message)
        });
      } else {
        this.getOrderDetailFunc(order_id, type, option)
      }
    },
    exchangeSfAction(data, type = '', option = '') {
      // 编辑物料弹框
      let order_id = data.order_id

      this.selectItemType = data.delivery_type
      // this.selectItemType = 'new'
      this.deliveryTitle = '订单发货相关操作'

      this.$confirm('确定将此订单转由顺丰配送吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getOrderDetailFunc(order_id, type, option)
      }).catch((error) => {
        console.log(error.message)
      });

    },
    setIupInfo(row) {
      this.drawer = true
      this.dealer_alias_name = row.distributor_info.dealer_alias_name
      this.show_dealer_name = row.distributor_info.dealer_name
      this.iup_order_id = row.order_id
      this.iup_agreement = row.iup_image
    },
    setLogPage(order_id) {
      this.drawer_log = true
      this.getProcessLogInfo(order_id)
    },
    getProcessLogInfo(order_id) {
      getProcessLog(order_id, this.logParams).then(response => {
        this.logList = response.data.data
      })
    },
    getOrderDetailFunc(order_id, type, option = '') {
      getLogisticsList().then((res) => {
        this.dlycorps = res.data.data.list
      })
      getOrderDetail(order_id).then((response) => {
        this.deliveryData = response.data.data
        if (this.deliveryData.orderInfo.cancel_status == 'WAIT_PROCESS') {
          this.$message.error('客户已经申请退款，请先处理退款操作再决定是否发货!')
          return false
        }

        if (this.selectItemType == 'old') {
          this.deliveryVisibleNew = true
        } else {
          this.deliveryVisibleNew = true
        }
        if (this.deliveryData && this.deliveryData.orderInfo.delivery_status === 'PARTAIL') {
          this.IsDisabled = true
          this.deliveryForm.delivery_type = 'sep'
        }
      })
      this.deliveryForm.order_id = order_id
      this.deliveryForm.dtype = type
      this.deliveryForm.dOption = option
    },
    confirmCancelOrderAction(order_id, row) {
      // 已支付订单的取消订单审核
      let params = { order_type: 'normal' }
      this.orderInfo = row;
      getCancelOrderInfo(order_id, params).then((response) => {
        this.cancelData = response.data.data
        this.cancelVisible = true
      })
      this.cancelForm.order_id = order_id
    },
    receivedOrder(order_id) { // 接单
      this.buttonLoading = true;
      this.loadingJd = true
      receivedOrder(order_id, {
        'receive_status': 1
      }).then((response) => {
        let res = response.data.data, msg = '操作失败！', type = 'error';
        this.loadingJd = false
        if (res) {
          this.handleColse();
          this.getOrders(this.params);
          type = 'success';
          msg = '接单成功，请准备发货';
        }
        this.loadingJd = false

        this.$message({
          type: type,
          message: msg,
        });
      });
    },
    handleCancelOrderCancel() {
      this.orderInfo = {};
      this.cancelVisible = false
      this.cancelForm.order_id = ''
      this.cancelForm.shop_reject_reason = ''
      this.buttonLoading = false;
    },
    submitCancelConfirmAction() { // 提交取消订单审核结果
      console.log('submitCancelConfirmAction---', 2)
      this.buttonLoading = true;
      if (this.cancelForm.check_cancel == '0') { // 拒绝
        this.cancelForm.receive_status = '4';
      }
      if (this.cancelForm.check_cancel == '1') { // 同意
        this.cancelForm.receive_status = '3';
      }
      if (this.cancelForm.check_cancel == '2') { //管理后台强制取消订单
        this.cancelForm.receive_status = '5';
      }
      if (this.cancelForm.check_cancel == '3') { //管理后台强制取消订单
        this.cancelForm.receive_status = '6';
      }
      console.log('submitCancelConfirmAction---', 3)

      cancelConfirm(this.cancelForm.order_id, this.cancelForm).then(response => {
        console.log('submitCancelConfirmAction---', 4)
        var cancelOrderStatus = response.data.data.refund_status
        if (cancelOrderStatus == 'AUDIT_SUCCESS') {
          this.$message.success('操作成功!')
          this.handleCancelOrderCancel()
          this.getOrders(this.params)
        } else if (cancelOrderStatus == 'SHOP_CHECK_FAILS') {
          this.handleCancelOrderCancel()
          this.$message.success('审核拒绝成功!')
          this.getOrders(this.params)
        } else {
          this.$message.error('审核失败!')
          this.getOrders(this.params)
          return false
        }
        console.log('submitCancelConfirmAction---', 5)
      });
    },
    handleCancel() {
      this.deliveryVisible = false
      this.deliveryForm.order_id = ''
      this.deliveryForm.delivery_corp = ''
      this.deliveryForm.delivery_code = ''
      this.deliveryVisibleNew = false
      this.buttonLoading = false;
      this.deliveryForm = {
        delivery_type: 'batch',
        order_id: '',
        delivery_corp: '',
        delivery_code: '',
        sepInfo: {},
      }
      this.IsDisabled = false
    },
    beihuoAction(data) {
      this.deliveryForm.type = this.selectItemType = 'new'
      this.deliveryForm.order_id = data.order_id
      this.deliveryForm.delivery_type = 'batch'
      this.deliveryForm.option_bh = 'zcbh'
      delivery(this.deliveryForm).then((response) => {
        this.deliveryForm.option_bh = false//重置数据
        // let deliveryStatus = response.data.data.delivery_status, sfOrderId = response.data.data.sf_order_id;
        if (response.data.data) {
          this.handleCancel()
          this.$message.success('操作成功！')
          this.getOrders(this.params)
        } else {
          this.$message.error('操作失败！')
          this.buttonLoading = false
          return false
        }
      })
    },
    submitDeliveryAction() {
      this.buttonLoading = true;
      // 提交物料
      this.deliveryForm.type = this.selectItemType = 'new';
      if (this.deliveryForm.delivery_type === 'sep') {
        if (this.selectItemType === 'old') {
          this.deliveryForm.sepInfo = JSON.stringify(this.deliveryData.orderInfo.items)
          this.deliveryForm.delivery_corp = ''
          this.deliveryForm.delivery_code = ''
        } else {
          this.deliveryForm.sepInfo = JSON.stringify(JSON.parse(JSON.stringify(this.deliveryData.orderInfo.items)).filter((item) => item.delivery_num && item.delivery_num != ''))
        }
      } else {
        this.deliveryForm.sepInfo = {}
      }
      console.log(this.deliveryForm);
      delivery(this.deliveryForm).then((response) => {
        // let deliveryStatus = response.data.data.delivery_status, sfOrderId = response.data.data.sf_order_id;
        if (response.data.data) {
          this.handleCancel()
          if (response.data.data.break === true) {
            this.$message.success('操作成功,美团呼叫失败，已为您自动呼叫顺丰！')
          } else {
            this.$message.success('操作成功！')

          }
          this.getOrders(this.params)
        } else {
          this.$message.error('操作失败！')
          this.buttonLoading = false;
          return false
        }
      }).catch(() => {
        this.buttonLoading = false
        this.getOrders(this.params)
      })
    },
    //关闭订单
    cancelOrderAction(order_id, row) {
      let params = { order_type: 'normal' }
      getOrderDetail(order_id).then((response) => {
        this.cancelOrderData = response.data.data
        if (this.cancelOrderData.orderInfo.cancel_status == 'SUCCESS') {
          this.$message.error('该订单已关闭！')
          // this.$message.error('该订单已取消!')
          return false
        }
        this.orderInfo = row;
        this.cancelOrderVisible = true
      })
      this.cancelOrderForm.order_id = order_id
    },
    handleCancelOrder() {
      this.cancel_order = ''
      this.cancelOrderVisible = false
      this.cancelOrderForm.order_id = ''
      this.cancelOrderForm.other_reason = ''
      this.cancelOrderInput = false
      this.orderInfo = {};
      this.buttonLoading = false;
    },
    cancelReasonSelect(val) {
      if (val == 12) {
        this.cancelOrderInput = true
      } else {
        this.cancelOrderForm.other_reason = ''
        this.cancelOrderInput = false
      }
      this.cancelOrderForm.cancel_reason = val
    },
    submitCancelOrderConfirmAction() {
      this.buttonLoading = true;
      if (this.cancelOrderForm.cancel_reason == 0) {
        this.$message.error('请选择关闭原因!')
        return false
      }
      if (this.cancelOrderForm.cancel_reason == 12 && this.cancelOrderForm.other_reason == '') {
        this.$message.error('请输入关闭原因!')
        return false
      }

      let orderStatus = this.orderInfo.order_status, order_id = this.orderInfo.order_id, receiveStatus = this.orderInfo.receive_status;
      let sf_is_cancel = this.orderInfo.sf_is_cancel
      let sf_order_id = this.orderInfo.sf_order_id
      console.log('orderStatus:', orderStatus)
      console.log('sf_is_cancel:', this.orderInfo.sf_is_cancel)
      console.log('sf_order_id:', this.orderInfo.sf_order_id)

      cancelOrderConfirm(order_id, this.cancelOrderForm).then((response) => {
        console.log('sf_is_cancel:', sf_is_cancel)
        console.log('sf_order_id:', sf_order_id)
        console.log('is_warehouse', this.orderInfo.is_warehouse)

        if (orderStatus === 'PAYED'
          || (orderStatus === 'WAIT_BUYER_CONFIRM'
            && (sf_is_cancel > 0 || sf_order_id > 0))) {

          console.log('orderStatus:', orderStatus)
          console.log('receiveStatus:', receiveStatus)

          // 2021年08月23日18:54:05 取消成功  就直接  退款无需审核   需求
          if (receiveStatus == '1' || receiveStatus == '4') { // 已接单 管理员取消 未发货商家取消
            this.cancelForm.check_cancel = '3';
          } else {
            this.cancelForm.check_cancel = '2'; // 管理后台强制取消订单
          }
          this.cancelForm.order_id = order_id
          if (this.orderInfo.is_warehouse == 0) {
            console.log('submitCancelConfirmAction---', 1)
            this.submitCancelConfirmAction();
            this.handleCancelOrder()
          } else {
            this.handleCancelOrder()
            console.log('submitCancelConfirmAction---', 11)
            console.log('云仓订单取消订单接口已确认取消，不需要再重复调确认取消接口：' . this.orderInfo.is_warehouse)
          }
        } else {
          console.log('submitCancelConfirmAction---', 22)
          console.log('orderStatus:', orderStatus)

          this.$message.success('关闭订单成功！');
          this.handleCancelOrder()
          this.getOrders(this.params);
        }

        this.handleCancelOrder()
      }).catch((error) => {
        this.buttonLoading = false;
        this.getOrders(this.params)
      })
    },
    writeoffOrderAction(order_id) {
      //自提订单核销
      getWriteoff(order_id).then((response) => {
        this.writeoffOrderData = response.data.data
        if (this.writeoffOrderData.ziti_status == 'DONE') {
          this.$message.error('该订单已核销!')
          return false
        }
        this.writeoffOrderVisible = true
      })
      this.writeoffOrderForm.order_id = order_id
    },
    handleWriteoffOrder() {
      this.writeoffOrderVisible = false
      this.writeoffOrderForm.order_id = ''
      this.writeoffOrderForm.pickupcode = ''
      this.writeoffOrderInput = false
    },
    submitWriteoffOrderConfirmAction() {
      if (this.writeoffOrderData.pickupcode_status == 1 && this.writeoffOrderForm.pickupcode == '') {
        this.$message.error('请输入提货码!')
        return false
      }
      doWriteoff(this.writeoffOrderForm.order_id, this.writeoffOrderForm).then((response) => {
        var writeoffStatus = response.data.data.ziti_status
        var order_id = response.data.data.order_id
        if (writeoffStatus == 'DONE') {
          this.handleWriteoffOrder()
          this.writeoffOrderSuccVisible = true
          this.writeoffOrderSucc.msg = '订单' + order_id + '核销成功'
        } else {
          this.$message.success('自提订单核销失败!')
          return false
        }
      })
    },
    handleWriteoffOrderSucc() {
      this.writeoffOrderSuccVisible = false
      this.writeoffOrderSucc.msg = ''
      this.getOrders(this.params)
    },
    exportData(type) {
      this.getParams()
      if (type) {
        this.params.type = type
      }
      this.params.page = 1
      if (this.params.order_type != 'normal') {
        this.$message({
          type: 'error',
          message: '暂不支持该类型订单导出',
        })
        return
      }
      orderExport(this.params).then((response) => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载',
          })
          return
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试',
          })
          return
        }
      })
    },
    exportInvoice() {
      this.getParams()
        ; (this.params.type = 'normal'),
          exportInvoice(this.params).then((response) => {
            if (response.data.data.status) {
              this.$message({
                type: 'success',
                message: '已加入执行队列，请在设置-导出列表中下载',
              })
              return
            } else if (response.data.data.url) {
              this.downloadUrl = response.data.data.url
              this.downloadName = response.data.data.filename
              this.downloadView = true
            } else {
              this.$message({
                type: 'error',
                message: '无内容可导出 或 执行失败，请检查重试',
              })
              return
            }
          })
    },
    handleInvoiceView(row) {
      this.orderInfo = row;
      this.invoiceVisible = true;
    },
    IsInvoiced(row) {
      if (this.oredrInvoiceUploadList.length <= 0) {
        this.$message({ type: 'error', message: '请选择上传文件' })
        return
      }
      this.buttonLoading = true;
      const files = this.orderInfo.invoice_files || []
      const params = {
        order_id: this.orderInfo.order_id,
        images: [...files, ...this.oredrInvoiceUploadList]
      }
      updateOrderInvoice(params).then(response => {
        this.$message({ type: 'success', message: '更新成功' })
        this.handleColse();
        this.getOrders(this.params);
      })
    },
    initDate() {
      let start = new Date(new Date().toLocaleDateString())
      let end = new Date(new Date().toLocaleDateString())
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      end.setTime(end.getTime() + 86399999)

      this.create_time = [start, end];
      this.params.time_start_begin = this.time_start_begin = start / 1000;
      this.params.time_start_end = this.time_start_end = end / 1000;

    },

    downloadExportReciver(row = null) {
      this.exportReciverLoading = true
      let orderIds = [];
      if (row) {
        orderIds.push(row.order_id)
      } else {
        // const orderList = this.list
        // orderList.forEach(function (value, index, orderList) {
        //   orderIds.push(value.order_id)
        // })
        orderIds = '';
      }

      let params = { order_id: orderIds }
      exportOrderReciverInfo(params).then(response => {
        let url = this.genUrl(response.data, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        /*{type:"application/vnd.ms-excel;charset=utf-8"}
           {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
           {type:'text/plain;charset=utf-8'}
         */
        const a = document.createElement('a');
        a.href = url;
        a.download = '发货信息.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        this.exportReciverLoading = false
      }).catch(error => {
        this.$message.error('下载失败')
        this.exportReciverLoading = false
      })
    },
    downloadExportPdf(row) {
      axios.get(this.VUE_APP_BASE_API + '/order/exportPdf', { //url: 接口地址
        params: { order_id: row.order_id },
        responseType: `arraybuffer`, //一定要写
        headers: { 'Authorization': 'bearer ' + store.getters.token }
      }).then(res => {
        console.log(res)
        if (res.status == 200) {
          let blob = new Blob([res.data], {
            type: `application/pdf` //word文档为msword,pdf文档为pdf
          });
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          let fname = row.order_id + `随箱单`; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute("download", fname);
          document.body.appendChild(link);
          link.click();
          this.selectRows = [];
        } else {
          this.$message({
            type: "error",
            message: "导出失败"
          })
        }
      });
    },
    genUrl(encoded, options = { type: 'text/plain;charset=utf-8' }) {
      const dataBlob = new Blob([encoded], options); //返回的格式
      return URL.createObjectURL(dataBlob);
    },
    uploadInvoiceChange(file, fileList) {
      let params = { file: file.raw, isUploadFile: true }
      uploadOrderInvoice(params).then(response => {
        const data = response.data.data
        if (data) this.oredrInvoiceUploadList.push({ ...data, name: data.file_name })
      }).catch(err => {
        console.log(err)
        this.oredrInvoiceUploadList = []
      })
    },
    uploadInvoiceRemoveChange(file, fileList) {
      const obj = this.oredrInvoiceUploadList.find(el => el.uid == file.uid)
      this.oredrInvoiceUploadList.splice(this.oredrInvoiceUploadList.indexOf(obj), 1)
    },
    exportMainDialog(type) {
      this.exportType = type
      switch (type) {
        case "matser_order":
          this.exportSelectAll = true
          this.exportTitle = '主订单导出';
          this.exportFunctionType = 'normal_master_order'
          break;
        case "order":
          this.exportSelectAll = true
          this.exportTitle = '子订单导出';
          this.exportFunctionType = 'normal_order'
          break;
        case "pull_gift_order":
          this.exportTitle = '满赠订单导出';
          this.exportFunctionType = 'pull_gift_order'
          break;
        default:
          this.exportTitle = '导出';
      }
      getExporLabel({ type }).then(response => {
        const data = response.data.data
        let left = data.filter(el => el.required)
        let right = data.filter(el => !el.required)
        console.log(left, right)
        this.exportLeftData = left
        this.exportRightData = right
        this.exportListData = data
        this.exportDialogVisible = true
      })
    },
    exportCloseDialog() {
      this.exportDialogVisible = false
      this.exportSelectAll = false
    },
    exportSaveDataAction(exportField) {
      this.export_field = exportField
      console.log(exportField.map(el => el.name_cn))
      this.getOrders(this.params, this.exportFunctionType)
    },
    checkMinusPromotion(row) {
      let show = false
      let discount = JSON.parse(row.discount_info)
      console.log(discount)
      for (let item in discount) {
        if (discount[item].type === 'full_minus') {
          show = true
        }
      }
      console.log('dassffsdad', show)
      return show
    },
    handleEmpty(i, type) {
        this.params[i.key] = ''
        if (i.key == 'item_bn') {
          this.params.goods_id = ''
        }
        this.numberSearch()

      if (i.key == 'dealer_id') {
        this.relDealers = []
        this.agency_num = '归属经销商'
      } else if (i.key == 'shop_code') {
        this.distributor_info = []
        this.shop_num = '归属店铺'
      } else if (i.key == 'item_bn') {
        this.relItems = []
        this.goods_num = '选择订单包含商品'
      }
    },
    handleCloseDrawer() {
      this.formDrawer = false
    },
    onDialog(type) {
      if (type === 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    // 返回已选择的片铺列表
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.shop_code)
      });
      this.params.shop_code = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.numberSearch()
      this.closeDialogAction()
    },
    // 关闭店铺和经销商弹窗
    closeDialogAction() {
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
    },
    // 已选择的经销商列表
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.params.dealer_id = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.numberSearch()
      this.closeDialogAction()
    },
    // 选择商品弹窗
    handleGoodsDialogShow() {
      this.itemVisible = true
      this.setItemStatus = true
    },
    // 返回已选择的商品列表
    handleGoodsSubmit(data) {
      let arr = []
      let goodIds = []
      data.forEach(i => {
        arr.push(i.item_bn)
        goodIds.push(i.goods_id)
      });
      this.params.item_bn = arr.join(',')
      this.params.goods_id = goodIds.join(',')
      if (data.length > 0) {
        this.goods_num = `已选中${data.length}个商品`
      } else {
        this.goods_num = '选择订单包含商品'
      }
      this.relItems = data
      this.numberSearch()
      this.handleGoodsDialogHide()
    },
    handleGoodsDialogHide() {
      this.itemVisible = false
    },
    clearAll(formName) {
      this.$refs[formName].resetFields()

      this.handleEmpty({ key: 'dealer_id' }, true)
      this.handleEmpty({ key: 'shop_code' }, true)
      this.handleEmpty({ key: 'item_bn' }, true)

      this.tagData()
      this.getOrders(this.params)
    },
    confirmDrawer() {
      this.formDrawer = false
      this.numberSearch()
    },
    noticeSelfOrder(alert ='msg') {
      if(this.operator_type ==='distributor' || this.operator_type ==='dealer')
      if(alert === 'box'){
        this.$alert('系统已开启订单自闭环，请前往<span style="color: red"> 【自闭环订单列表】 </span>进行后续订单操作，当前页面只能进行数据查询！', '系统提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
      // else if (alert === 'msg'){
      //   this.$message({
      //     message: '系统已开启订单自闭环，请前往【新订单列表】进行后续订单操作，当前页面只能进行数据查询！',
      //     type: 'warning'
      //   });
      // }


    },
    isSelfhandle(row) {
      if(this.open_self_handle && row.pay_status != 'NOTPAY' && row.order_status != 'CANCEL' && row.order_status != 'NOTPAY' && row.order_status != "DONE" && row.is_warehouse == '0'){
        return true
      }
      return false
    },
    pathSelfhandle(row) {
      this.$router.push({
        path: this.path_prefixes ? `${this.path_prefixes}//shopadmin/order/entitytrade/selfhandleorders` : '/shopadmin/order/entitytrade/selfhandleorders',
        query: {
          order_id: row.order_id
        }
      })
    },
    // 重置
    resetParams() {
      this.identifier = ''
      this.params.lng = ''
      this.params.lat = ''
      this.getParams()
      this.initDate()
      this.dynamicTags.forEach(el => {
        this.handleEmpty( el )
      })
      this.getOrders(this.params)
    }
  },
  // 页面缓存，返回是更新
  beforeRouteUpdate(to, from, next) {
    // 该钩子在路由更新时触发
    next();
    this.getOrders(this.params)
  },
  activated() {
    this.getOrders(this.params)
  },
  mounted() {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab
      this.params.order_status = this.$route.query.tab
      this.params.page = 1
    }
    if(this.$route.query.order_id) {
      const order_id = this.$route.query.order_id
      this.identifier = order_id
      this.params.order_id = order_id
    }
    this.params.order_type = this.order_type
    this.getStatus()
    this.getSelfHandleStatus('box')
    this.initDate();
    if(this.$route.query.lng && this.$route.query.lat) {
      const lng = this.$route.query.lng
      const lat = this.$route.query.lat
      this.params.lng = lng
      this.params.lat = lat
      this.create_time = []
    }
    this.getOrders(this.params)
    this.getAllSourcesList()
    this.operator_type = store.getters.login_type;
  },
}
</script>
<style scoped lang="css">
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.el-row {
  margin-bottom: 20px;
}

:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.invoice {
  cursor: pointer;
}

.filter-header .el-select .el-input {
  width: 200px;
}

.has-gutter .cell {
  text-align: center;
}

.detail-info {
  margin: 0 10px;
}

.operate-btns {
  .el-button {
    margin-bottom: 5px;
  }

  .mt-call {
    background-color: #febd3b;
    border-color: #febd3b;
    color: #333;
    font-weight: 600;
  }

  .sf-call {
    background-color: #000;
    border-color: #000;
    color: #fff;
    font-weight: 600;
  }
  .sf-exchange {
    background-color: #000;
    border-color: #000;
    color: #d7c9c9;
    font-weight: 600;
  }
  .mt-cancel {
    border-color: #febd3b;
    color: #333;
  }

  .sf-cancel {
    border-color: #000;
    color: #D70C23;
  }
}

.ipt-tag {
  margin-right: 5px;
}

.order-compose {
  padding-bottom: 40px
}

.drawer-list{
  padding-left: 20px
}

.confirm-drawer{
  padding: 20px;
  display: flex;
  justify-content: center
}
</style>
